import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosInstance from '../../axiosConfig';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Skeleton,
  Snackbar,
  Alert,
} from '@mui/material';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f0f2f5;
`;

const StatusDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: ${({ $statusColor }) => $statusColor};
`;

const getStatusColor = (status) => {
  switch (status) {
    case 'Available':
      return 'green';
    case 'On Call':
      return 'yellow';
    case 'Offline':
      return 'red';
    default:
      return 'grey';
  }
};

const getStatusMessage = (status) => {
  switch (status) {
    case 'Available':
      return 'Verfügbar';
    case 'On Call':
      return 'Im Anruf';
    case 'Offline':
      return 'Offline';
    default:
      return 'Unknown';
  }
};

const Agents = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axiosInstance.get('/agents');
        setAgents(response.data);
      } catch (error) {
        console.error('Error fetching agents', error);
        setSnackbar({ open: true, message: 'Error fetching agents', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();

    const socket = io('https://blub.ai', {
      path: '/socket.io',
      transports: ['websocket'], // Force the use of WebSockets
    });

    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('update_agent_status', (updatedAgent) => {
      setAgents((prevAgents) => {
        return prevAgents.map((agent) =>
          agent.ConfigID === parseInt(updatedAgent.ConfigID, 10)
            ? { ...agent, Status: updatedAgent.Status, ConversationID: updatedAgent.ConversationID }
            : agent
        );
      });
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleShowCall = async (conversationId, configId) => {
    setLoading(true);
    try {
      if (!conversationId) {
        const response = await axiosInstance.get(`/agents/${configId}/conversation`);
        if (response.data && response.data.CurrentConversation) {
          navigate(`/conversation/${response.data.CurrentConversation}`);
        } else {
          console.error('Current conversation not found for the agent');
          setSnackbar({ open: true, message: 'Current conversation not found', severity: 'error' });
        }
      } else {
        navigate(`/conversation/${conversationId}`);
      }
    } catch (error) {
      console.error('Error fetching current conversation', error);
      setSnackbar({ open: true, message: 'Error fetching conversation', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <PageContainer>
      <Helmet>
    <title>blub.ai - Agenten</title>
    <meta name="description" content="Verwalten Sie Ihre Agenten und deren Anrufzuweisungen im blub.ai-Dashboard." />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="canonical" href="https://www.blub.ai/agents" />
</Helmet>

      <Sidebar />
      <ContentContainer>
        <Typography variant="h3" component="h1" align="center" gutterBottom>
          Agenten
        </Typography>
        {loading ? (
          <Grid container spacing={3}>
            {Array.from(new Array(4)).map((_, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Skeleton variant="rectangular" height={200} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {agents.map((agent) => (
              <Grid item xs={12} md={6} lg={4} key={agent.ConfigID}>
                <Card sx={{ position: 'relative', padding: 2 }}>
                  <StatusDot $statusColor={getStatusColor(agent.Status)} />
                  <CardContent>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                      {agent.Username}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Status: {getStatusMessage(agent.Status)}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {agent.Status === 'On Call' && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleShowCall(agent.ConversationID, agent.ConfigID)}
                        fullWidth
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : 'Zeige Anruf'}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </ContentContainer>
    </PageContainer>
  );
};

export default Agents;
