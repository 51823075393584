// Dashboard.js
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import Sidebar from '../../components/Sidebar';
import Statistics from '../../components/Statistics';
import LimitsAndFeatures from '../../components/LimitsAndFeatures';
import { Helmet } from 'react-helmet';
const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f0f2f5;

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

const Header = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Dashboard = () => {
  const { user, error } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        navigate('/login');
      }, 3000); // Redirect after 3 seconds
    }
  }, [error, navigate]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <DashboardContainer>
      <Helmet>
    <title>blub.ai - Dashboard</title>
    <meta name="description" content="Verwalten Sie Ihre Anrufdaten, Statistiken und Einstellungen in Ihrem blub.ai-Dashboard. Ihr zentraler Hub für effizientes Anrufmanagement." />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="canonical" href="https://www.blub.ai/dashboard" />
</Helmet>

      <Sidebar />
      <ContentContainer>
        <Header>Dashboard</Header>
        <LimitsAndFeatures />
      </ContentContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
