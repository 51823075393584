import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('G-249MNDW1M7');
};

export const logPageView = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};
