import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
import Footer from './Footer';

const ImageFeatures = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const features = [
    {
        "label": "KI Gesprächsführung",
        "title": "Selbstständige Gesprächsführung durch die KI",
        "description": "Unsere fortschrittliche KI ermöglicht eine vollständig autonome Gesprächsführung. Definieren Sie in den Einstellungen mit einfacher Texteingabe, wer die KI ist und welche Aufgaben sie erfüllen soll. Basierend auf diesen Vorgaben entwickelt die KI eine eigene \"Persönlichkeit\", die wie ein menschlicher Mitarbeiter agiert.",
        "bulletPoint": "✓ Autonome Gesprächsführung",
        "image": "https://img.ashub.de/blub-ai/features/blub-smart-talk.png"
    },
    {
      label: "Ausführliche Einsicht",
      title: "Detailliertes Gesprächsprotokoll",
      description: "Erhalten Sie tiefe Einblicke in die Themen und Inhalte aller Gespräche mit Ihrem KI-Anrufbeantworter. Verfolgen Sie in Echtzeit, welche Anliegen Ihre Kunden haben und wie diese Gespräche ablaufen. So behalten Sie stets die Kontrolle und optimieren die Kommunikation mit Ihrem Unternehmen.",
      bulletPoint: "✓ Jeder Anruf einsehbar",
      image: "https://img.ashub.de/blub-ai/features/blub-conversations.jpg"
    },
    {
      label: "Anrufe Live verfolgen",
      title: "Sehen was passiert",
      description: "Mit der Live-Anrufverfolgung können Sie in Echtzeit überwachen, was Ihre Agenten gerade tun. Ihr KI-Anrufbeantworter bietet volle Transparenz, sodass Sie jederzeit sehen, welche Anrufe gerade bearbeitet werden und wie die Performance Ihres Teams aussieht.",
      bulletPoint: "✓ Echtzeitüberwachung",
      image: "https://img.ashub.de/blub-ai/features/blub-live-call.jpg"
    },
    {
      label: "Detaillierte Statistiken",
      title: "Halten Sie alles im Blick",
      description: "Unser KI-Anrufbeantworter erstellt umfassende Statistiken zu jedem Gespräch. Analysieren Sie die Gesprächsdauer, häufige Themen und die Performance Ihrer Agenten. Diese Daten helfen Ihnen, fundierte Entscheidungen zu treffen und die Kundenzufriedenheit zu erhöhen.",
      bulletPoint: "✓ Umfassende Anrufstatistiken",
      image: "https://img.ashub.de/blub-ai/features/blub-statistics.jpg"
    },
    {
      label: "Alle Agenten überwachen",
      title: "Sehen Sie den Status aller Agenten, jederzeit",
      description: "Behalten Sie den Überblick über alle Ihre Agenten und deren aktuellen Status. Unser KI-System zeigt Ihnen jederzeit an, welche Agenten aktiv sind und wie viele Anrufe derzeit bearbeitet werden.",
      bulletPoint: "✓ Agentenüberwachung in Echtzeit",
      image: "https://img.ashub.de/blub-ai/features/blub-multi-agents.jpg"
    },
    {
      label: "Eigene Kategorien",
      title: "Erstellen Sie eigene Kategorien",
      description: "Personalisieren Sie die Anrufbearbeitung mit eigenen Kategorien. Unsere KI analysiert den Gesprächsverlauf und klassifiziert Anrufe automatisch basierend auf den von Ihnen festgelegten Kategorien. Das macht die Anrufauswertung einfacher und effizienter.",
      bulletPoint: "✓ Automatische Anrufkategorisierung",
      image: "https://img.ashub.de/blub-ai/features/blub-custom-categories.jpg"
    },
    {
      label: "Eigene Datenabfrage",
      title: "Konfigurieren Sie selbst, welche Informationen für Sie wichtig sind",
      description: "Mit unserem KI-Anrufbeantworter entscheiden Sie, welche Daten während eines Gesprächs abgefragt werden sollen. Sie können die Fragen und den Gesprächsverlauf individuell an Ihre Bedürfnisse anpassen, um die wichtigsten Informationen von Ihren Kunden zu erhalten.",
      bulletPoint: "✓ Flexible Datenabfragen",
      image: "https://img.ashub.de/blub-ai/features/blub-custom-querys.jpg"
    },
    {
      label: "Ihr Mitarbeiter, Ihre Einstellungen",
      title: "Konfigurieren Sie den Agenten so, dass er in Ihr Team passt",
      description: "Unser KI-Anrufbeantworter bietet umfangreiche Konfigurationsmöglichkeiten. Sie können festlegen, wie Anrufe angenommen werden, wie lange Gespräche dauern dürfen und welche Begrüßungsansagen verwendet werden sollen. So passt sich der Anrufbeantworter perfekt an Ihre Bedürfnisse an.",
      bulletPoint: "✓ Anpassbare Agenteneinstellungen",
      image: "https://img.ashub.de/blub-ai/features/blub-configuration.jpg"
    },
    {
      label: "Smarte Aktionen",
      title: "Geben Sie Ihrem Agenten Funktionen",
      description: "Unser KI-Anrufbeantworter kann Gespräche intelligent weiterleiten. Ob zur Technikabteilung oder zu einem spezifischen Mitarbeiter – alles ist möglich. Sie können sogar festlegen, dass der Anruf automatisch beendet wird, wenn ein bestimmter Tonfall erkannt wird.",
      bulletPoint: "✓ Intelligente Gesprächsweiterleitung",
      image: "https://img.ashub.de/blub-ai/features/blub-smart-actions.jpg"
    }
  ];

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Inline styles for the modal to center it
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
      maxWidth: '90%',
      maxHeight: '90%',
      overflow: 'auto'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000
    }
  };

  return (
    <div className="max-w-9xl mx-auto px-6 lg:px-8 py-16 bg-white">
      {/* Section Heading */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Unsere Features</h1>
        <p className="text-lg text-gray-600">Entdecke, wie unsere Lösungen dir helfen, die Kontrolle zu behalten und Einblicke in die Gespräche mit deinen Kunden zu gewinnen.</p>
      </div>
      
      {/* Feature Blocks */}
      <div className="space-y-12">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className={`flex flex-col-reverse md:flex-row ${index % 2 !== 0 ? 'md:flex-row-reverse' : ''} items-center border border-gray-250 rounded-lg shadow-lg p-6 bg-gradient-to-r from-gray-100 to-gray-50 hover:from-blue-75`}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            whileHover={{ scale: 1.01, boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)" }}

          >
            <div className="md:w-1/2 px-4">
              <div className="mb-4 flex items-center">
                <motion.span
                  className="text-xs uppercase tracking-wider bg-gray-200 py-1 px-3 rounded-md text-gray-700"
                  whileHover={{ scale: 1.1 }}
                >
                  {feature.label}
                </motion.span>
              </div>
              <h2 className="text-2xl font-semibold mb-4 leading-tight">{feature.title}</h2>
              <p className="text-gray-600 mb-4">{feature.description}</p>
              <motion.div
                className="mt-4 text-sm font-medium text-gray-700"
                whileHover={{ x: 5 }}
              >
                <span>{feature.bulletPoint}</span>
                
              </motion.div>
            </div>
            <div className="md:w-1/2 px-4">
              <motion.img
                src={feature.image}
                alt={feature.title}
                className="rounded-lg shadow-lg border border-gray-300 cursor-pointer"
                whileHover={{ scale: 1.02 }}
                onClick={() => openModal(feature.image)}
              />
            </div>
          </motion.div>
        ))}
      </div>

      {/* Modal for Full Image */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Full Image Modal"
          style={customStyles}
        >
          <motion.div
            className="relative"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          >
            <img src={selectedImage} alt="Full-size" className="rounded-lg max-w-full max-h-screen" />
            <button
  onClick={closeModal}
  className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600"
>
  X
</button>
          </motion.div>
        </Modal>
      )}

<div className="text-center mt-16 mb-24">
  <h3 className="text-2xl font-semibold text-grey-600 mb-4">Bereit, den nächsten Schritt zu gehen?</h3>
  <p className="text-lg text-gray-600 mb-6">
    Entdecken Sie das volle Potenzial unserer KI-Lösung und optimieren Sie Ihre Kundenkommunikation. Fordern Sie noch heute eine kostenlose Demo an!
  </p>
  <a
    href="/demo"
    className="inline-block px-8 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition duration-300"
  >
    Demo anfordern
  </a>
</div>

      <Footer />
    </div>
  );
};

export default ImageFeatures;
