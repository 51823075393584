import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, Info as InfoIcon, ContentCopy as ContentCopyIcon, Edit as EditIcon, FileCopy as FileCopyIcon } from '@mui/icons-material';
import axiosInstance from '../../axiosConfig';

const ChatSettings = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [newDisplayName, setNewDisplayName] = useState('');
  const [editingKey, setEditingKey] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const defaultConfig = {
    DisplayName: '',
    InitialMessage: '',
    LogoLink: '',
    SmtpFromEmail: '',
    SmtpFromName: '',
    SmtpHost: '',
    SmtpPort: '',
    SmtpUser: '',
    Documents: []
  };

  const [apiConfig, setApiConfig] = useState(defaultConfig);

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await axiosInstance.get('/chat/apikeys/customer');
        setApiKeys(response.data);
      } catch (error) {
        setAlertMessage('Fehler beim Laden der API-Schlüssel.');
      }
    };

    fetchApiKeys();
  }, []);

  const handleCreateApiKey = async () => {
    try {
      const response = await axiosInstance.post('/chat/apikeys', { display_name: newDisplayName });
      setApiKeys([...apiKeys, response.data]);
      setNewDisplayName('');
      setAlertMessage('API-Schlüssel erfolgreich erstellt.');
    } catch (error) {
      setAlertMessage('Fehler beim Erstellen des API-Schlüssels.');
    }
  };

  const handleDeleteApiKey = async (keyId) => {
    try {
      await axiosInstance.delete(`/chat/apikeys/${keyId}`);
      setApiKeys(apiKeys.filter((key) => key.apikey_id !== keyId));
      setAlertMessage('API-Schlüssel erfolgreich gelöscht.');
    } catch (error) {
      setAlertMessage('Fehler beim Löschen des API-Schlüssels.');
    }
  };

  const handleOpenDialog = (key) => {
    setEditingKey(key);
    const documents = key.documents && Array.isArray(key.documents) ? key.documents : [];
    setApiConfig({
      DisplayName: key.display_name || '',
      InitialMessage: key.initial_message || '',
      LogoLink: key.logo_link || '',
      SmtpFromEmail: key.smtp_from_email || '',
      SmtpFromName: key.smtp_from_name || '',
      SmtpHost: key.smtp_host || '',
      SmtpPort: key.smtp_port || '',
      SmtpUser: key.smtp_user || '',
      Documents: documents
    });
    setDocuments(documents);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEditingKey(null);
    setOpenDialog(false);
  };

  const handleChangeConfig = (event) => {
    const { name, value } = event.target;
    setApiConfig((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddDocument = () => {
    setDocuments([...documents, '']);
  };

  const handleChangeDocument = (index, value) => {
    const updatedDocuments = documents.map((doc, i) => (i === index ? value : doc));
    setDocuments(updatedDocuments);
    setApiConfig((prevConfig) => ({
      ...prevConfig,
      Documents: updatedDocuments
    }));
  };

  const handleRemoveDocument = (index) => {
    const updatedDocuments = documents.filter((_, i) => i !== index);
    setDocuments(updatedDocuments);
    setApiConfig((prevConfig) => ({
      ...prevConfig,
      Documents: updatedDocuments
    }));
    setAlertMessage('Dokument erfolgreich entfernt.');
  };

  const handleSaveConfig = async () => {
    try {
      const updatedConfig = { ...apiConfig, Documents: documents };
      await axiosInstance.put(`/chat/apikeys/${editingKey.apikey_id}/settings`, updatedConfig);
      setApiKeys(apiKeys.map((key) => (key.apikey_id === editingKey.apikey_id ? { ...key, config: updatedConfig } : key)));
      handleCloseDialog();
      setAlertMessage('API-Schlüssel erfolgreich aktualisiert.');
    } catch (error) {
      setAlertMessage('Fehler beim Speichern der API-Schlüsselkonfiguration.');
    }
  };

  const handleCopyIframeCode = (apiKey) => {
    const iframeCode = `
  <iframe id="chat-widget" src="https://chat-web-embed.blub.ai/" style="border:none;width:90px;height:90px;"></iframe>
  <script>
    (function() {
      const iframe = document.getElementById('chat-widget');
      const apiKey = '${apiKey}';
      iframe.style.position = 'fixed';
      iframe.style.bottom = '20px';
      iframe.style.right = '20px';
      iframe.style.zIndex = '1000';
      iframe.onload = function() {
        iframe.contentWindow.postMessage({ apiKey: apiKey }, 'https://chat-web-embed.blub.ai/');
      };
      window.addEventListener('message', function(event) {
        if (event.origin !== 'https://chat-web-embed.blub.ai') return;
        if (event.data.chatOpen !== undefined) {
          if (event.data.chatOpen) {
            iframe.style.width = '350px';
            iframe.style.height = '520px';
          } else {
            iframe.style.width = '90px';
            iframe.style.height = '90px';
          }
        }
      });
    })();
  </script>`;
    navigator.clipboard.writeText(iframeCode);
    setSnackbarOpen(true);
    setAlertMessage('iFrame-Code in die Zwischenablage kopiert.');
  };
  

  const handleDuplicateApiKey = async (key) => {
    try {
      const duplicatedConfig = {
        display_name: `${key.display_name}_copy`,
        initial_message: key.initial_message,
        smtp_from_email: key.smtp_from_email,
        smtp_from_name: key.smtp_from_name,
        smtp_host: key.smtp_host,
        smtp_port: key.smtp_port,
        smtp_user: key.smtp_user,
        documents: key.documents
      };
      const response = await axiosInstance.post('/chat/apikeys', duplicatedConfig);
      setApiKeys([...apiKeys, response.data]);
      setAlertMessage('API-Schlüssel erfolgreich dupliziert.');
    } catch (error) {
      setAlertMessage('Fehler beim Duplizieren des API-Schlüssels.');
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      {alertMessage && (
        <Alert severity="info" onClose={() => setAlertMessage('')}>
          {alertMessage}
        </Alert>
      )}

      <Typography variant="h4" gutterBottom>
        Chat API-Schlüssel Verwaltung
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <TextField
            fullWidth
            label="Display Name"
            value={newDisplayName}
            onChange={(e) => setNewDisplayName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="API-Schlüssel Display Name eingeben">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={handleCreateApiKey}>
            Erstellen
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 3 }}>
        {apiKeys.map((key) => (
          <Grid item xs={12} key={key.apikey_id}>
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} bgcolor="grey.200" borderRadius={2}>
              <Typography>{key.display_name}</Typography>
              <Box>
                <Tooltip title="API-Schlüssel bearbeiten">
                  <IconButton onClick={() => handleOpenDialog(key)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="API-Schlüssel löschen">
                  <IconButton color="error" onClick={() => handleDeleteApiKey(key.apikey_id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="iFrame-Code kopieren">
                  <IconButton onClick={() => handleCopyIframeCode(key.api_key)}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="API-Schlüssel duplizieren">
                  <IconButton onClick={() => handleDuplicateApiKey(key)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>API-Schlüssel Konfiguration</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Display Name"
                name="DisplayName"
                value={apiConfig.DisplayName}
                onChange={handleChangeConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Initial Message"
                name="InitialMessage"
                value={apiConfig.InitialMessage}
                onChange={handleChangeConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Logo Link"
                name="LogoLink"
                value={apiConfig.LogoLink}
                onChange={handleChangeConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="SMTP From Email"
                name="SmtpFromEmail"
                value={apiConfig.SmtpFromEmail}
                onChange={handleChangeConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="SMTP From Name"
                name="SmtpFromName"
                value={apiConfig.SmtpFromName}
                onChange={handleChangeConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="SMTP Host"
                name="SmtpHost"
                value={apiConfig.SmtpHost}
                onChange={handleChangeConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="SMTP Port"
                name="SmtpPort"
                type="number"
                value={apiConfig.SmtpPort}
                onChange={handleChangeConfig}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="SMTP User"
                name="SmtpUser"
                value={apiConfig.SmtpUser}
                onChange={handleChangeConfig}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Dokumente</Typography>
              {documents.map((doc, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <TextField
                    fullWidth
                    label={`Dokument ${index + 1}`}
                    value={doc}
                    onChange={(e) => handleChangeDocument(index, e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <Tooltip title="Dokument entfernen">
                    <IconButton onClick={() => handleRemoveDocument(index)} color="error" sx={{ ml: 2 }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ))}
              <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddDocument}>
                Dokument hinzufügen
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Abbrechen</Button>
          <Button onClick={handleSaveConfig} variant="contained">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="iFrame-Code in die Zwischenablage kopiert"
        autoHideDuration={3000}
      />
    </Box>
  );
};

export default ChatSettings;
