// ChatsPage.js
import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import ChatTable from '../../components/ChatTable';
import { Helmet } from 'react-helmet';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f0f2f5;
`;

const Header = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const ChatsPage = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>blub.ai - Chats Übersicht</title>
        <meta name="description" content="Verwalten Sie Ihre Chats und Feedback im blub.ai-Dashboard." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.blub.ai/chats" />
      </Helmet>

      <Sidebar />
      <ContentContainer>
        <Header>Chats Übersicht</Header>
        <ChatTable />
      </ContentContainer>
    </PageContainer>
  );
};

export default ChatsPage;
