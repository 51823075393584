import React, { useEffect, useRef } from 'react';

const AudioStream = ({ socket, isSubscribed }) => {
    const audioContextRef = useRef(null);
    const bufferQueue = useRef([]);
    const BUFFER_THRESHOLD = 5; // Number of packets to accumulate before playback
    const isPlayingRef = useRef(false); // Track if audio is currently playing

    // Assume incoming audio sample rate (e.g., 16000 for 16 kHz or 8000 for 8 kHz)
    const incomingSampleRate = 8000;

    useEffect(() => {
        if (!socket) {
            console.log("No socket provided");
            return;
        }

        console.log("Initializing AudioContext");
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

        // Log the sample rate of the AudioContext
        console.log("AudioContext Sample Rate:", audioContextRef.current.sampleRate);

        socket.on("rtp_packet_stereo", (dataStereo) => {
            if (isSubscribed) {
                console.log("Received rtp_packet_stereo of length:", dataStereo.byteLength);
                bufferQueue.current.push(dataStereo);

                // Start processing if we have enough packets in the buffer and not already playing
                if (bufferQueue.current.length >= BUFFER_THRESHOLD && !isPlayingRef.current) {
                    console.log("Starting buffer processing");
                    processBufferQueue();
                }
            } else {
                console.log("Not subscribed, ignoring rtp_packet_stereo");
            }
        });

        const processBufferQueue = () => {
            if (bufferQueue.current.length > 0 && !isPlayingRef.current) {
                // Concatenate packets into a single buffer
                const packetsToPlay = bufferQueue.current.splice(0, BUFFER_THRESHOLD);
                const concatenatedBuffer = concatenateBuffers(packetsToPlay);

                console.log("Playing concatenated buffer of length:", concatenatedBuffer.byteLength);
                playStereoPCMData(concatenatedBuffer, () => {
                    console.log("Finished playing buffer, checking for next packets in queue");
                    isPlayingRef.current = false; // Mark that playback has finished

                    // After the current buffer is played, check if more packets are available
                    if (bufferQueue.current.length > 0) {
                        processBufferQueue(); // Continue playing the next buffer
                    } else {
                        console.log("Buffer queue is empty, waiting for new packets");
                    }
                });
            }
        };

        const concatenateBuffers = (buffers) => {
            const totalLength = buffers.reduce((acc, buffer) => acc + buffer.byteLength, 0);
            const concatenated = new Uint8Array(totalLength);
            let offset = 0;

            buffers.forEach(buffer => {
                concatenated.set(new Uint8Array(buffer), offset);
                offset += buffer.byteLength;
            });

            return concatenated.buffer;
        };

        const playStereoPCMData = (data, onEnded) => {
            const arrayBuffer = new Uint8Array(data).buffer;
            const dataView = new DataView(arrayBuffer);

            const frameCount = data.byteLength / 4; // 2 bytes per sample per channel
            const outputSampleRate = audioContextRef.current.sampleRate;

            // Calculate resampling ratio
            const resampleRatio = outputSampleRate / incomingSampleRate;
            const resampledFrameCount = Math.floor(frameCount * resampleRatio);

            const buffer = audioContextRef.current.createBuffer(2, resampledFrameCount, outputSampleRate); // 2 channels (stereo)

            const leftChannel = buffer.getChannelData(0);
            const rightChannel = buffer.getChannelData(1);

            /*for (let i = 0; i < resampledFrameCount; i++) {
                const originalIndex = Math.floor(i / resampleRatio);
                leftChannel[i] = dataView.getInt16(originalIndex * 4, true) / 32768;  // Left channel sample
                rightChannel[i] = dataView.getInt16(originalIndex * 4 + 2, true) / 32768;  // Right channel sample
            }
*/
            console.log("Connecting and starting audio source");
            const source = audioContextRef.current.createBufferSource();
            source.sampleRate = incomingSampleRate;
            source.buffer = buffer;
            source.connect(audioContextRef.current.destination);

            source.onended = () => {
                console.log("Audio playback ended");
                onEnded(); // Trigger the onEnded callback
            };

            isPlayingRef.current = true; // Mark that playback is in progress
            source.start();
        };

        return () => {
            if (socket) {
                console.log("Cleaning up, removing socket listeners");
                socket.off("rtp_packet_stereo");
            }
        };
    }, [socket, isSubscribed]);

    return null;
};

export default AudioStream;
