import React from 'react';
import { FaPhoneAlt, FaCog, FaRocket } from 'react-icons/fa';

const steps = [
  {
    icon: <FaPhoneAlt className="text-blue-600 w-8 h-8" />,
    title: 'Automatisches Anrufmanagement',
    description: 'Unser KI-Anrufbeantworter übernimmt das Anrufmanagement für Sie. Durch automatische Anrufannahme und smarte Gesprächsführung bleiben alle wichtigen Informationen erfasst. Perfekt für Unternehmen, die Effizienz und Zeit sparen wollen.'
  },
  {
    icon: <FaCog className="text-green-600 w-8 h-8" />,
    title: 'Individuelle Abfragen konfigurieren',
    description: 'Passen Sie die Abfragen an die Bedürfnisse Ihres Unternehmens an. Unser KI-System sammelt automatisch die für Sie relevanten Informationen und sorgt für eine effiziente Datenverarbeitung.'
  },
  {
    icon: <FaRocket className="text-purple-600 w-8 h-8" />,
    title: 'Effizienzsteigerung durch Automatisierung',
    description: 'Automatisierte Prozesse steigern die Effizienz Ihres Unternehmens. Unser KI-gestütztes Anrufmanagement verbessert die Kundenzufriedenheit und spart wertvolle Zeit für Ihr Team.'
  },
];

const HowItWorks = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-4xl font-bold leading-tight text-black sm:text-5xl lg:text-6xl">Wie funktioniert unser KI-Anrufbeantworter?</h2>
          <p className="max-w-lg mx-auto mt-6 text-lg leading-relaxed text-gray-700">
            Erfahren Sie, wie unser automatisiertes Anrufmanagement Ihr Team entlastet und für gesteigerte Effizienz sorgt. Hier sehen Sie, wie einfach Sie wertvolle Zeit sparen und die Kundenzufriedenheit steigern können.
          </p>
        </div>
        <div className="relative mt-12 lg:mt-20">
          <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
            <img className="w-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg" alt="Automatisiertes Anrufmanagement mit KI" />
          </div>
          <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
            {steps.map((step, index) => (
              <div key={index} className="group">
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow-lg group-hover:bg-blue-100 transition-all">
                  {step.icon}
                </div>
                <h3 className="mt-6 text-2xl font-semibold leading-tight text-black md:mt-10 group-hover:text-blue-600 transition-colors">{step.title}</h3>
                <p className="mt-4 text-base text-gray-600 group-hover:text-gray-800 transition-colors">{step.description}</p>
              </div>
            ))}
          </div>
          <div className="text-center mt-12">
            <a href="/features" className="inline-block px-8 py-4 mt-6 text-lg font-semibold text-white bg-blue-600 rounded-lg shadow hover:bg-blue-700 transition-all">
              Weitere Funktionen entdecken
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
