import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box, Tabs, Tab } from '@mui/material';
import Sidebar from '../../components/Sidebar';
import BenutzerVerwaltung from '../../components/BenutzerVerwaltung';
import ConversationTokenUsage from '../../components/ConversationTokenUsage';
import AdminDashboard from '../../components/AdminDashboard';
import CustomerManagement from '../../components/CustomerManager';
import TemplateManagement from '../../components/TemplateManagement';
import ApiKeyManagement from '../../components/ApiKeyManagement'; // Import the new component
import axiosInstance from '../../axiosConfig';
import CustomerSettings from '../../components/CustomerSettings';

import { useAuth } from '../../AuthContext';
import { Helmet } from 'react-helmet';
const AdminSettingsPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const checkRole = async () => {
      try {
        const response = await axiosInstance.get('/user_info');
        const data = response.data;
        if (data.role === 'Global Admin') {
          setIsAuthorized(true);
        }
      } catch (error) {
        console.error('Error checking user role:', error);
      }
    };

    if (user) {
      checkRole();
    }
  }, [user]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!isAuthorized) {
    return <div>Access denied</div>;
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
      <Helmet>
    <title>blub.ai - Admin-Einstellungen</title>
    <meta name="description" content="Verwalten Sie Ihre Admin-Einstellungen und Benutzerrechte im blub.ai-Dashboard." />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="canonical" href="https://www.blub.ai/admin" />
</Helmet>

      <Sidebar />
      <Container sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
        <Typography variant="h3" component="h1" align="center" gutterBottom>
          Admin Panel
        </Typography>
        <Paper sx={{ p: { xs: 2, md: 4} }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Kundenverwaltung" />
            <Tab label="Kundeneinstellungen" /> 
            <Tab label="Benutzerverwaltung" />
            <Tab label="Token Usage" />
            <Tab label="Dashboard" />
            <Tab label="Template Management" />
            <Tab label="API Key Management" /> 
            
          </Tabs>
          <Box sx={{ mt: { xs: 2, md: 4 } }}>
            {activeTab === 0 && <CustomerManagement />}
            {activeTab === 1 && <CustomerSettings />}
            {activeTab === 2 && <BenutzerVerwaltung />}
            {activeTab === 3 && <ConversationTokenUsage />}
            {activeTab === 4 && <AdminDashboard />}
            {activeTab === 5 && <TemplateManagement />}
            {activeTab === 6 && <ApiKeyManagement />} {/* Render the new component */}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default AdminSettingsPage;
