import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography } from '@mui/material';
import axiosInstance from '../axiosConfig';

const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [customerName, setCustomerName] = useState('');

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleCustomerChange = (event) => {
    const customerId = event.target.value;
    const customer = customers.find(c => c.id === customerId);
    setSelectedCustomer(customerId);
    setCustomerName(customer ? customer.name : '');
  };

  const handleSave = async () => {
    try {
      if (selectedCustomer) {
        await axiosInstance.put(`/admin/customers/${selectedCustomer}`, { name: customerName });
      } else {
        await axiosInstance.post('/admin/customers', { name: customerName });
      }
      fetchCustomers();
      setSelectedCustomer('');
      setCustomerName('');
    } catch (error) {
      console.error('Error saving customer:', error);
    }
  };

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Kundenverwaltung
      </Typography>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel>Kunde</InputLabel>
        <Select value={selectedCustomer} onChange={handleCustomerChange} label="Kunde">
          <MenuItem value="">
            <em>Neuen Kunden erstellen</em>
          </MenuItem>
          {customers.map((customer) => (
            <MenuItem key={customer.id} value={customer.id}>
              {customer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Kundenname"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
        sx={{ mb: 4 }}
      />
      <Button variant="contained" color="primary" onClick={handleSave}>
        Speichern
      </Button>
    </Box>
  );
};

export default CustomerManagement;
