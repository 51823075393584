import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/Navbar';
import Hero from '../../components/Hero';
import HowItWorks from '../../components/HowItWorks';
import Features from '../../components/Features';
import Benefits from '../../components/Benefits';
import Applications from '../../components/Applications';
import Pricing from '../../components/Pricing';
import AboutUs from '../../components/AboutUs';
import Footer from '../../components/Footer';
import StepsToStart from '../../components/StepsToStart';
const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>blub.ai - Virtueller Telefonassistent</title>
        <meta
          name="description"
          content="Automatisieren Sie Ihre Anrufbearbeitung mit blub.ai, dem intelligenten virtuellen Telefonassistenten. Entlasten Sie Ihre Mitarbeiter und bieten Sie Ihren Anrufern eine erstklassige Erfahrung."
        />
        <meta
          name="keywords"
          content="Virtueller Telefonassistent, Automatisierte Anrufbearbeitung, Künstliche Intelligenz für Anrufe, Anrufmanagement, Kundenservice-Assistent, DSGVO-konforme Anrufbearbeitung"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.blub.ai" />
      </Helmet>

      <div id="hero">
        <Hero />
      </div>
      <div id="how-it-works">
        <HowItWorks />
      </div>
      <div id="features">
        <Features />
      </div>
      <div id="benefits">
        <Benefits />
      </div>
      <div id="applications">
        <Applications />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div id="steps-to-start">
        <StepsToStart/>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
