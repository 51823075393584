import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosInstance from '../axiosConfig'; 
import { useAuth } from '../AuthContext';
import { CircularProgress, Divider, Tooltip } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  background-color: #f8f9fa;
  min-height: 100vh;
  box-sizing: border-box;
`;

const Section = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #2c3e50;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
`;

const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const FeatureItem = styled.div`
  flex: 1;
  min-width: 180px;
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const FeatureLabel = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #2c3e50;
`;

const FeatureValue = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: #34495e;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressBar = styled.div`
  height: 10px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
`;

const ProgressFill = styled.div`
  height: 100%;
  width: ${(props) => props.width || '0%'};
  background-color: #27ae60;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
`;



const RecentActivity = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityItem = styled.div`
  background-color: #ecf0f1;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const ActivityTitle = styled.h4`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
`;

const ActivityDescription = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
`;


const ChangelogWrapper = styled.div`
  background-color: #ecf0f1;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  max-height: 300px;
  overflow-y: auto;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const DividerLine = styled(Divider)`
  margin: 20px 0;
`;
const DashboardLandingPage = () => {
  const [limits, setLimits] = useState(null);
  const [loading, setLoading] = useState(true);
  const [changelog, setChangelog] = useState(''); // Changelog als Markdown

  useEffect(() => {
    const fetchLimitsAndChangelog = async () => {
      try {
        const limitsResponse = await axiosInstance.get('/customerlimits');
        setLimits(limitsResponse.data);

        // Fetch Changelog (in Markdown format)
        const changelogResponse = await axiosInstance.get('/changelog');
        setChangelog(changelogResponse.data); // Erwartet, dass das Backend den Changelog als Markdown zurückgibt
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLimitsAndChangelog();
  }, []);

  if (loading) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    );
  }

  const calculateUsagePercentage = (current, max) => {
    return max > 0 ? ((current / max) * 100).toFixed(0) : 0;
  };

  return (
    <DashboardContainer>
      <Section>
        <SectionHeader>Aktuelle Limits und Features</SectionHeader>
        <SectionContent>
          <FeatureItem>
            <FeatureLabel>Maximale Agenten</FeatureLabel>
            <FeatureValue>{limits?.limits?.max_agents}</FeatureValue>
          </FeatureItem>
          <FeatureItem>
            <FeatureLabel>Maximale Mitarbeiter</FeatureLabel>
            <FeatureValue>{limits?.limits?.max_employees}</FeatureValue>
          </FeatureItem>
          <FeatureItem>
            <FeatureLabel>Maximale Chat-API-Schlüssel</FeatureLabel>
            <FeatureValue>{limits?.limits?.max_chat_api_keys}</FeatureValue>
          </FeatureItem>
          <FeatureItem>
            <FeatureLabel>Plan</FeatureLabel>
            <FeatureValue>{limits?.limits?.plan?.charAt(0).toUpperCase() + limits?.limits?.plan?.slice(1)}</FeatureValue>
          </FeatureItem>
          <FeatureItem>
            <FeatureLabel>Chat aktiviert</FeatureLabel>
            <FeatureValue>{limits?.limits?.chat_enabled ? 'Ja' : 'Nein'}</FeatureValue>
          </FeatureItem>
        </SectionContent>
      </Section>

      <DividerLine />

      <Section>
        <SectionHeader>Aktuelle Nutzungsstatistiken</SectionHeader>
        <SectionContent>
          <FeatureItem>
            <Tooltip title="Nutzung der Agentenlizenzen">
              <div>
                <FeatureLabel>Agentennutzung</FeatureLabel>
                <ProgressWrapper>
                  <ProgressBar>
                    <ProgressFill width={`${calculateUsagePercentage(limits?.current?.current_agents, limits?.limits?.max_agents)}%`} />
                  </ProgressBar>
                  <span>{`${calculateUsagePercentage(limits?.current?.current_agents, limits?.limits?.max_agents)}% genutzt`}</span>
                </ProgressWrapper>
              </div>
            </Tooltip>
          </FeatureItem>

          <FeatureItem>
            <Tooltip title="Nutzung der Mitarbeiterlizenzen">
              <div>
                <FeatureLabel>Mitarbeiter Lizenz Nutzung</FeatureLabel>
                <ProgressWrapper>
                  <ProgressBar>
                    <ProgressFill width={`${calculateUsagePercentage(limits?.current?.current_employees, limits?.limits?.max_employees)}%`} />
                  </ProgressBar>
                  <span>{`${calculateUsagePercentage(limits?.current?.current_employees, limits?.limits?.max_employees)}% genutzt`}</span>
                </ProgressWrapper>
              </div>
            </Tooltip>
          </FeatureItem>

          <FeatureItem>
            <Tooltip title="Nutzung der Chat-API-Schlüssel">
              <div>
                <FeatureLabel>Chat-API-Schlüssel Nutzung</FeatureLabel>
                <ProgressWrapper>
                  <ProgressBar>
                    <ProgressFill width={`${calculateUsagePercentage(limits?.current?.current_chat_api_keys, limits?.limits?.max_chat_api_keys)}%`} />
                  </ProgressBar>
                  <span>{`${calculateUsagePercentage(limits?.current?.current_chat_api_keys, limits?.limits?.max_chat_api_keys)}% genutzt`}</span>
                </ProgressWrapper>
              </div>
            </Tooltip>
          </FeatureItem>
        </SectionContent>
      </Section>

      <DividerLine />

      <Section>
        <SectionHeader>Changelog / News</SectionHeader>
        <ChangelogWrapper>
          {/* Changelog in Markdown anzeigen */}
          <ReactMarkdown>
            {changelog || 'Keine Neuigkeiten verfügbar.'}
          </ReactMarkdown>
        </ChangelogWrapper>
      </Section>
    </DashboardContainer>
  );
};

export default DashboardLandingPage;
