import React from 'react';
import { supabase } from '../../supabaseClient'; // Ensure the path to your Supabase client is correct
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import bxLogOut from '@iconify-icons/bx/bx-log-out';
import { Helmet } from 'react-helmet';
const LogoutButton = styled.button`
  padding: 10px 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c9302c;
  }
`;

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      localStorage.removeItem('token');
      navigate('/login'); // Redirect user to the login page on successful logout
    }
  };

  return (
    <LogoutButton onClick={handleLogout}>
      <Helmet>
    <title>blub.ai - Logout</title>
    <meta name="description" content="Sie haben sich erfolgreich von Ihrem blub.ai-Konto abgemeldet." />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="canonical" href="https://www.blub.ai/logout" />
</Helmet>

      <Icon icon={bxLogOut} /> Logout
    </LogoutButton>
  );
};

export default Logout;
