import React, { useState } from 'react';
import { motion } from 'framer-motion';
import 'tailwindcss/tailwind.css';
import { Typography, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const applications = [
  { 
    title: 'Arztpraxen', 
    benefits: [
      'Dashboard für alle Patientenanfragen',
      'Notfallerkennung und automatische Weiterleitung',
      'Automatisierte Terminvereinbarung und -bestätigung',
      'Beantwortung von Standardanfragen (Öffnungszeiten, Versicherungen)',
    ]
  },
  { 
    title: 'Rechtsanwaltskanzleien', 
    benefits: [
      'Mandanteninformationen automatisch erfassen',
      'Anfragen nach Priorität sortieren',
      'Routineanfragen automatisieren und vorqualifizieren',
      'Komplexe Fälle an spezialisierte Anwälte weiterleiten',
    ]
  },
  { 
    title: 'Kundenservicezentren', 
    benefits: [
      'Beantwortung häufiger Kundenanfragen',
      'Beschwerden entgegennehmen und eskalieren',
      'Allgemeine Informationen bereitstellen',
      'Arbeitslast des Teams reduzieren durch KI-Unterstützung',
    ]
  },
  { 
    title: 'Einzelhandel', 
    benefits: [
      'Kundenanfragen und Bestellungen automatisiert verwalten',
      'Produktverfügbarkeiten in Echtzeit mitteilen',
      'Aufnahme von Bestellungen und Rückfragen',
      'Informationen zu Rückgaberichtlinien und Öffnungszeiten',
    ]
  },
  { 
    title: 'Immobilienbüros', 
    benefits: [
      'Vereinbarung von Besichtigungen',
      'Automatisierte Beantwortung von Immobilienanfragen',
      'Qualifizierung potenzieller Kunden',
      'Zeiteinsparung durch Automatisierung wiederkehrender Anfragen',
    ]
  },
  { 
    title: 'Fitnessstudios', 
    benefits: [
      'Anfragen zu Kurszeiten und Mitgliedschaften beantworten',
      'Mitgliederverwaltung automatisieren',
      'Registrierung neuer Mitglieder ohne Personalaufwand',
      'Verfügbarkeit von Kursplätzen und Räumen in Echtzeit anzeigen',
    ]
  },
  { 
    title: 'Bildungsinstitutionen', 
    benefits: [
      'Automatisierte Verwaltung von Kursanfragen',
      'Einschreibungen und allgemeine Informationen automatisch verarbeiten',
      'Entlastung des Verwaltungspersonals',
      'Verbesserte Informationsverarbeitung durch KI-Unterstützung',
    ]
  },
  { 
    title: 'Hotels', 
    benefits: [
      'Bearbeitung von Buchungsanfragen rund um die Uhr',
      'Informationen zu Zimmerverfügbarkeiten in Echtzeit',
      'Verwaltung spezieller Gästeanfragen',
      'Optimierung des Gästeservices durch KI-Unterstützung',
    ]
  },
  { 
    title: 'Finanzdienstleistungen', 
    benefits: [
      'Verwaltung von Kundenanfragen zu Kontoinformationen',
      'Automatisierte Terminvereinbarungen für Beratungsgespräche',
      'Verwaltung von Routinedienstleistungen ohne manuelles Eingreifen',
      'Verbesserte Kundenzufriedenheit durch schnellere Reaktionszeiten',
    ]
  },
  { 
    title: 'IT-Support', 
    benefits: [
      'Erfassung und Kategorisierung technischer Anfragen',
      'Erste Lösungsvorschläge durch KI-basierte Supportsysteme',
      'Automatisierte Erstellung von Support-Tickets',
      'Effizienzsteigerung durch Vorqualifizierung der Anfragen',
    ]
  },
  { 
    title: 'Versicherungen', 
    benefits: [
      'Verwaltung von Anfragen zu Policen und Schadenmeldungen',
      'Automatisierung der Bearbeitung von Routineanfragen',
      'Eskalation dringender Anfragen an die richtigen Abteilungen',
      'Verbesserung der Kundenzufriedenheit durch sofortige Rückmeldungen',
    ]
  },
  { 
    title: 'Reisebüros', 
    benefits: [
      'Automatisierte Verwaltung von Reisebuchungen',
      'Bereitstellung von Informationen zu Verfügbarkeiten und Angeboten',
      'Schnelle Bearbeitung von Kundenanfragen durch KI-Unterstützung',
      'Erhöhung der Servicequalität bei gleichzeitiger Entlastung des Personals',
    ]
  },
];

// Function to split the applications array into rows for easier mapping
const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const Applications = () => {
  const [expandedCard, setExpandedCard] = useState({ row: null, column: null });

  const handleToggle = (rowIndex, colIndex) => {
    // Toggle the selected card
    if (expandedCard.row === rowIndex && expandedCard.column === colIndex) {
      setExpandedCard({ row: null, column: null }); // Close if the same card is clicked
    } else {
      setExpandedCard({ row: rowIndex, column: colIndex }); // Expand the selected card
    }
  };

  // Convert applications to a 2D array with 3 columns per row
  const applicationRows = chunkArray(applications, 3);

  return (
    <section className="relative py-10 bg-white sm:py-16 lg:py-24 overflow-hidden">
      {/* Background Elements */}
      <motion.div 
        className="absolute inset-0 bg-white opacity-20"
        animate={{ backgroundPosition: ['0% 0%', '100% 100%'] }}
        transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
        style={{ backgroundSize: '200% 200%' }}
      />

      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <Typography variant="h2" className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
            Anwendungsbereiche
          </Typography>
          <Typography variant="body1" className="mt-4 text-lg text-gray-600">
            Entdecken Sie, wie unsere KI-Lösung in verschiedenen Branchen eingesetzt werden kann.
          </Typography>
        </div>

        {/* Render rows */}
        {applicationRows.map((row, rowIndex) => (
          <div key={rowIndex} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
            {row.map((application, colIndex) => (
              <motion.div
                key={colIndex}
                className="bg-white shadow-lg rounded-lg p-6 cursor-pointer transform transition-all hover:scale-105"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: rowIndex * 0.1 + colIndex * 0.1 }}
              >
                <div className="flex justify-between items-center">
                  <Typography variant="h6" className="text-lg font-semibold text-gray-800">
                    {application.title}
                  </Typography>
                  <IconButton onClick={() => handleToggle(rowIndex, colIndex)}>
                    {expandedCard.row === rowIndex && expandedCard.column === colIndex ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </div>

                <Collapse in={expandedCard.row === rowIndex && expandedCard.column === colIndex}>
                  <ul className="mt-4 space-y-2">
                    {application.benefits.map((benefit, i) => (
                      <li key={i} className="flex items-center text-gray-600 text-sm">
                        <CheckCircleOutlineIcon className="text-blue-500 mr-2" />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </motion.div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Applications;
