import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Nav = styled.nav`
  height: 60px;
  background: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: white;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const Navbar = () => {
  return (
    <Nav>
      <h1>TommyAI</h1>
      <div>
        <NavLink to="/">Landing Page</NavLink>
        <NavLink to="/dashboard">Dashboard</NavLink>
      </div>
    </Nav>
  );
};

export default Navbar;
