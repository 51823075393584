import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Box, Typography, Paper, IconButton, Button, Divider } from '@mui/material';
import { Star, StarBorder, Refresh, ArrowBack } from '@mui/icons-material';
import Sidebar from '../components/Sidebar';
import axiosInstance from '../axiosConfig';

// Hilfsfunktion zur Darstellung von Feedback-Sternen
const renderFeedbackStars = (feedback) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(i < feedback ? <Star key={i} className="text-yellow-500" /> : <StarBorder key={i} className="text-gray-300" />);
  }
  return stars;
};

const ChatDetailPage = () => {
  const { chatId } = useParams(); // Hol dir die chatId aus der URL
  const [chat, setChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // API-Aufruf, um Chat-Details zu holen
  const fetchChatDetails = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/chat/chats/${chatId}`);
      setChat(response.data);
    } catch (error) {
      setError('Fehler beim Laden der Chat-Daten.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChatDetails();
  }, [chatId]);

  const handleBackClick = () => {
    navigate(-1); // Zurück zur vorherigen Seite
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={5}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" mt={5}>
        <Typography variant="h6">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" height="100vh">
      <Sidebar />
      
      <Box flex={1} display="flex" flexDirection="column" p={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" className="font-bold">
            Chat mit {chat.user.firstName} {chat.user.lastName}
          </Typography>
          <Box>
            <IconButton onClick={fetchChatDetails} aria-label="refresh">
              <Refresh />
            </IconButton>
            <Button variant="outlined" onClick={handleBackClick} startIcon={<ArrowBack />}>
              Zurück
            </Button>
          </Box>
        </Box>

        <Box display="flex" height="80vh">
          {/* Linke Seite: Nachrichten */}
          <Paper style={{ flex: 1, padding: '20px', marginRight: '10px', overflowY: 'auto', maxHeight: '80vh' }}>
            <Typography variant="h6" className="font-semibold mb-2">Nachrichten</Typography>
            <Divider className="mb-4" />
            {chat.messages?.length > 0 ? (
              chat.messages.map((message, index) => (
                <Box
                  key={message.id}
                  p={2}
                  mb={1}
                  style={{ 
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#f1f1f1', 
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant="body1">
                    <strong>{message.source === 0 ? chat.user.firstName + " " + chat.user.lastName : 'KI'}:</strong> {message.content}
                  </Typography>
                  <Typography variant="caption" className="text-gray-500">
                    {new Date(message.createdAt).toLocaleString()}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">Keine Nachrichten vorhanden.</Typography>
            )}
          </Paper>

          <Paper style={{ flex: 1, padding: '20px', marginLeft: '10px', overflowY: 'auto', maxHeight: '20vh' }}>
            <Typography variant="h6" className="font-semibold mb-2">Zusammenfassung</Typography>
            <Divider className="mb-4" />
            {chat.summary?.length > 0 ? (


                  <Typography variant="body1">{chat.summary}</Typography>


            ) : (
              <Typography variant="body2">Zusammenfassung noch nicht vorhanden.</Typography>
            )}
          </Paper>
          {/* Rechte Seite: Feedback */}
          <Paper style={{ flex: 1, padding: '20px', marginLeft: '10px', overflowY: 'auto', maxHeight: '20vh' }}>
            <Typography variant="h6" className="font-semibold mb-2">Feedback</Typography>
            <Divider className="mb-4" />
            {chat.feedbacks?.length > 0 ? (
              chat.feedbacks.map((feedback, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="body1">{feedback.comments || 'Kein Kommentar abgegeben.'}</Typography>
                  <Box>{renderFeedbackStars(feedback.rating)}</Box>
                </Box>
              ))
            ) : (
              <Typography variant="body2">Kein Feedback vorhanden.</Typography>
            )}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatDetailPage;
