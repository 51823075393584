import React from 'react';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
const AGB = () => {
  return (
    <section className="relative flex flex-col items-center justify-center text-center p-10 bg-gradient-to-r from-white to-blue-50 text-gray-900 min-h-screen overflow-hidden">
   <Helmet>
    <title>blub.ai - AGB</title>
    <meta name="description" content="Allgemeine Geschäftsbedingungen für die Nutzung von blub.ai." />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://www.blub.ai/agb" />
</Helmet>

      <motion.div 
        className="absolute inset-0 bg-gradient-to-r from-blue-50 to-blue-200 opacity-30"
        animate={{ backgroundPosition: ['0% 0%', '100% 100%'] }}
        transition={{ duration: 15, repeat: Infinity, ease: "linear" }}
        style={{ backgroundSize: '200% 200%' }}
      />

      <motion.div
        className="absolute -bottom-20 -left-20 w-96 h-96 bg-gradient-to-br from-blue-200 to-blue-500 rounded-full opacity-30"
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 10, repeat: Infinity, ease: "easeInOut" }}
      />
      <motion.div
        className="absolute -top-20 -right-20 w-96 h-96 bg-gradient-to-br from-blue-200 to-blue-500 rounded-full opacity-30"
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 10, repeat: Infinity, ease: "easeInOut", delay: 5 }}
      />

      <motion.div
        className="max-w-7xl relative z-10 text-left"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-5xl font-bold mb-4 text-center">Allgemeine Geschäftsbedingungen</h1>
        <div className="text-xl mb-4 space-y-6">
          <h2 className="text-3xl font-bold mt-10 mb-4">1. Geltung der AGB, Einsichtnahme, Zugriff</h2>
          <p>Bei Auftragserteilung an die Armann Systems GmbH erklärt sich der Kunde mit den Allgemeinen Geschäftsbedingungen der Armann Systems GmbH ausdrücklich einverstanden. Entgegenstehende Einkaufsbedingungen oder Allgemeine Geschäftsbedingungen des Kunden werden für das gegenständliche Rechtsgeschäft sowie für die gesamte Geschäftsbeziehung ausdrücklich ausgeschlossen und verlieren ihre Wirksamkeit. Sofern nichts anderes vereinbar wurde, gelten die AGB´s für den gesamten – auch zukünftigen – Umfang der geschäftlichen Beziehungen zwischen der Armann Systems GmbH und dem Kunden. Die AGB´s der Armann Systems GmbH in der jeweils aktuellen Fassung stehen dem Kunden jederzeit zur Einsichtnahme auf der Homepage der Armann Systems GmbH zur Verfügung und werden dem Kunden auf Wunsch auch auf elektronischem Wege zugesandt.</p>

          <h2 className="text-3xl font-bold mt-10 mb-4">2. Vertragsabschluss</h2>
          <p>Durch Zusendung einer Bestellung oder Rücksendung des von der Armann Systems GmbH erstellten und vom Kunden firmenmäßig gezeichneten Angebotes (Original) durch den Kunden an die Armann Systems GmbH, gilt der Vertrag zur Erbringung der gegenständlichen Leistungen als gültig abgeschlossen. Angebote der Armann Systems GmbH sind freibleibend (§ 145 BGB).</p>

          <h2 className="text-3xl font-bold mt-10 mb-4">3. Vertragsgegenstand und Leistungserbringung</h2>
          <p>Die Armann Systems GmbH ist ein System- und Softwarehaus. Im Bereich Systemhaus erbringt sie Dienstleistungen in den Bereichen EDV, Netzwerke, Datenbanken, kundenspezifische Softwareentwicklungen sowie Dienstleistungen im Lexware®-Umfeld. Im Bereich Softwarehaus werden Softwareprogramme bzw. Softwarelösungen entwickelt und vertrieben. Die unter den Vertragsparteien vereinbarten, genauen Leistungen ergeben sich aus dem im Auftrag dargestellten Leistungsumfang und beinhalten in der Regel der Lieferung von Hard- und Software und die Bereitstellung und Durchführung von Dienstleistungen aus den genannten Bereichen.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">a. Leistungsbeschreibung</h3>
          <p>Grundlage für die Erbringung von Dienstleistungen sowie Abwicklung von Projekten ist die schriftliche Leistungsbeschreibung, die die Armann Systems GmbH (je nach Umfang gegen Kostenberechnung) aufgrund der ihr zur Verfügung gestellten Unterlagen und Informationen ausarbeitet bzw. die der Kunde zur Verfügung stellt. Diese Leistungsbeschreibung (Pflichtenheft) wird von der Armann Systems GmbH und dem Kunden auf Richtigkeit und Vollständigkeit überprüft und mit ihrem Zuständigkeitsvermerk versehen; sie ist integraler Vertragsbestandteil. Später auftretende Änderungswünsche des Kunden können zu gesonderten Termin- und Preisvereinbarungen führen.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">b. Leistungserbringung, Termine</h3>
          <p>Alle Unterlagen und erforderlichen Mittel stellt der Kunde der Armann Systems GmbH im erforderlichen Umfang (siehe Auftrag) auf eigene Kosten zur Verfügung. Vereinbarte Liefertermine können seitens der Armann Systems GmbH nur dann eingehalten werden, wenn der Kunde zu den von der Armann Systems GmbH angegebenen Terminen alle notwendigen Vorleistungen und Unterlagen in der vereinbarten Form vollständig zur Verfügung stellt und im erforderlichen Ausmaß konstruktiv an der Projektabwicklung mitwirkt (Einhaltung von Terminen, Feedback, etc.) Die Ausarbeitung von Konzepten und Applikationen erfolgt nah Art und Umfang dieser vom Kunden zur Verfügung gestellten Unterlagen und Informationen. Für den Inhalt, die Ausgestaltung sowie die Rechte an diesen Unterlagen und Mitteln ist ausschließlich der Kunde verantwortlich. Die Armann Systems GmbH übernimmt diesbezüglich keine wie auch immer geartete Haftung. Ausschließlich der Kunde hat somit deren rechtliche Zulässigkeit (insbesondere urheber-, wettbewerbs- und markenrechtlich) allein zu prüfen.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">c. Abnahme</h3>
          <p>Alle Leistungen der Armann Systems GmbH bedürfen einer schriftlichen Abnahme. Software, Internet- und Datenbank-Applikationen bedürfen einer Abnahme durch den Kunden spätestens 14 Tage nach Fertigstellung, sonstige Leistungen innerhalb von sieben Tagen ab Erbringung. Lässt der Kunde den Zeitraum ohne Abnahme verstreichen, gilt die Leistung als abgenommen. Beim Erwerb von Nutzungsberechtigungen (Lizenzsoftware der Armann Systems GmbH) bestätigt der Kunde mit dem Auftrag die Kenntnis des Leistungsumfanges der bestellten Programme.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">d. Wartung</h3>
          <p>Der Kunde kann durch Abschluss eines Wartungsvertrages mit der Armann Systems GmbH hierin festgelegte Arbeiten und Dienstleistungen auf die Armann Systems GmbH übertragen. Dafür erhält die Armann Systems GmbH eine Vergütung, die in der Preisliste der Armann Systems GmbH festgelegt ist. Handelt es sich um Lizenzsoftware der Armann Systems GmbH, gelten die jeweils aktuellen Wartungsverträge, die separat abgeschlossen werden. Wartungsverträge werden grundsätzlich auf unbestimmte Dauer abgeschlossen. Bei Befristung verlängert sich der Vertrag stillschweigend automatisch um die ursprünglich vereinbarte Dauer, wenn von den Vertragsparteien nicht längstens einen Monat vor Ablauf der Vertragsdauer (ursprünglich oder bereits verlängert) schriftlich gekündigt wird. Maßgeblich für eine fristgerechte Kündigung ist das Datum der Absendung (Poststempel). Der Wartungsvertrag kann vorzeitig auch einvernehmlich oder – mit sofortiger Wirkung – einseitig aus wichtigen Gründen beendet werden. Wichtige Gründe sind z. B. Zahlungsverzug (unter Setzung einer zweiwöchigen Nachfrist und Hinweis auf die Rechtsfolgen), Insolvenz des Kunden und dergleichen. Im Falle der vorzeitigen Auflösung des Wartungsvertrages aus Gründen, die vom Kunden zu vertreten sind, steht der Armann Systems GmbH das vertraglich vereinbarte Entgelt, das bis zum regulären Vertragsende angefallen wäre, zu. Bei vorzeitiger Auflösung aus anderen Gründen erfolgt eine aliquote Abrechnung des Wartungsentgelts.</p>

          <h2 className="text-3xl font-bold mt-10 mb-4">4. Zahlung und Zahlungskonditionen</h2>
          <h3 className="text-2xl font-bold mt-6 mb-4">a. Fälligkeit</h3>
          <p>Wenn nicht anders vereinbart, sind die von der Armann Systems GmbH zugestellten Rechnungen sofort ab Rechnungserhalt ohne jeden Abzug und spesenfrei auf das/die von der Armann Systems GmbH angegebene(n) Konto(en) zahlbar.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">b. Zahlung von Teillieferungen</h3>
          <p>Die Armann Systems GmbH ist berechtigt, zur Deckung der bei ihr während der Projektabwicklung zu erwartenden, laufenden Kosten Teillieferungen in Rechnung zu stellen.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">c. Abgeltung von Zusatzleistungen</h3>
          <p>Bei allen über den Auftragsumfang hinausgehenden Dienstleistungen (z. B. Änderungen oder Ergänzungen des Leistungsumfangs, der Inhalte, der Zielsetzung etc.) wird der Arbeitsaufwand zu den am Tage der Leistungserbringung gültigen Stundensätzen an den Kunden verrechnet. Abweichungen im Zeitaufwand, der die Basis für die Berechnung des Vertragspreises bildet, werden nach tatsächlichem Anfall berechnet, sofern sie nicht von der Armann Systems GmbH verursacht worden sind; dies betrifft auch etwaig anfallende Überstunden oder Wochenendzeiten, die auf Wunsch des Kunden zur Erreichung einer vom Kunden gesetzten Deadline (ohne durch die Armann Systems GmbH verursachten Terminverlust) geleistet werden. Äußert der Kunde substantielle Änderungswünsche, die einen grundlegenden Eingriff in die vereinbarte Leistungsstruktur und -abwicklung bedeuten, so ist die Armann Systems GmbH berechtigt, laufende arbeiten bis zur Annahme eines neuen oder Ergänzungsangebotes durch den Kunden einzustellen. Eine in diesem Fall etwaig verursachte Terminverzögerung in der Leistungsabwicklung kann nicht der Armann Systems GmbH angelastet werden.</p>
          <p>Im Falle von oben erwähnten Abweichungen im Leistungsumfang bzw. im entgeltrelevanten Zeitaufwand, verpflichtet sich die Armann Systems GmbH, den Kunden über Änderung und Höhe des von ihm an die Armann Systems GmbH zu leistenden zusätzlichen Entgelts, schriftlich in Kenntnis zu setzen. Alle Leistungen der Armann Systems GmbH, die nicht ausdrücklich durch das vereinbarte Entgelt abgegolten sind, werden gesondert in Rechnung gestellt; dies gilt z. B. für seitens der Kunden trotz Vereinbarung nicht beigestellte Materialien (z. B. Muster, Listen etc.), aber auch für Schulungen, Dokumentationen, Erklärungen und sonstige Nebenleistungen. Sofern nicht anders vereinbart, entsteht der Entgeltanspruch der Armann Systems GmbH für jede einzelne Leistung, sobald diese erbracht wurde. Die Armann Systems GmbH ist berechtigt, zur Deckung ihres Aufwandes Vorschüsse/Abschläge in Rechnung zu stellen.</p>
          <p>Für die Teilnahme an Schulungen, Präsentationen sowie für alle sonstigen vereinbarten Arbeiten der Armann Systems GmbH, die – aus welchen Gründen auch immer – nicht zur Ausführung gelangen, gebührt der Armann Systems GmbH ein angemessenes Honorar, das zumindest den gesamten Personal- und Sachaufwand sowie die Kosten sämtlicher Fremdleistungen deckt. Mit der Bezahlung dieser Vergütung erwirbt der Kunde an diesen Leistungen keinerlei Rechte; nicht aufgeführte Konzepte, Entwürfe, Unterlagen und alle sonstigen Leistungen bleiben Eigentum der Armann Systems GmbH, sind unverzüglich an die Armann Systems GmbH zurückzugeben und dürfen vom Kunden nicht weiter genutzt werden.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">d. Zahlungsverzug</h3>
          <p>Die Einhaltung der vereinbarten Zahlungstermine bildet eine wesentliche Grundlage für die Durchführung der von der Armann Systems GmbH erbrachten Leistungen. Die Nichteinhaltung der vereinbarten Zahlungen bzw. Zahlungsziele berechtigt die Armann Systems GmbH laufende Arbeiten einzustellen, vom Vertrag zurückzutreten oder – nach vollständiger Leistungserbringung – die erbrachten Leistungen oder Applikationen bis zum Zeitpunkt des Zahlungseingangs zurückzubehalten oder wieder offline zu schalten. Alle damit verbundenen Kosten sowie auch der entgangene Gewinn sind vom Kunden zu tragen. Im Falle des Zahlungsverzuges werden Verzugszinsen in Höhe von 19 % p. a. berechnet. Kosten für die Einschaltung eines Inkassobüros oder eines Rechtsanwaltes werden ebenso dem Kunden angelastet.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">e. Aufrechnung von Forderungen</h3>
          <p>Der Kunde ist nicht berechtigt, fällige Forderungen und Ansprüche gegen Forderungen der Armann Systems GmbH aufzurechnen oder Zahlungen wegen nicht vollständiger Gesamtlieferung, Garantie- und/oder Gewährleistungsansprüchen oder Bemängelungen zurückzuhalten, außer diese sind von der Armann Systems GmbH anerkannt oder rechtskräftig festgestellt.</p>

          <h2 className="text-3xl font-bold mt-10 mb-4">5. Nutzung, Rechte, Pflichten</h2>
          <h3 className="text-2xl font-bold mt-6 mb-4">a. Nutzungsrecht</h3>
          <p>Durch den Abschluss eines Vertrages mit der Armann Systems GmbH und durch Bezahlung des vereinbarten Entgelts der Eingang der vollständigen Zahlung bei der Armann Systems GmbH ist Voraussetzung) erwirbt der Kunde das Recht die Hardware/Software und/oder Leistung im vereinbarten Umfang zu nutzen.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">b. Eigentums- und Urheberrechte</h3>
          <p>Alle von der Armann Systems GmbH gelieferten Leistungen, Applikationen oder Materialien sowie die enthaltenen Ideen, Gedanken und Inhalte verbleiben im ausschließlichen und alleinigen Eigentum sowie Urheberrecht der Armann Systems GmbH. Durch die Mitwirkung des Kunden werden keine darüber hinausgehenden Rechte erworben.</p>
          <p>Der Kunde ist nicht berechtigt, Softwareprogramm/Applikationen zu ändern, zu vervielfältigen, Dritten Einsicht zu gewähren, diese zur Verfügung zu stellen oder diese zur Gänze oder teilweise zu veräußern usw. jede Verletzung der Urheberrechte der Armann Systems GmbH zieht Unterlassungs- und Schadenersatzansprüche nach sich, wobei in einem solchen Fall volle Genugtuung zu leisten ist. Der Kunde kann aber gegen gesonderte schriftliche Vereinbarung und Entgelt über den hier vereinbarten Umfang hinausgehende Rechte erwerben.</p>
          <p>Werden die im Zuge eines Workshops von der Armann Systems GmbH entwickelten Ideen und Konzepte nicht für einen Auftrag verwertet, so ist die Armann Systems GmbH berechtigt, diese anderweitig zu nutzen. Die Weitergabe von Workshopunterlagen an Dritte sowie deren Veröffentlichung, Vervielfältigung oder sonstige Verbreitung ist ohne ausdrückliche Zustimmung der Armann Systems GmbH unzulässig.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">c. Recht auf Veröffentlichung und Namensnennung</h3>
          <p>Die Armann Systems GmbH ist berechtigt, für den Kunden erbrachte Leistungen zu Werbe- und PR-Zwecken zu nutzen sowie in den für den Kunden erstellten Applikationen an geeigneter Stelle einen Urheberrechtsvermerk anzubringen, ohne dass dem Kunden dafür ein Entgeltanspruch entsteht.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">d. Aufbewahrungspflicht</h3>
          <p>Die Armann Systems GmbH ist nicht verpflichtet, Leistungsunterlagen, Materialien oder fertige Projekte (Softwareprogramme/Applikationen etc.) des Kunden zu archivieren.</p>

          <h2 className="text-3xl font-bold mt-10 mb-4">6. Haftung, Gewährleistung, Schadensersatz</h2>
          <h3 className="text-2xl font-bold mt-6 mb-4">a. Haftung</h3>
          <p>Die Armann Systems GmbH haftet im Rahmen der gesetzlichen Bestimmungen nur für Vorsatz oder grobe Fahrlässigkeit. Ausgeschlossen ist die Haftung für leichte Fahrlässigkeit, die Haftung für entgangenen Gewinn, Folgeschäden und für reine Vermögensschäden sowie für Schäden dritter Personen. Die Armann Systems GmbH haftet ebenso nicht für Ansprüche, die von Dritten gegen den Kunden erhoben werden, etwa im Falle von wettbewerbsrechtlichen Verletzungen.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">b. Reklamationen, Gewährleistung</h3>
          <p>Der Kunde hat eventuelle Reklamationen und Mängelrügen innerhalb von 14 Tagen ab Abschluss der Leistungen gegenüber der Armann Systems GmbH schriftlich geltend zu machen. Gerechtfertigte und anerkannte Mängelrügen werden von der Armann Systems GmbH binnen angemessener Frist behoben; der Kunde hat alle für die Mängelbehebung erforderlichen Maßnahmen zu dulden und zu ermöglichen. Nach Mängelerhebung ist eine neuerliche Abnahme erforderlich.</p>
          <p>Kosten für Hilfestellung, Fehldiagnose sowie Fehler- und Störungsbeseitigung, die vom Kunden zu vertreten sind, sowie zusätzliche Korrekturen, Änderungen und Ergänzungen werden von der Armann Systems GmbH gegen Berechnung durchgeführt. Dies gilt auch für die Behebung von Mängeln, wenn Änderungen, Ergänzungen oder sonstige Eingriffe vom Kunden selbst oder von dritter Stelle vorgenommen worden sind. Die Armann Systems GmbH übernimmt keine Gewährleistung für Leistungen, die durch den Kunden selbst oder durch Dritte nachträglich getätigt wurden.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">c. Schad- und Klagloshaltung</h3>
          <p>Die Armann Systems GmbH übernimmt keine Haftung für Rechtsverletzungen mit oder durch Mittel, die ihm vom Kunden übergeben worden sind, insbesondere für die unzulässige Verwendung von Marken, Fotografien, Texten und dergleichen. Für den Fall, dass gegen die Armann Systems GmbH wegen solcher Rechtsverletzungen von Dritten Ansprüche geltend gemacht werden sollten, ist die Armann Systems GmbH einerseits ausdrücklich berechtigt, den Dritten an den Kunden zu verweisen, und andererseits ist der Kunde verpflichtet, die Armann Systems GmbH für derartige Ansprüche vollkommen schad- und klaglos zu stellen.</p>

          <h2 className="text-3xl font-bold mt-10 mb-4">7. Sonstige Bestimmungen</h2>
          <h3 className="text-2xl font-bold mt-6 mb-4">a. Änderungen von Kundendaten</h3>
          <p>Namens- und/oder Adressänderungen hat der Kunde der Armann Systems GmbH unverzüglich bekanntzugeben. Die Armann Systems GmbH ist berechtigt, Erklärungen und Schriftstücke an die ihr zuletzt bekanntgegebene Adresse des Kunden zu übermitteln.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">b. Schriftlichkeit</h3>
          <p>Änderungen und Ergänzungen zu abgeschlossenen Verträgen bedürfen der Schriftform. Fax und E-Mail genügen der Schriftform. Auch für das Abgehen vom Erfordernis der Schriftform ist Schriftlichkeit erforderlich.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">c. Unwirksamkeit von Vertragsbestimmungen</h3>
          <p>Sollten einzelne Vertragsbestimmungen unwirksam sein oder unwirksam werden, so wird hierdurch der übrige Inhalt des Vertrages nicht berührt. Die unwirksame Bestimmung ist durch eine wirksame, die ihr dem Sinn und Zweck am nächsten kommt zu ersetzen.</p>

          <h3 className="text-2xl font-bold mt-6 mb-4">d. Gerichtsstand, anzuwendendes Recht</h3>
          <p>Gerichtsstand und Erfüllungsort ist Regensburg. Für alle Streitigkeiten aus abgeschlossenen Verträgen und alle (auch zukünftige) Rechtsgeschäfte aus der Geschäftsverbindung vereinbaren die Armann Systems GmbH und der Kunde die ausschließliche Zuständigkeit des sachlich und räumlich in Betracht kommenden Gerichts der Stadt Regensburg. Jeder andere Gerichtsstand ist ausdrücklich ausgeschlossen. Dieser Gerichtsstand gilt auch für die Frage des rechtswirksamen Zustandekommens des Vertragsverhältnisses sowie aller seiner Vor- und Nachwirkungen.</p>
          <p>Für alle Rechtsverhältnisse zwischen der Armann Systems GmbH und dem Kunden gilt ausschließlich deutsches Recht.</p>
          <p>Stand: 06.08.2024</p>
        </div>
      </motion.div>
      <Footer />
    </section>
  );
};

export default AGB;
