import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import axiosInstance from '../axiosConfig';

const ConversationTokenUsage = () => {
  const [tokenUsage, setTokenUsage] = useState([]);

  useEffect(() => {
    fetchTokenUsage();
  }, []);

  const fetchTokenUsage = async () => {
    try {
      const response = await axiosInstance.get('/admin/token_usage');
      setTokenUsage(response.data);
    } catch (error) {
      console.error('Error fetching token usage:', error);
    }
  };

  const calculatePrice = (tokens, rate) => (tokens / 1000000) * rate;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Token Usage (Prices in USD)
      </Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Input Tokens</TableCell>
              <TableCell>Output Tokens</TableCell>
              <TableCell>Total Tokens</TableCell>
              <TableCell>Durchschnittliche Tokens pro Gespräch</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tokenUsage.map((usage) => {
              const totalInputTokens = Number(usage.total_input_tokens) || 0;
              const totalOutputTokens = Number(usage.total_output_tokens) || 0;
              const totalTokens = Number(usage.total_tokens) || 0;
              const averageTokensPerConversation = Number(usage.average_tokens_per_conversation) || 0;
              const totalConversations = Number(usage.total_conversations) || 1;

              const inputPrice = usage.inputPrice;
              const outputPrice = usage.outputPrice;
              const averageInputTokens = (totalInputTokens / totalConversations).toFixed(2);
              const averageOutputTokens = (totalOutputTokens / totalConversations).toFixed(2);
              const averageInputPrice = (calculatePrice(totalInputTokens, 0.15) / totalConversations).toFixed(2);
              const averageOutputPrice = (calculatePrice(totalOutputTokens, 0.6) / totalConversations).toFixed(2);

              return (
                <TableRow key={usage.customer_id}>
                  <TableCell>{usage.customer_name}</TableCell>
                  <TableCell>
                    <Tooltip title={`Input Price: $${inputPrice}`} placement="top" arrow>
                      <span>
                        {totalInputTokens}
                        <div style={{ fontSize: '0.75em', color: '#888' }}>
                          <hr style={{ margin: '2px 0' }} />
                          ${inputPrice}
                        </div>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={`Output Price: $${outputPrice}`} placement="top" arrow>
                      <span>
                        {totalOutputTokens}
                        <div style={{ fontSize: '0.75em', color: '#888' }}>
                          <hr style={{ margin: '2px 0' }} />
                          ${outputPrice}
                        </div>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={`Input Price: $${inputPrice} | Output Price: $${outputPrice}`} placement="top" arrow>
                      <span>
                        {totalTokens}
                        <div style={{ fontSize: '0.75em', color: '#888' }}>
                          <hr style={{ margin: '2px 0' }} />
                          ${inputPrice} + ${outputPrice}
                        </div>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={`Average Input Price: $${averageInputPrice} | Average Output Price: $${averageOutputPrice}`}
                      placement="top"
                      arrow
                    >
                      <span>
                        {averageInputTokens} (Input) + {averageOutputTokens} (Output)
                        <div style={{ fontSize: '0.75em', color: '#888' }}>
                          <hr style={{ margin: '2px 0' }} />
                          ${averageInputPrice} + ${averageOutputPrice}
                        </div>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default ConversationTokenUsage;
