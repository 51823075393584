import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: white;
  border-radius: 10px;
  text-align: center;
`;

const ModalHeader = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const ModalBody = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const SessionExpirationModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/login');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Session Expiration"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          maxWidth: '500px',
          margin: 'auto',
          padding: '0',
          border: 'none',
          borderRadius: '10px',
          overflow: 'hidden',
        },
      }}
    >
      <ModalContent>
        <ModalHeader>Sitzung abgelaufen</ModalHeader>
        <ModalBody>Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.</ModalBody>
        <ModalButton onClick={handleButtonClick}>Okay</ModalButton>
      </ModalContent>
    </Modal>
  );
};

export default SessionExpirationModal;
