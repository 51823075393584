import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { Delete as DeleteIcon, Upload as UploadIcon, Info as InfoIcon, Warning as WarningIcon } from '@mui/icons-material';
import axiosInstance from '../../axiosConfig';
import AITutorial from './AITutorial';
import CreateStatementDialog from '../../components/CreateStatementDialog';

const AgentSettings = ({ agentId, isCreating, onCreated }) => {
  const [agentConfig, setAgentConfig] = useState({
    Username: '',
    AuthUsername: '',
    Password: '',
    Domain: '',
    SystemPrompt: '',
    DTMFMapping: [
      { Action: '', Beschreibung: '', Priority: '' },
    ],
    CallDelay: '',
    IgnoreCallerForSpeech: '0',
    BusinessHours: 'immer',
    MaxCallDuration: '',
    Voice: 'Laura',
    TransferTime: 10
  });

  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [statementDialogOpen, setStatementDialogOpen] = useState(false);
  const [audioWarning, setAudioWarning] = useState(false);
  const [businessHoursAlways, setBusinessHoursAlways] = useState(true);
  const [businessHoursFrom, setBusinessHoursFrom] = useState('08:00');
  const [businessHoursTo, setBusinessHoursTo] = useState('17:00');

  useEffect(() => {
    if (agentId && !isCreating) {
      const fetchAgentConfig = async () => {
        try {
          const response = await axiosInstance.get(`/configurations/${agentId}`);
          console.log('Konfiguration geladen:', response.data);
          const configData = response.data;

          const updatedDTMFMapping = configData.DTMFMapping.map((item) => {
            if (item.Action.startsWith('Transfer:')) {
              const nummer = item.Action.split('Transfer:')[1];
              return { ...item, Action: 'Transfer', Nummer: nummer };
            }
            return item;
          });

          setAgentConfig({
            ...configData,
            DTMFMapping: updatedDTMFMapping,
            CallDelay: configData.CallDelay || '',
            IgnoreCallerForSpeech: configData.IgnoreCallerForSpeech || '0',
            BusinessHours: configData.BusinessHours || 'immer',
            MaxCallDuration: configData.MaxCallDuration !== null && configData.MaxCallDuration !== undefined ? configData.MaxCallDuration : '',
            Voice: configData.Voice || 'Laura',
          });

          if (configData.BusinessHours !== 'immer') {
            const [from, to] = configData.BusinessHours.split('-');
            setBusinessHoursFrom(from);
            setBusinessHoursTo(to);
            setBusinessHoursAlways(false);
          }

          if (configData.AudioFile) {
            const binaryString = atob(configData.AudioFile);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: 'audio/wav' });
            const url = URL.createObjectURL(blob);
            setAudioUrl(url);
            setAudioWarning(false);
          } else {
            setAudioWarning(true);
          }      

        } catch (error) {
          console.error('Fehler beim Laden der Konfiguration:', error);
        }
      };

      fetchAgentConfig();
    } else {
      setAgentConfig({
        Username: '',
        AuthUsername: '',
        Password: '',
        Domain: '',
        SystemPrompt: '',
        DTMFMapping: [
          { Action: '', Beschreibung: '', Priority: '' },
        ],
        CallDelay: '',
        IgnoreCallerForSpeech: '0',
        BusinessHours: 'immer',
        MaxCallDuration: '',
        Voice: 'Laura',
      });
      setAudioUrl(null);
      setAudioWarning(true);
    }
  }, [agentId, isCreating]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAgentConfig((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleActionChange = (index, field, value) => {
    const updatedActions = agentConfig.DTMFMapping.map((action, i) =>
      i === index ? { ...action, [field]: value } : action
    );
    setAgentConfig((prevState) => ({
      ...prevState,
      DTMFMapping: updatedActions,
    }));
  };

  const handleAddAction = () => {
    setAgentConfig((prevState) => ({
      ...prevState,
      DTMFMapping: [...prevState.DTMFMapping, { Action: '', Beschreibung: '', Priority: '' }],
    }));
  };

  const handleDeleteAction = (index) => {
    const updatedActions = agentConfig.DTMFMapping.filter((_, i) => i !== index);
    setAgentConfig((prevState) => ({
      ...prevState,
      DTMFMapping: updatedActions,
    }));
  };

  const handleAudioUpload = (event) => {
    const file = event.target.files[0];
    setAudioFile(file);

    if (file) {
      const url = URL.createObjectURL(file);
      setAudioUrl(url);
      setAudioWarning(false);
    }
  };

  const handleAudioDelete = () => {
    setAudioFile(null);
    setAudioUrl(null);
    setAudioWarning(true);
  };

  const handleCreateStatement = (url) => {
    setAudioUrl(url);
    setStatementDialogOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const updatedDTMFMapping = agentConfig.DTMFMapping.map((item) => {
        if (item.Action === 'Transfer') {
          return { ...item, Action: `Transfer:${item.Nummer}` };
        }
        return item;
      });

      const businessHoursValue = businessHoursAlways ? 'immer' : `${businessHoursFrom}-${businessHoursTo}`;

      const formData = new FormData();
      formData.append('Username', agentConfig.Username);
      formData.append('AuthUsername', agentConfig.AuthUsername);
      formData.append('Password', agentConfig.Password);
      formData.append('Domain', agentConfig.Domain);
      formData.append('SystemPrompt', agentConfig.SystemPrompt);
      formData.append('DTMFMapping', JSON.stringify(updatedDTMFMapping));
      formData.append('CallDelay', agentConfig.CallDelay);
      formData.append('IgnoreCallerForSpeech', agentConfig.IgnoreCallerForSpeech);
      formData.append('BusinessHours', businessHoursValue);
      formData.append('MaxCallDuration', agentConfig.MaxCallDuration);
      formData.append('Voice', agentConfig.Voice);
      formData.append('TransferTime', agentConfig.TransferTime);

      if (audioFile) {
        formData.append('AudioFile', audioFile);
      }

      if (isCreating) {
        await axiosInstance.post('/configurations', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Agent erfolgreich erstellt');
        onCreated();
      } else {
        await axiosInstance.put(`/configurations/${agentId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Agent erfolgreich aktualisiert');
      }
    } catch (error) {
      console.error('Fehler beim Speichern der Konfiguration:', error);
      alert('Fehler beim Speichern');
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={2} alignItems="flex-start">
        {audioWarning && (
          <Grid item xs={12}>
            <Box bgcolor="error.main" color="white" p={2} borderRadius={2} display="flex" alignItems="center">
              <WarningIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="body1">Achtung: Keine Ansage für diesen Agenten konfiguriert!</Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="Username"
            label="Benutzername"
            name="Username"
            value={agentConfig.Username}
            onChange={handleChange}
            autoComplete="new-username"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Der Benutzername, der für diesen Agenten verwendet wird.">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="AuthUsername"
            label="Auth-Benutzername"
            name="AuthUsername"
            value={agentConfig.AuthUsername}
            onChange={handleChange}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Der Authentifizierungs-Benutzername, der zur Anmeldung des Agenten verwendet wird.">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="Password"
            label="Passwort"
            name="Password"
            type="password"
            value={agentConfig.Password}
            onChange={handleChange}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Das Passwort für die Authentifizierung des Agenten.">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="Domain"
            label="Domain"
            name="Domain"
            value={agentConfig.Domain}
            onChange={handleChange}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Die Domain, unter der der Agent konfiguriert ist.">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="SystemPrompt"
            label="System-Prompt"
            name="SystemPrompt"
            value={agentConfig.SystemPrompt}
            onChange={handleChange}
            autoComplete="off"
            multiline
            rows={4}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Der System-Prompt definiert das Verhalten des Agenten bei der Gesprächsführung.">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Ansage
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4} sm={2}>
              {audioUrl && (
                <Box display="flex" alignItems="center">
                  <audio controls src={audioUrl} style={{ width: '100%' }}>
                    Your browser does not support the audio element.
                  </audio>
                  <IconButton color="error" onClick={handleAudioDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Grid>
            <Grid item xs={8} sm={4}>
              <Button
                variant="outlined"
                component="label"
                startIcon={<UploadIcon />}
                fullWidth
              >
                Ansage hochladen
                <input
                  type="file"
                  hidden
                  accept="audio/wav"
                  onChange={handleAudioUpload}
                />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setStatementDialogOpen(true)}
                sx={{ mt: 0 }}
              >
                Neue Aussage generieren
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
        <Typography variant="h6" gutterBottom>
            Einstellungen
          </Typography>
          <TextField
            fullWidth
            id="Delay"
            label="Annahme verzögern (Sekunden)"
            name="CallDelay"
            value={agentConfig.CallDelay}
            onChange={handleChange}
            placeholder="z.B. 1-2"
            helperText="Von-Bis oder ein Wert"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Verzögert die Annahme eines Anrufs um die angegebene Zeit in Sekunden.">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth sx={{ mt: 4.9 }}>
            <InputLabel>Anrufer bei Sprachausgabe ignorieren</InputLabel>
            <Select
              id="IgnoreCallerForSpeech"
              name="IgnoreCallerForSpeech"
              label="Anrufer bei Sprachausgabe ignorieren"
              value={agentConfig.IgnoreCallerForSpeech}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Wenn aktiviert, wird der Anrufer während der Sprachausgabe ignoriert.">
                      <InfoIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="1">Ja</MenuItem>
              <MenuItem value="0">Nein</MenuItem>
            </Select>
            <FormHelperText>Wählen Sie Ja oder Nein</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Geschäftszeiten</InputLabel>
            <Select
              id="BusinessHours"
              name="BusinessHours"
              label="Geschäftszeiten"
              value={businessHoursAlways ? 'immer' : 'custom'}
              onChange={(e) => setBusinessHoursAlways(e.target.value === 'immer')}
              displayEmpty
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Definiert die Zeiten, in denen der Agent aktiv ist.">
                      <InfoIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="immer">Immer</MenuItem>
              <MenuItem value="custom">Benutzerdefiniert</MenuItem>
            </Select>
            {!businessHoursAlways && (
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Von"
                    type="time"
                    value={businessHoursFrom}
                    onChange={(e) => setBusinessHoursFrom(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 Minuten Schritte
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Bis"
                    type="time"
                    value={businessHoursTo}
                    onChange={(e) => setBusinessHoursTo(e.target.value)}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 Minuten Schritte
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <FormHelperText>Wählen Sie die Geschäftszeiten</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="MaxDuration"
            label="Maximale Gesprächsdauer (Sekunden)"
            name="MaxCallDuration"
            value={agentConfig.MaxCallDuration !== '' ? agentConfig.MaxCallDuration : '0'}
            onChange={handleChange}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Legt die maximale Dauer eines Gesprächs in Sekunden fest.">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Stimme</InputLabel>
            <Select
              id="Voice"
              name="Voice"
              label="Stimme"
              value={agentConfig.Voice}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Wählen Sie die Stimme des Agenten.">
                      <InfoIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="Laura">Laura</MenuItem>
            </Select>
            <FormHelperText>Wählen Sie die Stimme</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="TransferTime"
            label="Wie lange klingeln bei einer Weiterleitung?"
            name="TransferTime"
            value={agentConfig.TransferTime}
            onChange={handleChange}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Legt die Zeit fest, wie lange ein Anruf bei dem Ansprechpartner klingelt, bevor er als 'nicht erreichbar' gilt.">
                    <InfoIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Aktionen
          </Typography>
          {agentConfig.DTMFMapping.map((actionItem, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={3}>
                <Select
                  fullWidth
                  value={actionItem.Action.startsWith('Transfer') ? 'Transfer' : actionItem.Action}
                  onChange={(e) => handleActionChange(index, 'Action', e.target.value)}
                  displayEmpty
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Wählen Sie die Aktion, die der Agent bei der Auswahl durchführt.">
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value=""><em>Keine</em></MenuItem>
                  <MenuItem value="EndCall">Anruf beenden</MenuItem>
                  <MenuItem value="Transfer">Weiterleiten</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  value={actionItem.Beschreibung || ''}
                  onChange={(e) => handleActionChange(index, 'Beschreibung', e.target.value)}
                  autoComplete="off"
                  label="Beschreibung"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Beschreiben Sie die Aktion des Agenten.">
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  value={actionItem.Priority || ''}
                  onChange={(e) => handleActionChange(index, 'Priority', e.target.value)}
                  autoComplete="off"
                  label="Priorität"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Legen Sie die Priorität der Aktion fest.">
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {actionItem.Action === 'Transfer' && (
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    value={actionItem.Nummer || ''}
                    onChange={(e) => handleActionChange(index, 'Nummer', e.target.value)}
                    autoComplete="off"
                    label="Nummer"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Geben Sie die Nummer ein, an die weitergeleitet werden soll.">
                            <InfoIcon />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={1} display="flex" alignItems="center">
                <IconButton onClick={() => handleDeleteAction(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleAddAction}
            sx={{ mt: 2 }}
          >
            Neue Aktion hinzufügen
          </Button>
        </Grid>
        
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {isCreating ? 'Agent erstellen' : 'Agent speichern'}
          </Button>
        </Grid>
      </Grid>
      <AITutorial open={tutorialOpen} onClose={() => setTutorialOpen(false)} />
      <CreateStatementDialog
        open={statementDialogOpen}
        onClose={() => setStatementDialogOpen(false)}
        onCreate={handleCreateStatement}
      />
    </Box>
  );
};

export default AgentSettings;
