import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const SuccessStoriesContainer = styled.section`
  padding: 80px 20px;
  background: #f4f4f9;
  text-align: center;
  font-family: 'Helvetica Neue', Arial, sans-serif;
`;

const Title = styled.h2`
  font-size: 2.8rem;
  margin-bottom: 60px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const ProjectsSection = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 800px;
  transform: rotate(-1deg);

  &:nth-child(even) {
    transform: rotate(1deg);
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.15);
  }
`;

const ProjectImageContainer = styled.div`
  width: 100%;
  position: relative;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-bottom: 5px solid #007bff;
`;

const ProjectContent = styled.div`
  padding: 20px;
  position: relative;
`;

const ProjectTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ProjectText = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
  line-height: 1.6;
  color: #555;

  strong {
    color: #333;
  }
`;

const ProjectAuthor = styled.p`
  font-size: 0.9rem;
  margin-top: 20px;
  font-style: italic;
  color: #777;
`;

const ProjectChart = styled.div`
  margin-top: 20px;
  background: #f4f4f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
`;

const successStories = [
  {
    client: 'Beispiel GmbH',
    title: 'Revolutionierung des Kundenservices',
    challenge: 'Ineffizienter Kundenservice',
    solution: 'TommyAI hat den Kundenservice revolutioniert',
    result: 'Die Effizienz im Kundenservice wurde drastisch verbessert',
    author: 'Max Müller, CEO von Beispiel GmbH',
    image: 'path/to/image1.jpg',  // Replace with the actual image path
  },
  {
    client: 'Beispiel AG',
    title: 'Automatisierung von Routineaufgaben',
    challenge: 'Zu viele Routineaufgaben',
    solution: 'Automatisierung durch TommyAI',
    result: 'Fokus auf wichtigere Aufgaben durch das Team',
    author: 'Anna Schmidt, Kundenservice-Leiterin',
    image: 'path/to/image2.jpg',  // Replace with the actual image path
  },
  {
    client: 'Beispiel IT Solutions',
    title: 'Effizienzsteigerung im IT-Management',
    challenge: 'Komplexe IT-Anfragen',
    solution: 'Einfache Bedienung und effektive Lösungen durch TommyAI',
    result: 'Steigerung der Effizienz im IT-Management',
    author: 'John Doe, IT-Manager',
    image: 'path/to/image3.jpg',  // Replace with the actual image path
  },
  // Add more success stories as needed
];

const SuccessStories = () => {
  return (
    <SuccessStoriesContainer>
      <Title>Erfolgsgeschichten</Title>
      <ProjectsSection>
        {successStories.map((story, index) => (
          <ProjectWrapper key={index}>
            <ProjectImageContainer>
              <ProjectImage src={story.image} alt={story.client} />
            </ProjectImageContainer>
            <ProjectContent
              as={motion.div}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 * index }}
            >
              <ProjectTitle>{story.title}</ProjectTitle>
              <ProjectText><strong>Herausforderung:</strong> {story.challenge}</ProjectText>
              <ProjectText><strong>Lösung:</strong> {story.solution}</ProjectText>
              <ProjectText><strong>Ergebnis:</strong> {story.result}</ProjectText>
              <ProjectAuthor>{story.author}</ProjectAuthor>
              <ProjectChart>
                {/* Platzhalter für das Diagramm */}
                <p>Diagramm zur Effizienzsteigerung</p>
              </ProjectChart>
            </ProjectContent>
          </ProjectWrapper>
        ))}
      </ProjectsSection>
    </SuccessStoriesContainer>
  );
};

export default SuccessStories;
