import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from './supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const checkSession = async () => {
    try {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) throw error;
      if (session) {
        setUser(session.user);
        setError(null); // Clear error if session is valid
      } else {
        setUser(null);
        setError('Session expired. Please log in again.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing session:', error);
      setUser(null);
      setLoading(false);
      setError('Session expired. Please log in again.');
    }
  };

  useEffect(() => {
    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        setUser(session.user);
        setError(null); // Clear error if session is valid
      } else {
        setUser(null);
        setError('Session expired. Please log in again.');
      }
    });

    const intervalId = setInterval(checkSession, 5 * 60 * 1000); // Check every 5 minutes

    return () => {
      authListener.subscription.unsubscribe();
      clearInterval(intervalId);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, error, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
