import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bxLogIn from '@iconify-icons/bx/bx-log-in';
import { useAuth } from '../../AuthContext';
import { supabase } from '../../supabaseClient';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
const LoginContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
`;

const BackgroundGradient = styled(motion.div)`
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(173, 216, 230, 0.5) 25%, rgba(255, 255, 255, 0.5) 75%);
  z-index: -1;
`;

const GradientCircle = styled(motion.div)`
  position: absolute;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(173, 216, 230, 0.3), rgba(135, 206, 235, 0.1));
  border-radius: 50%;
  opacity: 0.6;
`;

const LoginForm = styled(motion.div)`
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const LoginTitle = styled(motion.h2)`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const LoginInput = styled.input`
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 10px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #61dafb;
    outline: none;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: blue;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #21a1f1;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 1rem;
  }
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleLogin = async () => {
    try {
      const { data: { session, user }, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) {
        console.error('Error logging in:', error.message);
        return;
      }
      if (session) {
        localStorage.setItem('token', session.access_token);
        localStorage.setItem('refresh_token', session.refresh_token);
        setUser({ ...user, accessToken: session.access_token, refreshToken: session.refresh_token });
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    
    <LoginContainer>
      <Helmet>
    <title>blub.ai - Login</title>
    <meta name="description" content="Loggen Sie sich in Ihren blub.ai-Account ein, um auf Ihr Dashboard und alle Funktionen des virtuellen Telefonassistenten zuzugreifen." />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="canonical" href="https://www.blub.ai/login" />
</Helmet>

      <BackgroundGradient
        initial={{ backgroundPosition: '0% 0%' }}
        animate={{ backgroundPosition: ['0% 0%', '100% 100%'] }}
        transition={{ duration: 15, repeat: Infinity, ease: 'linear' }}
        style={{ backgroundSize: '200% 200%' }}
      />
      <GradientCircle
        className="absolute -bottom-10 -left-10"
        initial={{ scale: 1 }}
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 10, repeat: Infinity, ease: 'easeInOut' }}
      />
      <GradientCircle
        className="absolute -top-10 -right-10"
        initial={{ scale: 1 }}
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 10, repeat: Infinity, ease: 'easeInOut', delay: 5 }}
      />
      <LoginForm
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <LoginTitle>Login</LoginTitle>
        <LoginInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <LoginInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <LoginButton onClick={handleLogin}>
          <Icon icon={bxLogIn} className="mr-2" /> Login
        </LoginButton>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
