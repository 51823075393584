import React, { useState, useEffect, memo, useCallback } from "react";
import {
  Container,
  Typography,
  Tabs,
  Tab,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Done,
  Visibility,
  Info,
  Sms,
  Email,
  ErrorOutline,
  CheckCircleOutline,
  Phone,
  ContentCopy,
  HelpOutline,
  MoreVert,
  Search,
  Comment,
} from "@mui/icons-material";
import axiosInstance from "../axiosConfig";
import CustomAudioPlayer from "./CustomAudioPlayer";
import { io } from "socket.io-client";
import "react-h5-audio-player/lib/styles.css";
import ConversationActions from "./ConversationActions";
import SmsDetailsForm from "./SmsDetailsForm";

const convertToLocalTime = (timestamp) => {
  const date = new Date(timestamp);
  const offset = date.getTimezoneOffset(); // Minutenverschiebung von UTC
  const localDate = new Date(date.getTime() - (offset * 60 * 1000)); // Umrechnung auf lokale Zeit
  return localDate.toLocaleString(); // Ausgabe im lokalen Format
};

const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const padToTwoDigits = (num) => String(num).padStart(2, "0");

  return `${padToTwoDigits(date.getDate())}.${padToTwoDigits(
    date.getMonth() + 1
  )}.${date.getFullYear()} ${padToTwoDigits(date.getHours())}:${padToTwoDigits(
    date.getMinutes()
  )}:${padToTwoDigits(date.getSeconds())}`;
};

const MemoizedAudioPlayer = memo(CustomAudioPlayer);

const renderDynamicTheme = (theme) => {
  try {
    const themeObject = JSON.parse(theme);
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {Object.keys(themeObject).map((key) => (
          <Typography key={key} variant="body2">
            <strong>{key}:</strong> {themeObject[key]}
          </Typography>
        ))}
      </Box>
    );
  } catch (e) {
    console.error("Invalid JSON in theme field:", theme);
    return (
      <Typography variant="body2">
        Ungültige Daten im Theme-Feld
      </Typography>
    );
  }
};

const renderResultItem = (result) => {
  const themeContent = renderDynamicTheme(result.theme);

  return (
    <ListItem
      button
      onClick={() =>
        (window.location.href = `/conversation/${result.conversation_id}`)
      }
      key={result.conversation_id}
      sx={{ flexDirection: "column", alignItems: "flex-start", padding: 2 }}
    >
      <ListItemIcon>
        <Comment color="action" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {`Anliegen: ${result?.concise_summary}`}
          </Typography>
        }
        secondary={
          <>
            {themeContent}
            <Typography variant="body2">
            <strong>Zusammenfassung:</strong> {result?.summary}
            </Typography>
            <Typography variant="body2">
              <strong>Telefon:</strong> {result?.phone_number}
            </Typography>
            <Typography variant="body2">
              <strong>Datum:</strong> {convertToLocalTime(result?.created_at)}
            </Typography>
            <Typography variant="body2">
              <strong>Abgehakt von:</strong> {result?.marked_by_user_name}
            </Typography>
            <Tooltip title={`ID: ${result?.conversation_id}`}>
              <Typography
                variant="caption"
                sx={{ mt: 1, display: "block", color: "gray" }}
              >
                Gesprächs-ID anzeigen
              </Typography>
            </Tooltip>
          </>
        }
      />
    </ListItem>
  );
};

const ActionButtons = ({ conversation, handleOpenDialog }) => (
  <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
    <Tooltip title="Anzeigen">
      <IconButton
        color="primary"
        onClick={() =>
          (window.location.href = `/conversation/${conversation.conversation_id}`)
        }
      >
        <Visibility />
      </IconButton>
    </Tooltip>
    {!conversation.is_done && (
      <Tooltip title="Erledigt">
        <IconButton
          color="secondary"
          onClick={() => handleOpenDialog(conversation)}
        >
          <Done />
        </IconButton>
      </Tooltip>
    )}
    <Tooltip title="Anrufen">
      <IconButton
        color="primary"
        onClick={() => (window.location.href = `tel:${conversation.phone_number}`)}
      >
        <Phone />
      </IconButton>
    </Tooltip>
    <Tooltip title="Mehr">
      <IconButton>
        <MoreVert />
      </IconButton>
    </Tooltip>
  </Box>
);

const PhoneNumberCell = ({ phoneNumber, handleCopyToClipboard }) => (
  <TableCell sx={{ textAlign: "left", padding: "4px", fontSize: "13px", fontWeight: "300" }}>
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
      <span>{phoneNumber}</span>
      <Tooltip title="Kopieren">
        <IconButton onClick={() => handleCopyToClipboard(phoneNumber)} size="small" sx={{ ml: 1 }}>
          <ContentCopy fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  </TableCell>
);

const AttributeCell = ({ attribute, dynamicAttributes, originalAttributes }) => {
  const value = dynamicAttributes ? dynamicAttributes[attribute.attribute_name] : "";
  const originalValue = originalAttributes && originalAttributes[attribute.attribute_name];

  return (
    <TableCell sx={{ textAlign: "left", padding: "12px", fontSize: "13px", fontWeight: "300" }}>
      {value}
      {originalValue && originalValue !== value && (
        <Tooltip title={`Original: ${originalValue}`}>
          <IconButton size="small" sx={{ ml: 1 }}>
            <Info fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
};

const SummaryCell = ({ conversation, handleSmsIconClick }) => (
  <TableCell sx={{ textAlign: "left", padding: "24px", fontSize: "13px", fontWeight: "300" }}>
    {conversation.summary?.length > 300 ? `${conversation.summary?.substring(0, 300)}...` : conversation.summary}

    {conversation.is_done === 1 && (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
        {conversation.answer_type === "SMS" && (
          <IconButton color="primary" onClick={() => handleSmsIconClick(conversation.conversation_id)}>
            <Sms />
          </IconButton>
        )}
        {conversation.answer_type === "EMAIL" && <Email color="primary" />}
        {conversation.answer_type === "NONE" ? (
          <Tooltip title="Keine Antwort gesendet">
            <HelpOutline color="warning" />
          </Tooltip>
        ) : conversation.answer_success === null ? (
          <Tooltip title="In Bearbeitung">
            <ErrorOutline color="disabled" />
          </Tooltip>
        ) : conversation.answer_success ? (
          <Tooltip title="Erfolgreich">
            <CheckCircleOutline color="success" />
          </Tooltip>
        ) : (
          <Tooltip title="Fehlgeschlagen">
            <ErrorOutline color="error" />
          </Tooltip>
        )}
      </Box>
    )}
  </TableCell>
);

const ConversationRow = ({
  conversation,
  attributes,
  handleOpenDialog,
  handleCopyToClipboard,
  handleSmsIconClick,
}) => (
  <TableRow
    key={conversation.conversation_id}
    hover
    sx={{
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    }}
  >
    <TableCell sx={{ textAlign: "center", padding: "4px", fontSize: "15px", fontWeight: "300" }}>
      <ActionButtons conversation={conversation} handleOpenDialog={handleOpenDialog} />
    </TableCell>
    <PhoneNumberCell
      phoneNumber={conversation.dynamic_attributes.phone_number}
      handleCopyToClipboard={handleCopyToClipboard}
    />
    {attributes.map((attribute) => (
      <AttributeCell
        key={attribute.attribute_name}
        attribute={attribute}
        dynamicAttributes={conversation.dynamic_attributes}
        originalAttributes={conversation.original_attributes}
      />
    ))}
    <TableCell sx={{ textAlign: "center", padding: "4px", fontSize: "13px", fontWeight: "300" }}>
      {formatDateString(conversation.created_at)}
    </TableCell>
    <SummaryCell conversation={conversation} handleSmsIconClick={handleSmsIconClick} />
    <TableCell sx={{ textAlign: "center", padding: "4px" }}>
      {conversation.has_audio ? (
        <MemoizedAudioPlayer conversationId={conversation.conversation_id} />
      ) : (
        <Typography variant="caption" color="textSecondary">
          No audio available
        </Typography>
      )}
    </TableCell>
    <TableCell sx={{ textAlign: "center", padding: "4px", fontSize: "13px", fontWeight: "300" }}>
      <Typography variant="caption" color="textSecondary">
        {conversation.marked_by_user_name}
      </Typography>
    </TableCell>
  </TableRow>
);

const ConversationList = () => {
  const [conversations, setConversations] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState("Active");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [openSmsDialog, setOpenSmsDialog] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await axiosInstance.get("/attributes");
        setAttributes(response.data.filter((attribute) => attribute.is_active));
      } catch (error) {
        console.error("There was an error fetching the attributes!", error);
      }
    };

    const fetchConversations = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("/conversations", {
          params: {
            page,
            per_page: perPage,
            status: activeTab.toLowerCase(),
            category: selectedCategory,
          },
        });
        setConversations(response.data.conversations);
        setTotalPages(Math.ceil(response.data.total / perPage));
      } catch (error) {
        console.error("There was an error fetching the conversations!", error);
      }
      setLoading(false);
    };

    fetchAttributes();
    fetchConversations();

    const socket = io("https://blub.ai", {
      path: "/socket.io",
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    socket.on("update", (data) => {
      if (data.status === "done") {
        setConversations((prevConversations) =>
          prevConversations.filter(
            (conversation) => conversation.conversation_id !== data.conversation_id
          )
        );
        if (activeTab === "Done") {
          setConversations((prevConversations) => [...prevConversations, data]);
        }
      }
    });

    socket.on("update_conversation", (data) => {
      setConversations((prevConversations) => {
        const conversationExists = prevConversations.find(
          (conversation) => conversation.conversation_id === data.conversation_id
        );
        if (conversationExists) {
          return prevConversations.map((conversation) =>
            conversation.conversation_id === data.conversation_id
              ? { ...conversation, ...data }
              : conversation
          );
        } else {
          return [data, ...prevConversations];
        }
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [page, perPage, activeTab, selectedCategory]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/categories");
        setCategories(response.data.categories);
      } catch (error) {
        console.error("There was an error fetching the categories!", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchQuery.trim() === "") {
        setSearchResults([]);
        return;
      }

      setSearching(true);

      try {
        const response = await axiosInstance.get("/search/conversations", {
          params: { q: searchQuery },
        });
        setSearchResults(response.data);
      } catch (error) {
        console.error("There was an error fetching the search results!", error);
        setSearchResults([]);
      }

      setSearching(false);
    };

    const delayDebounceFn = setTimeout(() => {
      fetchSearchResults();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleMarkAsDone = useCallback(
    (conversation_id) => {
      setConversations(
        conversations.map((conversation) =>
          conversation.conversation_id === conversation_id
            ? { ...conversation, is_done: true }
            : conversation
        )
      );
    },
    [conversations]
  );

  const handleOpenDialog = useCallback((conversation) => {
    setSelectedConversation(conversation);
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedConversation(null);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSmsIconClick = (conversationId) => {
    setSelectedConversationId(conversationId);
    setOpenSmsDialog(true);
  };

  const handleCloseSmsDialog = () => {
    setOpenSmsDialog(false);
    setSelectedConversationId(null);
  };

  const handleCopyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Copied to clipboard successfully!");
      },
      (err) => {
        console.error("Failed to copy to clipboard: ", err);
      }
    );
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Container maxWidth="xl" sx={{ padding: isMobile ? 2 : 4 }}>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        component="h1"
        align="center"
        gutterBottom
        sx={{ marginBottom: 4, fontWeight: "bold" }}
      >
        Konversationen
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTab-root": {
              minHeight: isMobile ? "28px" : "36px",
              padding: "8px 24px",
              fontSize: isMobile ? "0.8rem" : "1rem",
            },
            "& .Mui-selected": {
              backgroundColor: "#e0f7fa",
              borderRadius: "8px",
            },
          }}
        >
          <Tab label="Neu" value="Active" />
          <Tab label="Abgeschlossen" value="Done" />
        </Tabs>
        <FormControl sx={{ minWidth: 200, mr: 2, mt: isMobile ? 2 : 0 }}>
          <InputLabel>Kategorie</InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            label="Kategorie"
          >
            <MenuItem value="">
              <em>Alle</em>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: isMobile ? "100%" : "30%",
            mt: isMobile ? 2 : 0,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Suche..."
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            sx={{ marginRight: 2, width: "100%" }}
            InputProps={{
              startAdornment: <Search sx={{ marginRight: 1 }} />,
            }}
          />
        </Box>
      </Box>

      {searching ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        searchResults.length > 0 && (
          <Paper
            sx={{
              marginBottom: 2,
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography variant="h6" sx={{ mb: 1, color: "primary.main" }}>
              Suchergebnisse
            </Typography>
            <List sx={{ maxHeight: 200, overflowY: "auto" }}>
              {searchResults.map(renderResultItem)}
            </List>
          </Paper>
        )
      )}

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ width: "100%", overflowX: "auto", borderRadius: "8px" }}
        >
          <Table
            aria-label="conversations table"
            size="small"
            stickyHeader
            sx={{ minWidth: isMobile ? 400 : "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "12px" : "15px",
                    padding: "12px",
                    backgroundColor: "#f5f5f5",
                    borderBottom: "2px solid #ddd",
                  }}
                >
                  Aktionen
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "12px" : "15px",
                    padding: "12px",
                    paddingLeft: "0px",
                    backgroundColor: "#f5f5f5",
                    borderBottom: "2px solid #ddd",
                    width: "75px"
                  }}
                >
                  Tel-Nr.
                </TableCell>
                {attributes.map((attribute) => (
                  <TableCell
                    key={attribute.attribute_name}
                    sx={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: isMobile ? "12px" : "15px",
                      padding: "12px",
                      backgroundColor: "#f5f5f5",
                      borderBottom: "2px solid #ddd",
                    }}
                  >
                    {attribute.attribute_name}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "12px" : "15px",
                    padding: "14px",
                    backgroundColor: "#f5f5f5",
                    borderBottom: "2px solid #ddd",
                  }}
                >
                  Anruf am
                </TableCell>
                
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "12px" : "15px",
                    padding: "12px",
                    backgroundColor: "#f5f5f5",
                    borderBottom: "2px solid #ddd",
                  }}
                >
                  Zusammenfassung
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "12px" : "15px",
                    padding: "12px",
                    backgroundColor: "#f5f5f5",
                    borderBottom: "2px solid #ddd",
                  }}
                >
                  Audio
                </TableCell>
                {activeTab !== "Active" ? (
                  <TableCell
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: isMobile ? "12px" : "15px",
                    padding: "12px",
                    backgroundColor: "#f5f5f5",
                    borderBottom: "2px solid #ddd",
                  }}
                >
                  Bearbeiter
                </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {conversations.map((conversation) => (
                <ConversationRow
                  key={conversation.conversation_id}
                  conversation={conversation}
                  attributes={attributes}
                  handleOpenDialog={handleOpenDialog}
                  handleCopyToClipboard={handleCopyToClipboard}
                  handleSmsIconClick={handleSmsIconClick}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={page === 1}
          onClick={() => handlePageChange(page - 1)}
          sx={{ marginRight: "10px" }}
        >
          Zurück
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={page === totalPages}
          onClick={() => handlePageChange(page + 1)}
        >
          Weiter
        </Button>
      </Box>
      {selectedConversation && (
        <ConversationActions
          conversation={selectedConversation}
          open={openDialog}
          onClose={handleCloseDialog}
          onMarkAsDone={handleMarkAsDone}
        />
      )}
      {openSmsDialog && (
        <SmsDetailsForm
          conversationId={selectedConversationId}
          open={openSmsDialog}
          onClose={handleCloseSmsDialog}
        />
      )}
    </Container>
  );
};

export default ConversationList;
