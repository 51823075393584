import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Tabs,
  Tab,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import Sidebar from '../../components/Sidebar';
import AgentSettings from './AgentSettings';
import KategorienSettings from './KategorienSettings';
import BenutzerSettings from './BenutzerSettings';
import AttributeSettings from './AttributeSettings'; // Ensure correct path
import ChatSettings from './ChatSettings';
import axiosInstance from '../../axiosConfig';
import { Helmet } from 'react-helmet';
import ChatsPage from './ChatsPage';
const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await axiosInstance.get('/web_configurations');
      console.log('Fetched agents:', response.data);
      setAgents(response.data);
      if (response.data.length > 0) {
        setSelectedAgent(response.data[0].ConfigID);
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
  };

  const handleCreateAgent = () => {
    setIsCreating(true);
    setSelectedAgent('');
  };

  const handleDeleteAgent = async () => {
    if (selectedAgent) {
      try {
        await axiosInstance.delete(`/configurations/${selectedAgent}`);
        fetchAgents();
        setSelectedAgent('');
      } catch (error) {
        console.error('Error deleting agent:', error);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
      <Helmet>
    <title>blub.ai - Einstellungen</title>
    <meta name="description" content="Passen Sie Ihre blub.ai-Einstellungen an, um das Anrufmanagement zu optimieren." />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="canonical" href="https://www.blub.ai/settings" />
</Helmet>

      <Sidebar />
      <Container maxWidth="wd" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
        <Typography variant="h3" component="h1" align="center" gutterBottom>
          Settings
        </Typography>
        <Paper sx={{ p: { xs: 2, md: 4 } }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Agenten" />
            <Tab label="Kategorien" />
            <Tab label="Benutzer" />
            <Tab label="Attribute" />
            <Tab label="Chat" />
          </Tabs>
          <Box sx={{ mt: { xs: 2, md: 4 } }}>
            {activeTab === 0 && (
              <Box>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <InputLabel>Agent</InputLabel>
                  <Select
                    value={selectedAgent}
                    onChange={handleAgentChange}
                    label="Agent"
                  >
                    {agents.map((agent, index) => (
                      <MenuItem key={agent.ConfigID} value={agent.ConfigID}>
                        {index} ({agent.AuthUsername})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', mb: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleCreateAgent} sx={{ mb: { xs: 2, md: 0 } }}>
                    Create New Agent
                  </Button>
                  <Button variant="contained" color="secondary" onClick={handleDeleteAgent} disabled={!selectedAgent}>
                    Delete Agent
                  </Button>
                </Box>
                <AgentSettings agentId={selectedAgent} isCreating={isCreating} onCreated={fetchAgents} />
              </Box>
            )}
            {activeTab === 1 && <KategorienSettings />}
            {activeTab === 2 && <BenutzerSettings />}
            {activeTab === 3 && <AttributeSettings />}
            {activeTab === 4 && <ChatSettings />}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default SettingsPage;
