import React from 'react';
import { motion } from 'framer-motion';
import { FaCheckCircle } from 'react-icons/fa';

const benefits = [
  { 
    title: 'Effizienzsteigerung', 
    description: 'Steigern Sie die Effizienz im Kundenservice durch den Einsatz modernster KI-Technologie. Unser virtueller Telefonassistent automatisiert Anrufe und optimiert Ihre Abläufe, um die Produktivität zu erhöhen.' 
  },
  { 
    title: 'Mitarbeiterentlastung', 
    description: 'Entlasten Sie Ihre Mitarbeiter durch automatisierte Anrufverwaltung und -weiterleitung. Der intelligente Anrufbeantworter übernimmt repetitive Aufgaben, sodass Ihr Team mehr Zeit für wertschöpfende Tätigkeiten hat.' 
  },
  { 
    title: 'Erhöhte Erreichbarkeit', 
    description: 'Erhöhen Sie die Erreichbarkeit und Zufriedenheit der Anrufer dank unserer rund um die Uhr Verfügbarkeit. Mit unserem virtuellen Telefonassistenten sind Sie immer erreichbar und verbessern die Kundenzufriedenheit.' 
  },
  { 
    title: '24/7 Verfügbarkeit', 
    description: 'Garantieren Sie ständige Erreichbarkeit mit unserer 24/7 Verfügbarkeit, die keine Wünsche offen lässt. Kunden können Sie jederzeit erreichen, ohne Wartezeiten und mit optimalem Service.' 
  },
];

const testimonials = [
  {
    quote: "Seit wir den KI-Anrufbeantworter von Blub.ai nutzen, hat sich unser Kundenservice spürbar verbessert. Anfragen werden schneller bearbeitet, und wir haben deutlich weniger manuelle Nacharbeit. Blub.ai ist aus unserem täglichen Betrieb nicht mehr wegzudenken.",
    author: "Sophie Müller, Leiterin Kundenservice"
  }
];

const Benefits = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl">Vorteile für Ihr Unternehmen</h2>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-8">Entdecken Sie die wichtigsten Vorteile, die unsere Lösung Ihrem Unternehmen bietet.</p>
        </div>
        <div className="grid grid-cols-1 gap-8 text-center sm:grid-cols-2 lg:grid-cols-4">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              className="p-8 bg-white rounded-xl shadow-lg transform transition-all duration-300 hover:shadow-2xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="flex items-center justify-center w-16 h-16 mx-auto text-blue-600">
                <FaCheckCircle className="w-full h-full" />
              </div>
              <h3 className="mt-6 text-xl font-semibold text-gray-900">{benefit.title}</h3>
              <p className="mt-4 text-base leading-7 text-gray-600">{benefit.description}</p>
            </motion.div>
          ))}
        </div>
        <div className="mt-16">
          {testimonials.map((testimonial, index) => (
            <motion.blockquote
              key={index}
              className="max-w-3xl mx-auto text-lg italic leading-relaxed text-gray-700 border-l-4 border-blue-600 pl-4"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.3 }}
            >
              "{testimonial.quote}"
              <footer className="mt-2 text-sm text-gray-500">— {testimonial.author}</footer>
            </motion.blockquote>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
