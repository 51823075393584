import React, { useState } from 'react';
import {
  Box, Typography, TextField, Button, Paper, List, ListItem, ListItemText, Divider, Alert, Snackbar
} from '@mui/material';
import axiosInstance from '../axiosConfig';

const Notes = ({ conversationId, notes, setConversation }) => {
  const [newNote, setNewNote] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const parsedNotes = Array.isArray(notes) ? notes : (notes ? JSON.parse(notes) : []);

  const handleAddNote = async () => {
    if (newNote.trim() === '') {
      setAlertMessage('Notiz kann nicht leer sein.');
      setAlertSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      // Send the new note to the backend without including user_id or user_name
      const response = await axiosInstance.post(`/conversation/${conversationId}/notes`, {
        user_note: newNote,
      });

      const updatedNotes = response.data.notes; // Get the updated notes from the response

      // Update the conversation state with the new notes
      setConversation((prevConversation) => ({
        ...prevConversation,
        notes: updatedNotes,
      }));

      setNewNote('');
      setAlertMessage('Notiz erfolgreich hinzugefügt.');
      setAlertSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      setAlertMessage('Fehler beim Hinzufügen der Notiz.');
      setAlertSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h6" gutterBottom>Notizen</Typography>
      <Paper elevation={2} sx={{ padding: 2, marginBottom: 3 }}>
        <List>
          {parsedNotes.map((note, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={note.user_note}
                  secondary={`${note.user_name} - ${new Date(note.posted_at).toLocaleString('de-DE')}`}
                />
              </ListItem>
              {index < parsedNotes.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
      <TextField
        label="Neue Notiz hinzufügen"
        multiline
        fullWidth
        rows={4}
        value={newNote}
        onChange={(e) => setNewNote(e.target.value)}
        variant="outlined"
        sx={{ marginBottom: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddNote}
      >
        Notiz hinzufügen
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Notes;
