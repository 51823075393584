// src/pages/ConversationDetails.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Box, IconButton, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  Grid
} from '@mui/material';
import { ArrowBack, PlayArrow, Headset, HeadsetOff } from '@mui/icons-material';
import axiosInstance from '../../axiosConfig';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import AudioStream from '../../components/AudioStream';
import { io } from "socket.io-client";
import { Helmet } from 'react-helmet';
import Notes from '../../components/Notes';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const ConversationDetails = () => {
  const { conversation_id } = useParams();
  const [conversation, setConversation] = useState({ messages: [], summary: '', notes: [] });
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [extensionNumber, setExtensionNumber] = useState('');

  const socketRef = useRef(null);

  // Fetching the conversation details
  useEffect(() => {
    const fetchConversationDetails = async () => {
      try {
        const response = await axiosInstance.get(`/conversation/${conversation_id}`);
        setConversation(response.data);
      } catch (error) {
        console.error("There was an error fetching the conversation details!", error);
      }
    };

    fetchConversationDetails();

    // Initialize WebSocket connection
    socketRef.current = io('https://blub.ai', {
      path: '/socket.io',
      transports: ['websocket'],
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket server with ID:", socketRef.current.id);
    });

    socketRef.current.on("new_message", (data) => {
      if (data.conversation_id === conversation_id) {
        setConversation((prevConversation) => ({
          ...prevConversation,
          messages: [...prevConversation.messages, {
            id: Date.now(),
            timestamp: data.timestamp,
            role: data.role,
            content: data.message,
            audio_file_url: data.audio_url
          }]
        }));
      }
    });

    socketRef.current.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket error:", error);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [conversation_id]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  const roleMapping = {
    user: "Anrufer",
    assistant: "Assistent"
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('de-DE', {
      timeZone: 'Europe/Berlin',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const handlePlayAudio = async (messageId) => {
    try {
      const response = await axiosInstance.get(`/audio/${conversation_id}/${messageId}`, { responseType: 'blob' });
      const audioUrl = URL.createObjectURL(response.data);
      const audio = new Audio(audioUrl);
      audio.play();
    } catch (error) {
      console.error("There was an error fetching the audio file!", error);
    }
  };

  const handleTakeCallClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalSubmit = async () => {
    try {
      const response = await axiosInstance.post(`/take_call`, {
        conversation_id: conversation_id,
        extension_number: extensionNumber
      });
      console.log("Call taken successfully:", response.data);
      setModalOpen(false);
    } catch (error) {
      console.error("There was an error taking the call!", error);
    }
  };

  const handleSubscribe = async () => {
    try {
      const response = await axiosInstance.post(`/stream/subscribe`, {
        conversation_id,
        socket_id: socketRef.current.id // Send the current socket ID
      });
      console.log("Subscribed to stream:", response.data);
      setIsSubscribed(true);
    } catch (error) {
      console.error("There was an error subscribing to the stream!", error);
    }
  };

  const handleUnsubscribe = () => {
    setIsSubscribed(false);
  };

  if (!conversation) {
    return <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>Loading...</Typography>;
  }

  return (
    <PageContainer>
      <Helmet>
    <title>blub.ai - Gesprächsdetails</title>
    <meta name="description" content="Überprüfen Sie die Details und den Verlauf Ihrer Telefonate im blub.ai-Dashboard." />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="canonical" href="https://www.blub.ai/conversation/:conversation_id" />
</Helmet>

      <Sidebar />
      <ContentContainer>
        <Container maxWidth="xlg" sx={{ marginTop: 4, marginBottom: 7 }}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <Tooltip title="Back">
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
              <Typography variant="h4" component="h1" sx={{ marginLeft: 1 }}>
                Konversationsdetails
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box sx={{ marginBottom: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Zusammenfassung
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    {conversation.summary}
                  </Typography>

                  <Typography variant="h6" gutterBottom sx={{ marginTop: '25px' }}>
                    Details
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Angerufen:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body1">
                          {formatTimestamp(conversation.created_at)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Gespräch beendet:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body1">
                          {formatTimestamp(conversation.ended_at)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Konversations-ID:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body1">
                          {conversation.conversation_id}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Tel-Nr:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body1">
                          {conversation.phone_number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {!conversation.ended_at && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleTakeCallClick}
                      sx={{ marginTop: 2, width: '100%' }}
                    >
                      Anruf übernehmen
                    </Button>
                    <Button
                      variant="contained"
                      color={isSubscribed ? "secondary" : "primary"}
                      onClick={isSubscribed ? handleUnsubscribe : handleSubscribe}
                      sx={{ marginTop: 2, width: '100%' }}
                      startIcon={isSubscribed ? <HeadsetOff /> : <Headset />}
                    >
                      {isSubscribed ? "Stream beenden" : "Stream abonnieren"}
                    </Button>
                  </>
                )}
                <Grid>
                <Notes
                conversationId={conversation_id}
                notes={conversation.notes || []}
                setConversation={setConversation}
                />
              </Grid>
              </Grid>

              

              <Grid item xs={12} md={8}>
                <Typography variant="h6" gutterBottom>
                  Nachrichten
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Timestamp</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Rolle</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Nachricht</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Audio</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {conversation.messages.map((message) => (
                        <TableRow key={message.id} sx={{ transition: 'background-color 0.3s ease', backgroundColor: message.new ? '#e0f7fa' : 'transparent' }}>
                          <TableCell>{formatTimestamp(message.timestamp)}</TableCell>
                          <TableCell>{roleMapping[message.role]}</TableCell>
                          <TableCell>{message.content}</TableCell>
                          <TableCell>
                            {message.audio_file_url && (
                              <IconButton onClick={() => handlePlayAudio(message.id)}>
                                <PlayArrow />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow ref={messagesEndRef} />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <Footer />
      </ContentContainer>

      {/* Modal for taking call */}
      <Dialog open={modalOpen} onClose={handleModalClose}>
        <DialogTitle>Anruf übernehmen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="extensionNumber"
            label="Meine Durchwahl"
            type="tel"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            fullWidth
            variant="standard"
            value={extensionNumber}
            onChange={(e) => setExtensionNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="secondary">
            ABBRECHEN
          </Button>
          <Button onClick={handleModalSubmit} color="primary">
            ÜBERNEHMEN
          </Button>
        </DialogActions>
      </Dialog>

      {/* Pass the socket instance to AudioStream */}
      <AudioStream socket={socketRef.current} isSubscribed={isSubscribed} />

    </PageContainer>
  );
};

export default ConversationDetails;
