import React from 'react';
import { FaCheck, FaTimes, FaPhone, FaRobot, FaFileAlt, FaLink, FaUsers, FaChartLine, FaReply, FaList, FaMobile, FaHeadset, FaMicrophone, FaUserTie, FaDollarSign, FaSignInAlt, FaShoppingCart, FaInfoCircle } from 'react-icons/fa';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const columns = [
  { label: '', key: 'feature' },
  { label: 'Basis', key: 'basic' },
  { label: 'Pro', key: 'pro' },
  { label: 'Enterprise', key: 'enterprise' },
];

const rows = [
  { feature: <><FaPhone className="inline mr-2" />Automatische Anrufannahme</>, basic: true, pro: true, enterprise: true },
  { feature: <><FaRobot className="inline mr-2" />Intelligente Gesprächsführung</>, basic: true, pro: true, enterprise: true },
  { feature: <><FaFileAlt className="inline mr-2" />Live-Transkription</>, basic: true, pro: true, enterprise: true },
  { feature: <><FaLink className="inline mr-2" />Integration in bestehende Systeme</>, basic: true, pro: true, enterprise: true },
  { feature: <><FaUsers className="inline mr-2" />Mehrere Benutzer</>, basic: 5, pro: 25, enterprise: true },
  { feature: <><FaChartLine className="inline mr-2" />Echtzeit-Analysen</>, basic: true, pro: true, enterprise: true },
  { feature: <><FaReply className="inline mr-2" />Anrufe übernehmen</>, basic: true, pro: true, enterprise: true },
  { feature: <><FaList className="inline mr-2" />Konfigurierbare DTMF-Inputs</>, basic: true, pro: true, enterprise: true },
  { feature: <><FaMobile className="inline mr-2" />SMS-Antworten</>, basic: false, pro: true, enterprise: true },
  { feature: <><FaHeadset className="inline mr-2" />Priorisierter Support</>, basic: <FaTimes className="text-red-600" />, pro: true, enterprise: true },
  { feature: <><FaMicrophone className="inline mr-2" />Eigene Stimme</>, basic: false, pro: false, enterprise: true },
  { feature: <><FaUserTie className="inline mr-2" />Dedizierter Account Manager</>, basic: <FaTimes className="text-red-600" />, pro: <FaTimes className="text-red-600" />, enterprise: true },
  { feature: <><FaDollarSign className="inline mr-2" />Preis</>, basic: '0.19€ pro Minute', pro: '99€/Monat', enterprise: 'Kontaktieren Sie uns' },
];

const renderCell = (value) => {
  if (typeof value === 'boolean') {
    return value ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />;
  }
  return value;
};

const Comparison = () => {
  return (
    <section className="py-16 bg-white sm:py-20 lg:py-24">
      <Helmet>
        <title>blub.ai - Funktionen vergleichen</title>
        <meta name="description" content="Vergleichen Sie die Funktionen des blub.ai-Telefonassistenten mit anderen Lösungen auf dem Markt." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.blub.ai/compare" />
      </Helmet>

      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-900 sm:text-5xl lg:text-6xl">Vergleich der Pakete</h2>
          <p className="mt-4 text-lg text-gray-600">Wählen Sie das passende Paket für Ihre Bedürfnisse und optimieren Sie Ihre Anrufverwaltung.</p>
        </div>

        <div className="mt-12 overflow-x-auto">
          <table className="w-full text-left border-collapse bg-white shadow-lg rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                {columns.map((col) => (
                  <th key={col.key} className="px-4 py-3 text-sm font-semibold text-gray-600">{col.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex} className="border-t border-gray-200">
                  {columns.map((col) => (
                    <td key={col.key} className="px-4 py-4 text-sm text-gray-800">{renderCell(row[col.key])}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Comparison;
