import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography } from '@mui/material';
import axiosInstance from '../axiosConfig';

const BenutzerVerwaltung = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [role, setRole] = useState('');
  const [customerID, setCustomerID] = useState('');
  const [customers, setCustomers] = useState([]);
  const [roles] = useState(['Global Admin', 'Customer Admin', 'Customer User']);

  useEffect(() => {
    fetchUsers();
    fetchCustomers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/admin/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleUserChange = (event) => {
    const userId = event.target.value;
    const user = users.find(u => u.supabase_user_id === userId);
    setSelectedUser(userId);
    setDisplayName(user.displayname);
    setRole(user.role);
    setCustomerID(user.customer_id);
  };

  const handleSave = async () => {
    try {
      const selectedCustomer = customers.find(customer => customer.id === customerID);
      const customerName = selectedCustomer ? selectedCustomer.name : '';
  
      await axiosInstance.put(`/admin/users/${selectedUser}`, {
        displayname: displayName,
        role,
        customer_name: customerName
      });
      fetchUsers();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };
  

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Benutzerverwaltung
      </Typography>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel>Benutzer</InputLabel>
        <Select value={selectedUser} onChange={handleUserChange} label="Benutzer">
          {users.map((user) => (
            <MenuItem key={user.supabase_user_id} value={user.supabase_user_id}>
              {user.displayname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedUser && (
        <Box sx={{ width: '100%' }}>
          <TextField
            fullWidth
            label="Display Name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            sx={{ mb: 4 }}
          />
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel>Rolle</InputLabel>
            <Select value={role} onChange={(e) => setRole(e.target.value)} label="Rolle">
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel>Kunde</InputLabel>
            <Select value={customerID} onChange={(e) => setCustomerID(e.target.value)} label="Kunde">
              {customers.map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BenutzerVerwaltung;
