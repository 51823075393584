import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const DemoFormSteps = ({ step, handleChange, formData, direction }) => {
  switch (step) {
    case 1:
      return (
        <motion.div
          key={step}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="space-y-6"
        >
          <h2 className="text-xl font-semibold mb-4">Kontaktdaten</h2>
          <div>
            <label className="block text-gray-700">Firmenname</label>
            <input
              type="text"
              name="companyName"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.companyName}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Ansprechpartner</label>
            <input
              type="text"
              name="contactPerson"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.contactPerson}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Anschrift</label>
            <input
              type="text"
              name="address"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">E-Mail-Adresse</label>
            <input
              type="email"
              name="email"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Telefonnummer</label>
            <input
              type="text"
              name="phone"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </motion.div>
      );
    case 2:
      return (
        <motion.div
          key={step}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="space-y-6"
        >
          <h2 className="text-xl font-semibold mb-4">Unternehmensinformationen</h2>
          <div>
            <label className="block text-gray-700">Branche</label>
            <input
              type="text"
              name="industry"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.industry}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Standort</label>
            <input
              type="text"
              name="location"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.location}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Mitarbeiterzahl</label>
            <input
              type="number"
              name="employees"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.employees}
              onChange={handleChange}
            />
          </div>
        </motion.div>
      );
    case 3:
      return (
        <motion.div
          key={step}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="space-y-6"
        >
          <h2 className="text-xl font-semibold mb-4">Technische Details</h2>
          <div>
            <label className="block text-gray-700">Derzeitige Telefonanlage</label>
            <input
              type="text"
              name="currentSystem"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.currentSystem}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Stunden/Monat telefoniert</label>
            <input
              type="number"
              name="monthlyHours"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.monthlyHours}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block text-gray-700">Weitere technische Anforderungen</label>
            <textarea
              name="additionalRequirements"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.additionalRequirements}
              onChange={handleChange}
            />
          </div>
        </motion.div>
      );
    case 4:
      return (
        <motion.div
          key={step}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="space-y-6"
        >
          <h2 className="text-xl font-semibold mb-4">Zusätzliche Informationen</h2>
          <div>
            <label className="block text-gray-700">Weitere Kommentare oder Anfragen</label>
            <textarea
              name="comments"
              className="w-full p-2 border border-gray-300 rounded mt-1"
              value={formData.comments}
              onChange={handleChange}
            />
          </div>
        </motion.div>
      );
    default:
      return null;
  }
};

export default DemoFormSteps;
