import React from 'react';
import { FaPhone, FaBrain, FaChartLine, FaCogs, FaEnvelopeOpenText, FaCloud } from 'react-icons/fa';

const features = [
  { 
    icon: <FaPhone />, 
    title: 'Vollautomatisierte Anrufannahme', 
    description: 'Unser KI-basierter Anrufbeantworter nimmt Anrufe automatisch entgegen und hilft Ihren Kunden schnell, wodurch die Effizienz Ihrer Praxis erhöht wird. Der virtuelle Telefonassistent bearbeitet Anrufe autonom und sorgt für eine nahtlose Kommunikation.' 
  },
  { 
    icon: <FaBrain />, 
    title: 'Intelligente Gesprächsführung durch KI', 
    description: 'Mit unserer fortschrittlichen künstlichen Intelligenz liefert unser System präzise und hilfreiche Antworten auf Kundenanfragen. Optimieren Sie den Kundenservice und verbessern Sie die Kommunikation mit unserem intelligenten Anrufbeantworter.' 
  },
  { 
    icon: <FaChartLine />, 
    title: 'Effizienzsteigerung', 
    description: 'Automatisieren Sie repetitive Aufgaben und steigern Sie die Produktivität Ihres Teams erheblich. Unsere Anrufmanagement-Software maximiert die Effizienz und ermöglicht es Ihrem Team, sich auf wichtigere Aufgaben zu konzentrieren.' 
  },
  { 
    icon: <FaCogs />, 
    title: 'Konfigurierbare Abfragen', 
    description: 'Passen Sie unsere Lösung vollständig an die spezifischen Bedürfnisse und Prozesse Ihres Betriebes an. Erstellen Sie individuelle Anrufkategorien und optimieren Sie die Anrufbearbeitung nach Ihren Anforderungen.' 
  },
  { 
    icon: <FaEnvelopeOpenText />, 
    title: 'Echtzeit-Transkription und -Analyse', 
    description: 'Bearbeiten Sie Anfragen effizient auf einer zentralen Plattform. Unsere Lösung bietet Echtzeit-Transkription und detaillierte Anrufanalysen, um den Kundenservice zu verbessern und wertvolle Einblicke zu gewinnen.' 
  },
  { 
    icon: <FaCloud />, 
    title: 'Integration in bestehende Telefonanlagen', 
    description: 'Unsere cloudbasierte Lösung ist von überall zugänglich und erfordert keine lokale Installation. Integrieren Sie unseren virtuellen Telefonassistenten nahtlos in Ihre bestehende Infrastruktur und verbessern Sie die Erreichbarkeit und Effizienz.' 
  },
];

const Features = () => {
  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl">Hauptmerkmale</h2>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-8">Unsere Lösung bietet eine Reihe von leistungsstarken Funktionen, um Ihre Anrufverwaltung zu optimieren.</p>
        </div>

        <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-24">
          {features.map((feature, index) => (
            <div key={index} className={`md:p-8 lg:p-14 ${index % 3 !== 0 ? 'md:border-l' : ''} ${index >= 3 ? 'md:border-t' : ''} md:border-gray-200`}>
              <div className="flex items-center justify-center mx-auto text-6xl text-blue-600">
                {feature.icon}
              </div>
              <h3 className="mt-12 text-xl font-bold text-gray-900">{feature.title}</h3>
              <p className="mt-5 text-base text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
