// BenutzerSettings.js
import React from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
} from '@mui/material';

const BenutzerSettings = () => {
  // Add state and handlers similar to AgentSettings if needed
  return (
    <Box component="form" noValidate sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Save User Settings
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BenutzerSettings;
