import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography, Paper, Chip } from '@mui/material';
import axiosInstance from '../axiosConfig';

const TemplateManagement = () => {
  const [templates, setTemplates] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [activeAttributes, setActiveAttributes] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateContent, setTemplateContent] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const contentRef = useRef(null);

  useEffect(() => {
    fetchCustomers();
    if (selectedCustomer) {
      fetchTemplates(selectedCustomer);
      fetchActiveAttributes(selectedCustomer);
    }
  }, [selectedCustomer]);

  const fetchTemplates = async (customerId) => {
    try {
      const response = await axiosInstance.get('/get-templates', {
        params: { customer_id: customerId }
      });
      setTemplates(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Vorlagen:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Kunden:', error);
    }
  };

  const fetchActiveAttributes = async (customerId) => {
    try {
      const response = await axiosInstance.get('/active-attributes', { params: { customer_id: customerId } });
      setActiveAttributes(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der aktiven Attribute:', error);
    }
  };

  const handleTemplateChange = (event) => {
    const templateId = event.target.value;
    const template = templates.find(t => t.id === templateId);
    setSelectedTemplate(templateId);
    setTemplateName(template ? template.name : '');
    setTemplateContent(template ? template.template : '');
  };

  const handleCustomerChange = (event) => {
    const customerId = event.target.value;
    setSelectedCustomer(customerId);
    fetchTemplates(customerId);
    fetchActiveAttributes(customerId);
  };

  const handleSave = async () => {
    try {
      if (selectedTemplate) {
        await axiosInstance.put(`/edit-template/${selectedTemplate}`, { name: templateName, template: templateContent });
      } else {
        await axiosInstance.post('/create-template', { name: templateName, template: templateContent, customer_id: selectedCustomer });
      }
      fetchTemplates(selectedCustomer);
      setSelectedTemplate('');
      setTemplateName('');
      setTemplateContent('');
      setSelectedCustomer('');
    } catch (error) {
      console.error('Fehler beim Speichern der Vorlage:', error);
    }
  };

  const handleDelete = async () => {
    try {
      if (selectedTemplate && selectedCustomer) {
        await axiosInstance.delete(`/delete-template/${selectedTemplate}`, {
          data: { customer_id: selectedCustomer }
        });
        fetchTemplates(selectedCustomer);
        setSelectedTemplate('');
        setTemplateName('');
        setTemplateContent('');
        setSelectedCustomer('');
      }
    } catch (error) {
      console.error('Fehler beim Löschen der Vorlage:', error);
    }
  };

  const insertPlaceholder = (placeholder) => {
    const startPos = contentRef.current.selectionStart;
    const endPos = contentRef.current.selectionEnd;
    const newContent = `${templateContent.substring(0, startPos)}{${placeholder}}${templateContent.substring(endPos)}`;
    setTemplateContent(newContent);
  };

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Paper sx={{ p: 2, maxWidth: 800, width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Vorlagenverwaltung
        </Typography>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Kunde</InputLabel>
          <Select value={selectedCustomer} onChange={handleCustomerChange} label="Kunde">
            {customers.map((customer) => (
              <MenuItem key={customer.id} value={customer.id}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <InputLabel>Vorlage</InputLabel>
          <Select value={selectedTemplate} onChange={handleTemplateChange} label="Vorlage">
            <MenuItem value="">
              <em>Neue Vorlage erstellen</em>
            </MenuItem>
            {templates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {activeAttributes.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6">Verfügbare Platzhalter:</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {activeAttributes.map(attr => (
                <Chip
                  key={attr}
                  label={`{${attr}}`}
                  onClick={() => insertPlaceholder(attr)}
                  sx={{ cursor: 'pointer' }}
                />
              ))}
            </Box>
            <Typography variant="body2">
              Verwenden Sie diese Platzhalter in Ihrem Vorlageninhalt. Zum Beispiel wird "Hallo &#123;name&#125;" durch den tatsächlichen Namen ersetzt.
            </Typography>
          </Box>
        )}
        <TextField
          fullWidth
          label="Vorlagenname"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          sx={{ mb: 4 }}
        />
        <TextField
          fullWidth
          inputRef={contentRef}
          label="Vorlageninhalt"
          value={templateContent}
          onChange={(e) => setTemplateContent(e.target.value)}
          multiline
          rows={4}
          sx={{ mb: 4 }}
        />
        <Button variant="contained" color="primary" onClick={handleSave} sx={{ mb: 0, marginRight: '10px' }}>
          Speichern
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Löschen
        </Button>
      </Paper>
    </Box>
  );
};

export default TemplateManagement;
