import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosInstance from '../axiosConfig';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Legend } from 'recharts';
import { Card, CardContent, Typography, Grid, CircularProgress, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const StatisticsContainer = styled.div`
  background: #f0f4f8;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
`;

const StatValue = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const StatCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: 100px; /* Ensures all cards have the same height */
  margin-bottom: 20px; /* Add margin to ensure spacing between cards */
`;

const ChartCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const SectionHeader = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Statistics = () => {
  const [stats, setStats] = useState(null);
  const [peakCallTimes, setPeakCallTimes] = useState(null);
  const [callDurationByTime, setCallDurationByTime] = useState(null);
  const [repeatCalls, setRepeatCalls] = useState(null);
  const [costAnalysis, setCostAnalysis] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axiosInstance.get('/statistics');
        setStats(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Statistiken', error);
      }
    };

    const fetchPeakCallTimes = async () => {
      try {
        const response = await axiosInstance.get('/peak_call_times');
        setPeakCallTimes(response.data.peak_call_times);
      } catch (error) {
        console.error('Fehler beim Abrufen der Spitzenzeiten der Anrufe', error);
      }
    };

    const fetchCallDurationByTime = async () => {
      try {
        const response = await axiosInstance.get('/call_duration_by_time');
        setCallDurationByTime(response.data.call_duration_by_time);
      } catch (error) {
        console.error('Fehler beim Abrufen der Anrufdauer nach Tageszeit', error);
      }
    };

    const fetchRepeatCalls = async () => {
      try {
        const response = await axiosInstance.get('/repeat_calls');
        setRepeatCalls(response.data.repeat_calls);
      } catch (error) {
        console.error('Fehler beim Abrufen der Wiederholungsanrufe', error);
      }
    };

    const fetchCostAnalysis = async () => {
      try {
        const response = await axiosInstance.get('/cost_analysis');
        setCostAnalysis(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Kostenanalyse', error);
      }
    };

    fetchStats();
    fetchPeakCallTimes();
    fetchCallDurationByTime();
    fetchRepeatCalls();
    fetchCostAnalysis();
  }, []);

  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AB82FF', '#FF6347', '#7B68EE'];

  if (!stats || !peakCallTimes || !callDurationByTime || !repeatCalls || !costAnalysis) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const {
    total_words_spoken_by_ai,
    average_conversation_duration,
    total_conversations,
    average_response_time,
    common_categories,
    unique_customers,
    repeat_customers,
    activity_over_time,
    phone_activity
  } = stats;

  const reversedActivityOverTime = [...activity_over_time].reverse();

  return (
    <StatisticsContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Von der KI gesprochene Wörter
              </Typography>
              <StatValue>{total_words_spoken_by_ai}</StatValue>
            </CardContent>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Durchschnittliche Gesprächsdauer
              </Typography>
              <StatValue>{average_conversation_duration} Sekunden</StatValue>
            </CardContent>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Gesamtanzahl der Gespräche
              </Typography>
              <StatValue>{total_conversations}</StatValue>
            </CardContent>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Durchschnittliche Antwortzeit der KI
              </Typography>
              <StatValue>{average_response_time} Sekunden</StatValue>
            </CardContent>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Einzigartige Anrufer
              </Typography>
              <StatValue>{repeatCalls.filter(call => !call.is_repeat).length}</StatValue>
            </CardContent>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Wiederkehrende Anrufer
              </Typography>
              <StatValue>{repeatCalls.filter(call => call.is_repeat).length}</StatValue>
            </CardContent>
          </StatCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartCard>
            <SectionHeader>Konversationen pro Tag</SectionHeader>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={reversedActivityOverTime}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="conversations_per_day" name="Konversationen" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartCard>
            <SectionHeader>Telefonaktivität nach Wochentag</SectionHeader>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={phone_activity}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day_of_week" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="morning" stackId="a" fill="#8884d8" name="Morgen" />
                <Bar dataKey="midday" stackId="a" fill="#82ca9d" name="Mittag" />
                <Bar dataKey="evening" stackId="a" fill="#ffc658" name="Abend" />
              </BarChart>
            </ResponsiveContainer>
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={12}>
          <ChartCard>
            <SectionHeader>Spitzenzeiten der Anrufe (Stündlich)</SectionHeader>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={peakCallTimes}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hour" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="call_count" fill="#8884d8" name="Anrufe" />
              </BarChart>
            </ResponsiveContainer>
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={6} >
          <ChartCard >
            <SectionHeader>Kostenanalyse für diesen Monat</SectionHeader>
            <TableContainer component={Paper} >
              <Table >
                <TableHead>
                  <TableRow>
                    <TableCell>Beschreibung</TableCell>
                    <TableCell align="right">Wert</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Gesamtdauer der Anrufe</TableCell>
                    <TableCell align="right">{costAnalysis.total_call_duration_hours.toFixed(2)} Stunden</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Kosten für einen menschlichen Mitarbeiter für {costAnalysis.total_call_duration_hours.toFixed(2)} Stunden</TableCell>
                    <TableCell align="right">{costAnalysis.human_cost.toFixed(2)} €</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Kosten für {costAnalysis.agent_config_count} Agenten für {costAnalysis.total_call_duration_hours.toFixed(2)} Stunden</TableCell>
                    <TableCell align="right">{((costAnalysis.virtual_assistant_monthly_cost / (4 * 168)).toFixed(2) * costAnalysis.total_call_duration_hours.toFixed(2)).toFixed(2)} €</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Anzahl der Agenten</TableCell>
                    <TableCell align="right">{costAnalysis.agent_config_count}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Kosten pro Agent</TableCell>
                    <TableCell align="right">{costAnalysis.virtual_assistant_monthly_cost.toFixed(2) / costAnalysis.agent_config_count} €</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Monatliche Kosten für Agenten</TableCell>
                    <TableCell align="right">{costAnalysis.virtual_assistant_monthly_cost.toFixed(2)} €</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ersparnis</TableCell>
                    <TableCell align="right">{costAnalysis.human_cost.toFixed(2) - costAnalysis.virtual_assistant_monthly_cost.toFixed(2)} €</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={6}>
  <ChartCard>
    <SectionHeader>Anrufdauer nach Tageszeit</SectionHeader>
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={[...callDurationByTime].reverse()}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time_of_day" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="avg_call_duration" fill="#82ca9d" name="Dauer (Sekunden)" />
      </BarChart>
    </ResponsiveContainer>
  </ChartCard>
</Grid>

        <Grid item xs={12}>
          <ChartCard>
            <SectionHeader>Gesprächskategorien</SectionHeader>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie data={common_categories} dataKey="category_count" nameKey="category_name" outerRadius={150} fill="#8884d8" label>
                  {common_categories.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </ChartCard>
        </Grid>
      </Grid>
    </StatisticsContainer>
  );
};

export default Statistics;
