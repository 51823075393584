import React from 'react';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
const Impressum = () => {
  return (
    <section className="relative flex flex-col items-center justify-center text-center p-10 bg-gradient-to-r from-white to-blue-50 text-gray-900 min-h-screen overflow-hidden">
 <Helmet>
    <title>blub.ai - Impressum</title>
    <meta name="description" content="Impressum und rechtliche Informationen zu blub.ai." />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://www.blub.ai/impressum" />
</Helmet>

      <motion.div 
        className="absolute inset-0 bg-gradient-to-r from-blue-50 to-blue-200 opacity-30"
        animate={{ backgroundPosition: ['0% 0%', '100% 100%'] }}
        transition={{ duration: 15, repeat: Infinity, ease: "linear" }}
        style={{ backgroundSize: '200% 200%' }}
      />

      <motion.div
        className="absolute -bottom-20 -left-20 w-96 h-96 bg-gradient-to-br from-blue-200 to-blue-500 rounded-full opacity-30"
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 10, repeat: Infinity, ease: "easeInOut" }}
      />
      <motion.div
        className="absolute -top-20 -right-20 w-96 h-96 bg-gradient-to-br from-blue-200 to-blue-500 rounded-full opacity-30"
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 10, repeat: Infinity, ease: "easeInOut", delay: 5 }}
      />

      <motion.div
        className="max-w-4xl relative z-10 text-left"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-5xl font-bold mb-4 text-center">Impressum</h1>
        <div className="text-xl mb-4 space-y-6">
          <p><strong>Armann Systems GmbH</strong></p>
          <p>Tulpenweg 1</p>
          <p>93092 Barbing</p>
          <p>Deutschland</p>

          <p><strong>Vertreten durch:</strong></p>
          <p>Markus Armann</p>

          <p><strong>Kontakt:</strong></p>
          <p>Telefon: +49 9401 91791500</p>
          <p>E-Mail: kontakt@armann-systems.com</p>

          <p><strong>Registereintrag:</strong></p>
          <p>Eintragung im Handelsregister.</p>
          <p>Registergericht: Amtsgericht Regensburg</p>
          <p>Registernummer: HRB 123456</p>

          <p><strong>Umsatzsteuer-ID:</strong></p>
          <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 326370558</p>

          <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong></p>
          <p>Armann Systems GmbH, Tulpenweg 1, 93092 Barbing</p>

          <p><strong>Streitschlichtung</strong></p>
          <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: 
            <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer" className="text-blue-600"> https://ec.europa.eu/consumers/odr</a>.
          </p>
          <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
      </motion.div>
      <Footer />
    </section>
  );
};

export default Impressum;
