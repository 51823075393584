import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Pagination,
  Box,
} from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'; // For navigation
import axiosInstance from '../axiosConfig';

// Hilfsfunktion, um Feedback in Sterne umzuwandeln
const renderFeedbackStars = (feedback) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(i < feedback ? <Star key={i} className="text-yellow-500" /> : <StarBorder key={i} className="text-gray-300" />);
  }
  return stars;
};

const ChatTable = () => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const navigate = useNavigate(); // Hook for navigation

  // API-Aufruf, um alle Chats zu holen
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axiosInstance.get('/chat/chats'); // API-Route
        setChats(response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError('Keine Chats vorhanden.');
        } else {
          setError('Fehler beim Abrufen der Chats.');
        }
      } finally {
        setLoading(false);
      }
    };
    fetchChats();
  }, []);

  // Berechnung der Anzahl der Seiten basierend auf rowsPerPage und Anzahl der Chats
  const totalPages = Math.ceil(chats.length / rowsPerPage);

  // Seitenwechsel-Handler
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Chats für die aktuelle Seite abrufen
  const getPaginatedChats = () => {
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return chats.slice(startIndex, endIndex);
  };

  // Wenn die Daten noch geladen werden
  if (loading) {
    return (
      <div className="flex justify-center mt-5">
        <CircularProgress />
      </div>
    );
  }

  // Wenn ein Fehler aufgetreten ist oder keine Chats vorhanden sind
  if (error) {
    return (
      <div className="text-center mt-5">
        <Typography variant="h6">{error}</Typography>
      </div>
    );
  }

  // Zur Detailansicht navigieren
  const handleRowClick = (chatId) => {
    navigate(`/chat/${chatId}`);
  };

  return (
    <Box className="container mx-auto p-4">
      <TableContainer component={Paper} className="shadow-lg rounded-lg">
        <Table aria-label="Chats-Tabelle">
          <TableHead className="bg-blue-500 text-white">
            <TableRow>
              <TableCell className="text-white font-bold">Vorname</TableCell>
              <TableCell className="text-white font-bold">Nachname</TableCell>
              <TableCell className="text-white font-bold">E-Mail</TableCell>
              <TableCell className="text-white font-bold"sx={{width:"750px"}}>Zusammenfassung</TableCell>
              <TableCell className="text-white font-bold">Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getPaginatedChats().map((chat) => (
              <TableRow
                key={chat.id}
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => handleRowClick(chat.id)} // Handle row click
              >
                <TableCell className="p-4">{chat.user.firstName}</TableCell>
                <TableCell className="p-4">{chat.user.lastName}</TableCell>
                <TableCell className="p-4">{chat.user.email}</TableCell>
                <TableCell className="p-4">{chat.summary}</TableCell>
                <TableCell className="p-4">{renderFeedbackStars(chat.feedbacks?.[0]?.rating || 0)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={4} className="flex justify-center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          className="bg-white shadow rounded-lg"
        />
      </Box>
    </Box>
  );
};

export default ChatTable;
