import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
  bottom: 0;
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const FooterLink = styled.a`
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const SocialIcon = styled.a`
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
`;

const FooterText = styled.p`
  color: #666;
  font-size: 0.875rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinks>
        <FooterLink href="/impressum">Impressum</FooterLink>
        <FooterLink href="/datenschutz">Datenschutz</FooterLink>
        <FooterLink href="/agb">AGB</FooterLink>
      </FooterLinks>
      <SocialIcons>
        <SocialIcon href="https://www.facebook.com" aria-label="Facebook">
          <FaFacebook />
        </SocialIcon>
        <SocialIcon href="https://www.twitter.com" aria-label="Twitter">
          <FaTwitter />
        </SocialIcon>
        <SocialIcon href="https://www.linkedin.com" aria-label="LinkedIn">
          <FaLinkedin />
        </SocialIcon>
      </SocialIcons>
      <FooterText>&copy; {new Date().getFullYear()} blub.ai. All rights reserved.</FooterText>
    </FooterContainer>
  );
};

export default Footer;
