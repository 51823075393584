import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const SectionContainer = styled.section`
  padding: 80px 20px;
  background-color: #f9fafb;  /* Hellgrauer Hintergrund */
  text-align: center;
`;
const QuestionsHead = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
  color: #1f2937;  /* Dunkelgrauer Text für Klarheit und Kontrast */
  
  @media (max-width: 768px) {
    font-size: 2rem;  /* Verkleinern auf mobilen Geräten */
  }
`;

const QuestionsTextHeader = styled.h2`

`;

const QuestionsText = styled.p`
  padding: px 20px;
  background-color: rgb(0 0 0 / 1%);
  color: #4b5563;  /* Dezent dunkler Text für gute Lesbarkeit */
  font-size: 1.2rem;
  line-height: 1.75;
  text-align: center;
  max-width: 900px;
  margin: 0 auto;  /* Zentriert den Text */
  
  @media (max-width: 768px) {
    padding: 20px;
    font-size: 1.1rem;
  }
`;

const Continue = styled.section`
padding: 80px 20px;
background-color: #f9fafb;  /* Hellgrauer Hintergrund */
text-align: center;
`;

const Title = styled(motion.h2)`
  font-size: 2.5rem;
  color: #1f2937; /* Dunkelgrauer Text für den Titel */
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 30px;
  }
`;

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  justify-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const StepCard = styled(motion.div)`
  background-color: rgba(0,0,0,0.01);
  color: #1f2937;
  padding: 30px;
  border-radius: 16px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05), 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StepIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 20px;
  color: #3b82f6;
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  color: #3b82f6;
  margin-bottom: 20px;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #6b7280;
  line-height: 1.5;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const animationVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
  hover: { scale: 1.05 },
};

const StepsToStart = () => {
  return (
    <SectionContainer>
      <Title
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
      >
        Ihr Weg zu Ihrem individuellen <br></br>Telefonassistenten
      </Title>

      <StepsWrapper>

        
        <StepCard
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          transition={{ duration: 0.3 }}
        >
          <StepIcon>📅</StepIcon>
          <StepTitle>Anmelden</StepTitle>
          <StepDescription>
            Beginnen Sie noch heute Ihre 14-tägige kostenlose Testphase und nutzen Sie alle Vorteile des Telefonassistenten
            einen Monat lang völlig unverbindlich.
          </StepDescription>
        </StepCard>

        <StepCard
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <StepIcon>💬</StepIcon>
          <StepTitle>Einrichten</StepTitle>
          <StepDescription>
            Sofort nach dem On-Boarding können Sie selbständig durchstarten: Konfigurieren Sie in unter einer Stunde Ihren
            eigenen Telefonassistenten – ganz ohne technische Vorkenntnisse.
          </StepDescription>
        </StepCard>

        <StepCard
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          transition={{ duration: 0.3, delay: 0.4 }}
        >
          <StepIcon>🚀</StepIcon>
          <StepTitle>Loslegen</StepTitle>
          <StepDescription>
            Genießen Sie die Ruhe und arbeiten Sie endlich wieder konzentriert, ohne vom ständigen Telefonklingeln gestört
            zu werden.
          </StepDescription>
        </StepCard>
      </StepsWrapper>

      <ButtonWrapper>
        <Link to="/demo">
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={motion.button}
            whileHover={{ scale: 1.02 }}
            sx={{ padding: '12px 24px', fontSize: '1.1rem' }}
          >
            14 tägigen Zugang anfordern
          </Button>
        </Link>

        
      </ButtonWrapper>
      <Continue>
  <QuestionsHead>Haben Sie noch Fragen?<br /></QuestionsHead>
  <QuestionsText>
    Möchten Sie mehr darüber erfahren, wie blub.ai Ihr Unternehmen unterstützen kann? Buchen Sie jetzt ein unverbindliches Gespräch mit einem unserer Experten und entdecken Sie, wie Sie mit blub.ai Ihre Telefonanfragen automatisieren und die Effizienz Ihres Kundenservices auf das nächste Level heben.
  </QuestionsText>
  <Link to="/contact">
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={motion.button}
            whileHover={{ scale: 1.02 }}
            sx={{ padding: '12px 24px', fontSize: '1.1rem', marginTop: "40px"}}
          >
            Zum Kontaktformular
          </Button>
        </Link>
</Continue>

    </SectionContainer>
  );
};

export default StepsToStart;
