import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../axiosConfig';  // Importiere die Axios-Instanz
import Snackbar from '@mui/material/Snackbar'; // Snackbar-Komponente importieren
import Alert from '@mui/material/Alert'; // Alert-Komponente importieren

const ContactContainer = styled.section`
  padding: 100px 20px;
  background-color: #f4f4f4;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const ContactTitle = styled(motion.h2)`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #2b2b2b;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const ContactFormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 60px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContactInput = styled.input`
  width: 100%;
  padding: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #0070f3;
    outline: none;
  }
`;

const ContactTextArea = styled.textarea`
  width: 100%;
  padding: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  resize: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #0070f3;
    outline: none;
  }
`;

const ContactButton = styled.button`
  padding: 15px 40px;
  background-color: #0070f3;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005bb5;
  }
`;

const ContactSubtitle = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 40px;
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [formDisabled, setFormDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post('/send-mail', {
        subject: `Kontaktanfrage von ${formData.name}`,
        message: `Name: ${formData.name}\nE-Mail: ${formData.email}\nTelefon: ${formData.phone}\n\nNachricht:\n${formData.message}`,
      });

      if (response.status === 200) {
        setSnackbar({ open: true, message: 'Nachricht erfolgreich gesendet!', severity: 'success' });
        // Nach erfolgreichem Versand das Formular zurücksetzen und deaktivieren
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
        setFormDisabled(true); // Formular deaktivieren
      } else {
        setSnackbar({ open: true, message: 'Fehler beim Senden der Nachricht.', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Es ist ein Fehler aufgetreten.', severity: 'error' });
      console.error('Error sending email:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ContactContainer id="contact">
      <Helmet>
        <title>blub.ai - Kontakt</title>
        <meta name="description" content="Kontaktieren Sie uns für weitere Informationen oder Support. Das blub.ai-Team ist für Sie da." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.blub.ai/contact" />
      </Helmet>

      <ContactTitle
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Kontaktieren Sie uns
      </ContactTitle>
      <ContactSubtitle>Wir freuen uns, von Ihnen zu hören. Füllen Sie das Formular aus, um uns eine Nachricht zu senden.</ContactSubtitle>
      <ContactFormContainer>
        <ContactForm onSubmit={handleSubmit}>
          <ContactInput
            type="text"
            placeholder="Ihr Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            disabled={formDisabled} // Deaktiviert nach dem Absenden
          />
          <ContactInput
            type="email"
            placeholder="Ihre E-Mail"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            disabled={formDisabled} // Deaktiviert nach dem Absenden
          />
          <ContactInput
            type="tel"
            placeholder="Ihre Telefonnummer"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            disabled={formDisabled} // Deaktiviert nach dem Absenden
          />
          <ContactTextArea
            rows="5"
            placeholder="Ihre Nachricht"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            disabled={formDisabled} // Deaktiviert nach dem Absenden
          />
          <ContactButton type="submit" disabled={loading || formDisabled}>
            {loading ? 'Senden...' : 'Nachricht senden'}
          </ContactButton>
        </ContactForm>
        {responseMessage && <p>{responseMessage}</p>}
      </ContactFormContainer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Snackbar prominent zentrieren
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ fontSize: '1.2rem' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Footer />
    </ContactContainer>
  );
};

export default Contact;
