import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, Dialog, DialogContent, DialogActions, IconButton, Stepper, Step, StepLabel, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../../axiosConfig';

const AITutorial = ({ open, onClose }) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const [attributes, setAttributes] = useState([]);
  const [formData, setFormData] = useState({
    attributes: ['', '', '', '', ''],
    questions: ['', '', '', '', ''],
    additionalQuestions: '',
    companyInfo: '',
    tone: '',
  });

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await axiosInstance.get('/attributes');
        setAttributes(response.data.filter(attribute => attribute.is_active));  // Filter active attributes
        setFormData((prevData) => ({
          ...prevData,
          attributes: response.data.filter(attribute => attribute.is_active).map(attr => attr.attribute_name),
        }));
      } catch (error) {
        console.error('Error fetching active attributes:', error);
      }
    };

    fetchAttributes();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleArrayChange = (index, value, field) => {
    setFormData((prevData) => {
      const newArray = [...prevData[field]];
      newArray[index] = value;
      return { ...prevData, [field]: newArray };
    });
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/generate_prompt', formData);
      const generatedPrompt = response.data.generated_text;
      setGeneratedPrompt(generatedPrompt);
    } catch (error) {
      console.error('Error generating prompt:', error);
    }
    setLoading(false);
  };

  const steps = [
    'Attribute wählen',
    'Fragen spezifizieren',
    'Zusätzliche Fragen',
    'Unternehmensinformationen',
    'Ton des Gesprächs',
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">AI-Tutorial Einrichtung</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={2}>
          {step === 0 && (
            <Box>
              <Typography variant="subtitle1">Welche Attribute sollen abgefragt werden?</Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {formData.attributes.map((attr, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      fullWidth
                      label={`Attribut ${index + 1}`}
                      value={attr}
                      onChange={(e) => handleArrayChange(index, e.target.value, 'attributes')}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {step === 1 && (
            <Box>
              <Typography variant="subtitle1">Sollen für die Attribute spezielle Fragen gestellt werden?</Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {formData.attributes.map((attr, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      fullWidth
                      label={`Frage für ${attr}`}
                      value={formData.questions[index]}
                      onChange={(e) => handleArrayChange(index, e.target.value, 'questions')}
                      placeholder={`Beispiel: Was ist Ihre ${attr}?`}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {step === 2 && (
            <Box>
              <Typography variant="subtitle1">Sollen zusätzliche Fragen oder Aussagen gestellt werden?</Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                name="additionalQuestions"
                value={formData.additionalQuestions}
                onChange={handleChange}
              />
            </Box>
          )}
          {step === 3 && (
            <Box>
              <Typography variant="subtitle1">Unternehmensinformationen, Öffnungszeiten, wichtige Informationen</Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                name="companyInfo"
                value={formData.companyInfo}
                onChange={handleChange}
              />
            </Box>
          )}
          {step === 4 && (
            <Box>
              <Typography variant="subtitle1">In welchem Ton soll das Gespräch gehalten werden?</Typography>
              <TextField
                fullWidth
                name="tone"
                value={formData.tone}
                onChange={handleChange}
                placeholder="Freundlich, Kumpelhaft, Professionell"
              />
            </Box>
          )}
          {loading && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}
          {generatedPrompt && (
            <Box mt={4} p={2} bgcolor="lightgrey" borderRadius={4}>
              <Typography variant="subtitle1">Generierter Prompt:</Typography>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>{generatedPrompt}</Typography>
              <Typography variant="body2" color="textSecondary" mt={2}>Bitte kopieren Sie diesen Prompt, da er nicht automatisch gespeichert wird.</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {step > 0 && <Button onClick={handleBack}>Zurück</Button>}
        {step < steps.length - 1 && <Button onClick={handleNext}>Weiter</Button>}
        {step === steps.length - 1 && (
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? 'Generiert...' : 'Fertig'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AITutorial;
