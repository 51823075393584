import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, CircularProgress, Box } from '@mui/material';
import axiosInstance from '../axiosConfig';

const SmsDetailsForm = ({ conversationId, open, onClose }) => {
  const [smsDetails, setSmsDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open && conversationId) {
      const fetchSmsDetails = async () => {
        try {
          const response = await axiosInstance.get(`/sent-sms/${conversationId}`);
          setSmsDetails(response.data);
        } catch (error) {
          console.error('Error fetching SMS details:', error);
        } finally {
          setLoading(false); // Ensure loading is set to false in the finally block
        }
      };

      fetchSmsDetails();
    }
  }, [open, conversationId]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>SMS Details</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : smsDetails.length > 0 ? (
          smsDetails.map((sms, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="body1">
                <strong>Empfänger:</strong> {sms.recipient || 'Unbekannt'}
              </Typography>
              <Typography variant="body2">
                <strong>Nachricht:</strong> {sms.content}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body2">Keine SMS Details gefunden.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SmsDetailsForm;
