import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactAudioPlayer from 'react-h5-audio-player';
import axiosInstance from '../axiosConfig';
import 'react-h5-audio-player/lib/styles.css';

const CustomAudioPlayer = ({ conversationId }) => {
  const [audioSrc, setAudioSrc] = useState('');
  const [isInView, setIsInView] = useState(false);
  const playerRef = useRef(null);
  const observerRef = useRef(null);
  const containerRef = useRef(null);

  const fetchAudioSrc = useCallback(async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage
      const response = await axiosInstance.get(`/audio/${conversationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // Ensure the response is a blob
      });

      // Create a URL for the audio file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setAudioSrc(url);
    } catch (error) {
      console.error('Error fetching audio file', error);
    }
  }, [conversationId]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.audio.current.pause();
    }
  }, [audioSrc]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (isInView && !audioSrc) {
      fetchAudioSrc();
    }
  }, [isInView, audioSrc, fetchAudioSrc]);

  return (
    <div ref={containerRef}>
      <ReactAudioPlayer
        ref={playerRef}
        src={audioSrc}
        autoPlay={false}
        controls
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default CustomAudioPlayer;
