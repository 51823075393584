import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import axiosInstance from '../../axiosConfig';  // Axios-Instanz importieren
import FormSteps from '../../components/DemoFormSteps';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import Snackbar from '@mui/material/Snackbar'; // Importiere Snackbar-Komponente
import Alert from '@mui/material/Alert'; // Importiere Alert-Komponente

const DemoContainer = styled.section`
  padding: 100px 20px;
  background-color: #f9fafb;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackgroundGradient = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(34, 193, 195, 0.3), rgba(255, 187, 45, 0.3));
  z-index: -1;
`;

const FormWrapper = styled.div`
  background-color: #ffffff;
  padding: 60px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin-bottom: 50px;
`;

const GradientCircle = styled.div`
  position: absolute;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, rgba(34, 193, 195, 0.4), rgba(255, 187, 45, 0.2));
  border-radius: 50%;
`;

const Heading = styled.h2`
  font-size: 3rem;
  color: #111827;
  margin-bottom: 20px;
  font-weight: 700;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #6b7280;
  margin-bottom: 40px;
`;

const DemoRequestForm = () => {
  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState(0);
  const [formData, setFormData] = useState({
    companyName: '',
    contactPerson: '',
    email: '',
    phone: '',
    industry: '',
    employees: '',
    currentSystem: '',
    monthlyHours: '',
    additionalRequirements: '',
    comments: '',
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [formDisabled, setFormDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNext = () => {
    setDirection(1);
    setStep(step + 1);
  };

  const handlePrev = () => {
    setDirection(-1);
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post('/send-mail', {
        subject: `Demo-Anfrage von ${formData.contactPerson}`,
        message: `
          Unternehmen: ${formData.companyName}
          Kontaktperson: ${formData.contactPerson}
          E-Mail: ${formData.email}
          Telefon: ${formData.phone}
          Branche: ${formData.industry}
          Mitarbeiter: ${formData.employees}
          Aktuelles System: ${formData.currentSystem}
          Monatliche Stunden: ${formData.monthlyHours}
          Zusätzliche Anforderungen: ${formData.additionalRequirements}
          Kommentare: ${formData.comments}
        `
      });

      if (response.status === 200) {
        setSnackbar({ open: true, message: 'Anfrage erfolgreich gesendet!', severity: 'success' });
        // Nach erfolgreichem Versand Formular leeren und deaktivieren
        setFormData({
          companyName: '',
          contactPerson: '',
          email: '',
          phone: '',
          industry: '',
          employees: '',
          currentSystem: '',
          monthlyHours: '',
          additionalRequirements: '',
          comments: '',
        });
        setFormDisabled(true);  // Formular deaktivieren, um Spam zu verhindern
      } else {
        setSnackbar({ open: true, message: 'Fehler beim Senden der Anfrage.', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Es ist ein Fehler aufgetreten.', severity: 'error' });
      console.error('Error submitting form', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <DemoContainer>
      <Helmet>
        <title>blub.ai - Demo anfordern</title>
        <meta name="description" content="Fordern Sie eine kostenlose Demo von blub.ai an und erleben Sie die Leistungsfähigkeit des virtuellen Telefonassistenten." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.blub.ai/demo" />
      </Helmet>

      <BackgroundGradient />
      <GradientCircle style={{ top: '10%', left: '-20%' }} />
      <GradientCircle style={{ bottom: '10%', right: '-20%' }} />

      <FormWrapper>
        <Heading>Demo/Angebot anfordern</Heading>
        <Description>Füllen Sie das Formular aus, um eine Demo oder ein Angebot anzufordern.</Description>
        
        <form onSubmit={handleSubmit}>
          <AnimatePresence mode="wait" custom={direction}>
            <FormSteps step={step} handleChange={handleChange} formData={formData} direction={direction} />
          </AnimatePresence>
          
          <div className="flex justify-between mt-6">
  {step > 1 && (
    <button
      type="button"
      className="px-4 py-2 text-base font-semibold text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none"
      onClick={handlePrev}
      disabled={formDisabled}  // Deaktiviert nach dem Absenden
    >
      Zurück
    </button>
  )}

  {step < 4 ? (
    <button
      type="button"
      className="px-4 py-2 ml-auto text-base font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
      onClick={handleNext}
      disabled={formDisabled}  // Deaktiviert nach dem Absenden
    >
      Weiter
    </button>
  ) : (
    <button
      type="button"  // Typ auf "button" setzen, um kein automatisches Submit auszulösen
      className="px-4 py-2 ml-auto text-base font-semibold text-white bg-green-600 rounded-md hover:bg-green-500 focus:outline-none"
      onClick={handleSubmit}  // handleSubmit manuell aufrufen
      disabled={loading || formDisabled}  // Deaktiviert nach dem Absenden oder während des Ladens
    >
      {loading ? 'Absenden...' : 'Absenden'}
    </button>
  )}
</div>

        </form>
      </FormWrapper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  // Snackbar prominent in der Mitte des Bildschirms
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ fontSize: '1.2rem' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      <Footer />  
    </DemoContainer>
  );
};

export default DemoRequestForm;
