import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import axiosInstance from '../axiosConfig';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    conversations: [],
    averageTokenUsage: [],
    totalTokenUsage: {},
    averageConversationDuration: [],
    totalConversationDuration: {},
    totalConversationsPerCustomer: {},
    customers: []
  });
  const [customerColors, setCustomerColors] = useState({});

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await axiosInstance.get('/admin/global-statistics?days=90');
      const normalizedData = normalizeData(response.data);
      setStats(normalizedData);
      setCustomerColors(generateColors(normalizedData.customers));
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const normalizeData = (data) => {
    const defaultItem = { time: '', total: 0 };
    const customers = data.customers.map(customer => customer.name);

    const normalizeArray = (array, defaultValue) => {
      return array.map(item => ({
        ...defaultValue,
        ...item,
        ...customers.reduce((acc, customer) => ({
          ...acc,
          [customer]: item[customer] || 0
        }), {})
      }));
    };

    const normalizedData = { ...data };
    normalizedData.conversations = normalizeArray(data.conversations, defaultItem);
    normalizedData.averageTokenUsage = normalizeArray(data.averageTokenUsage, defaultItem);
    normalizedData.averageConversationDuration = normalizeArray(data.averageConversationDuration, defaultItem);

    normalizedData.totalConversationDuration = Object.keys(data.totalConversationDuration).reduce((acc, key) => {
      acc[key] = secondsToHms(data.totalConversationDuration[key]);
      return acc;
    }, {});

    return normalizedData;
  };

  const generateColors = (customers) => {
    const colors = {};
    customers.forEach(customer => {
      colors[customer.name] = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    });
    return colors;
  };

  const secondsToHms = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 3600 % 60);

    const hDisplay = h > 0 ? h + "h " : "";
    const mDisplay = m > 0 ? m + "m " : "";
    const sDisplay = s > 0 ? s + "s" : "";
    return hDisplay + mDisplay + sDisplay || "0s";
  };

  const renderChart = (title, dataKey, data, totalData) => (
    <Grid item xs={12}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="total" stroke="#8884d8" />
            {stats.customers.map((customer) => (
              <Line
                key={customer.id}
                type="monotone"
                dataKey={customer.name}
                stroke={customerColors[customer.name]}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
        <Box mt={2}>
          <Typography variant="subtitle1">Gesamte {title.split(' ')[1]}</Typography>
          {stats.customers.map(customer => (
            <Typography key={customer.id} variant="body2">
              {customer.name}: {totalData[customer.name] || 0}
            </Typography>
          ))}
        </Box>
      </Paper>
    </Grid>
  );

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        {renderChart('Geführte Konversationen', 'total', stats.conversations, stats.totalConversationsPerCustomer)}
        {renderChart('Durchschnittliche Token-Nutzung pro Gespräch', 'averageTokenUsage', stats.averageTokenUsage, stats.totalTokenUsage)}
        {renderChart('Durchschnittliche Gesprächsdauer pro Gespräch', 'averageConversationDuration', stats.averageConversationDuration, stats.totalConversationDuration)}
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
