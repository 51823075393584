import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Send, Email, Sms, Cancel, CheckCircle } from '@mui/icons-material';
import axiosInstance from '../axiosConfig';

const ConversationActions = ({ conversation, open, onClose, onMarkAsDone }) => {
  const [responseMethod, setResponseMethod] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(conversation.phone_number || '');
  const [phoneError, setPhoneError] = useState(false);
  const [previewMessage, setPreviewMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedTemplate) {
      const fetchTemplatePreview = async () => {
        try {
          const response = await axiosInstance.get(`/template-preview/${selectedTemplate}`, {
            params: { conversation_id: conversation.conversation_id },
          });
          setPreviewMessage(response.data.message);
        } catch (error) {
          console.error('Fehler beim Abrufen der Vorlagenvorschau:', error);
        }
      };

      fetchTemplatePreview();
    }
  }, [selectedTemplate, conversation.conversation_id]);

  useEffect(() => {
    if (responseMethod === 'sms') {
      const fetchTemplates = async () => {
        try {
          const response = await axiosInstance.get('/get-personal-templates', {
            params: { customer_id: conversation.customer_id },
          });
          setTemplates(response.data);
        } catch (error) {
          console.error('Fehler beim Abrufen der Vorlagen:', error);
        }
      };

      fetchTemplates();
    }
  }, [responseMethod, conversation.customer_id]);

  const handleSendSms = async () => {
    setLoading(true);
    try {
      await axiosInstance.post('/send-sms', {
        template_id: selectedTemplate,
        recipient: phoneNumber,
        conversation_id: conversation.conversation_id,
      });

      // Mark the conversation as done after sending SMS
      await handleMarkAsDone();
    } catch (error) {
      console.error('Fehler beim Senden der SMS:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      // Implement email sending logic here
      // ...

      // Mark the conversation as done after sending email
      await handleMarkAsDone();
    } catch (error) {
      console.error('Fehler beim Senden der E-Mail:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNoResponse = async () => {
    setLoading(true);
    try {
      // Mark the conversation as done without sending any message
      await handleMarkAsDone();
    } catch (error) {
      console.error('Fehler beim Markieren ohne Antwort:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkAsDone = async () => {
    try {
      await axiosInstance.post(`/summary/${conversation.conversation_id}/mark_done`);
      onMarkAsDone(conversation.conversation_id);
      onClose();
    } catch (error) {
      console.error('Fehler beim Markieren der Konversation als erledigt:', error);
    }
  };

  const handleActionComplete = async () => {
    if (responseMethod === 'sms' && !/^\+\d{1,3}\s?\d{1,14}(\s?\d{1,13})?$/.test(phoneNumber)) {
      setPhoneError(true);
      return;
    }
    setPhoneError(false);

    switch (responseMethod) {
      case 'sms':
        await handleSendSms();
        break;
      case 'email':
        await handleSendEmail();
        break;
      case 'no-response':
        await handleNoResponse();
        break;
      default:
        break;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>Wählen Sie eine Antwortmethode</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Antwortmethode</InputLabel>
          <Select
            value={responseMethod}
            onChange={(e) => setResponseMethod(e.target.value)}
            label="Antwortmethode"
            startAdornment={
              responseMethod === 'sms' ? (
                <Sms sx={{ mr: 1 }} />
              ) : responseMethod === 'email' ? (
                <Email sx={{ mr: 1 }} />
              ) : (
                ''
              )
            }
          >
            <MenuItem value="sms">per SMS</MenuItem>
            <MenuItem value="email">per E-Mail</MenuItem>
            <MenuItem value="no-response">Keine Antwort</MenuItem>
          </Select>
        </FormControl>
        {responseMethod === 'sms' && (
          <>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Vorlage auswählen</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
                label="Vorlage auswählen"
              >
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Telefonnummer"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              error={phoneError}
              helperText={phoneError ? 'Das Format der Telefonnummer muss +XX XXX XXXXXX sein' : ''}
              sx={{ mb: 2 }}
            />
            {selectedTemplate && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1">Nachrichtenvorschau:</Typography>
                <Typography variant="body2" sx={{ mt: 1, p: 2, backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
                  {previewMessage}
                </Typography>
              </Box>
            )}
          </>
        )}
        {responseMethod === 'email' && (
          <Typography sx={{ mb: 2, textAlign: 'center' }}>
            Die E-Mail Funktionalität ist derzeit nicht verfügbar.
          </Typography>
        )}
        {responseMethod === 'no-response' && (
          <Typography sx={{ mb: 2, textAlign: 'center' }}>
            Es wird keine Antwort gesendet.
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose} color="secondary" startIcon={<Cancel />} disabled={loading}>
          Abbrechen
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleActionComplete}
          startIcon={loading ? <CircularProgress size={20} /> : <Send />}
          disabled={loading}
        >
          {loading ? 'Wird gesendet...' : 'Bestätigen'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConversationActions;
