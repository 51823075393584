import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axiosInstance from '../../axiosConfig';

const AttributeSettings = () => {
  const [attributes, setAttributes] = useState([]);
  const [attributeName, setAttributeName] = useState('');
  const [displayOrder, setDisplayOrder] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editAttributeId, setEditAttributeId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchAttributes();
  }, []);

  const fetchAttributes = async () => {
    try {
      const response = await axiosInstance.get('/attributes');
      setAttributes(response.data);
    } catch (error) {
      console.error('Error fetching attributes:', error);
    }
  };

  const handleAddAttribute = async () => {
    if (attributeName && displayOrder) {
      try {
        await axiosInstance.post('/attributes', {
          attribute_name: attributeName,
          display_order: displayOrder,
        });
        setAttributeName('');
        setDisplayOrder('');
        fetchAttributes();
      } catch (error) {
        console.error('Error adding attribute:', error);
      }
    }
  };

  const handleEditAttribute = async () => {
    if (attributeName && displayOrder && editAttributeId !== null) {
      try {
        await axiosInstance.put(`/attributes/${editAttributeId}`, {
          attribute_name: attributeName,
          display_order: displayOrder,
        });
        setAttributeName('');
        setDisplayOrder('');
        setEditMode(false);
        setEditAttributeId(null);
        fetchAttributes();
      } catch (error) {
        console.error('Error editing attribute:', error);
      }
    }
  };

  const handleDeleteAttribute = async (attributeId) => {
    try {
      await axiosInstance.delete(`/attributes/${attributeId}`);
      fetchAttributes();
    } catch (error) {
      console.error('Error deleting attribute:', error);
    }
  };

  const handleEditClick = (attribute) => {
    setAttributeName(attribute.attribute_name);
    setDisplayOrder(attribute.display_order);
    setEditMode(true);
    setEditAttributeId(attribute.attribute_id);
  };

  const handleToggleAttribute = async (attribute) => {
    const isActive = !attribute.is_active;
    const activeCount = attributes.filter(attr => attr.is_active).length;

    if (isActive && activeCount >= 5) {
      alert('You can only activate up to 5 attributes at a time.');
      return;
    }

    try {
      await axiosInstance.post(`/attributes/${attribute.attribute_id}/toggle`, { is_active: isActive });
      fetchAttributes();
    } catch (error) {
      console.error('Error toggling attribute:', error);
    }
  };

  const filteredAttributes = attributes.filter(attribute =>
    attribute.attribute_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box>
      <Typography variant="h4" component="h2" gutterBottom>
        Attribute Settings
      </Typography>
      <TextField
        label="Search Attributes"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Paper sx={{ p: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Attribute Name"
            value={attributeName}
            onChange={(e) => setAttributeName(e.target.value)}
            fullWidth
          />
          <TextField
            label="Display Order"
            value={displayOrder}
            onChange={(e) => setDisplayOrder(e.target.value)}
            fullWidth
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {editMode ? (
            <Button variant="contained" color="primary" onClick={handleEditAttribute}>
              Save Changes
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleAddAttribute}>
              Add Attribute
            </Button>
          )}
        </Box>
      </Paper>
      <TableContainer component={Paper}>
        <Table aria-label="attributes table">
          <TableHead>
            <TableRow>
              <TableCell>Attribute Name</TableCell>
              <TableCell>Display Order</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAttributes.map((attribute) => (
              <TableRow key={attribute.attribute_id}>
                <TableCell>{attribute.attribute_name}</TableCell>
                <TableCell>{attribute.display_order}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={attribute.is_active}
                    onChange={() => handleToggleAttribute(attribute)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(attribute)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteAttribute(attribute.attribute_id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AttributeSettings;
