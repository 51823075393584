import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bxHome from '@iconify-icons/bx/bx-home';
import bxGridAlt from '@iconify-icons/bx/bx-grid-alt';
import bxUser from '@iconify-icons/bx/bx-user';
import bxLogOut from '@iconify-icons/bx/bx-log-out';
import bxMenu from '@iconify-icons/bx/bx-menu';
import bxDemo from '@iconify-icons/bx/bx-briefcase-alt-2';
import { supabase } from '../supabaseClient';

const Header = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const LogoImage = styled.img`
  height: 40px; /* Adjust the height as needed */
  width: auto;
  object-fit: contain;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
  flex-grow: 2;
  justify-content: flex-end;

  @media (max-width: 768px) {
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
`;

const NavLink = styled(Link)`
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const Button = styled(Link)`
  padding: 10px 20px;
  margin-left: 30px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    justify-content: center;
  }
`;

const LogoutButton = styled.button`
  padding: 10px 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c9302c;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    justify-content: center;
  }
`;

const MenuToggle = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`;

const ModernHeader = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const token = localStorage.getItem('token');

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      localStorage.removeItem('token');
      navigate('/login'); // Redirect user to the login page on successful logout
    }
  };

  return (
    <Header>
      <Nav>
        <Logo to="/">
          <LogoImage src="https://img.ashub.de/blub-ai/blubbb.png" alt="Logo" />
        </Logo>
        <MenuToggle onClick={() => setIsOpen(!isOpen)}>
          <Icon icon={bxMenu} width="30" height="30" />
        </MenuToggle>
        <NavLinks isOpen={isOpen}>
          <NavLink to="/"><Icon icon={bxHome} /> Home</NavLink>
          <NavLink to="/features"><Icon icon={bxGridAlt} /> Funktionen</NavLink>
          <NavLink to="/contact"><Icon icon={bxUser} /> Kontakt</NavLink>
          <NavLink to="/demo"><Icon icon={bxDemo} /> Demo/Angebot</NavLink>
          <Button to="/dashboard"><Icon icon={bxGridAlt} /> Dashboard</Button>
          {token && (
            <LogoutButton onClick={handleLogout}>
              <Icon icon={bxLogOut} /> Abmelden
            </LogoutButton>
          )}
        </NavLinks>
      </Nav>
    </Header>
  );
};

export default ModernHeader;
