import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

const pricingOptions = [
  {
    title: 'Basis',
    description: 'Ideal für kleine Unternehmen, die eine kostengünstige und effiziente Telefonieunterstützung benötigen.',
    monthlyPrice: '0.19€ pro Konversation',
    annualPrice: '-',
    details: [
      'Automatische Anrufannahme',
      'Live-Transkription',
      'Intelligente Gesprächsführung',
      'Integration per SIP oder Anrufweiterleitung',
    ],
  },
  {
    title: 'Pro',
    description: 'Perfekt für mittelständische Unternehmen mit höherem Anrufaufkommen und mehreren Nutzern.',
    monthlyPrice: '99€ / Agent',
    annualPrice: '69€ / Agent',
    details: [
      'Unendlich viele Konversationen',
      'Mehrere Benutzer und Mandanten',
      'Priorisierter Support',
      'Echtzeit-Analysen',
      'Antworten per SMS',
    ],
  },
  {
    title: 'Enterprise',
    description: 'Optimal für große Unternehmen, die maßgeschneiderte Lösungen und erstklassigen Support benötigen.',
    price: 'Kontaktieren Sie uns',
    details: [
      'Dedizierter Support inklusive Telefonsupport',
      'Individuelle Integration',
    ],
  },
];

const Pricing = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [billingCycle, setBillingCycle] = useState('monthly');

  const handleSelect = (index) => {
    setCurrentIndex(index);
  };

  const toggleBillingCycle = (cycle) => {
    setBillingCycle(cycle);
  };

  const springProps = useSpring({
    opacity: 1,
    transform: 'scale(1)',
    config: { mass: 1, tension: 280, friction: 60 },
  });

  return (
    <section id="pricing" className="py-10 bg-gray-50 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Preisgestaltung</h2>
          <p className="max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600">Flexible Preismodelle, die sich an Ihre geschäftlichen Anforderungen anpassen.</p>
          
        </div>
        <div className="relative mt-8 lg:mt-16 h-auto">
          <div className="flex flex-col lg:flex-row justify-center items-center h-full space-y-8 lg:space-y-0 lg:space-x-4">
            {pricingOptions.map((option, index) => (
              <animated.div
                key={index}
                style={{
                  ...springProps,
                  opacity: currentIndex === index ? 1 : 0.5,
                  transform: currentIndex === index ? 'scale(1)' : 'scale(0.9)',
                }}
                className={`relative p-6 bg-white border border-gray-200 rounded-lg shadow-lg mx-4 cursor-pointer transition-transform duration-300 ease-in-out transform ${
                  currentIndex === index ? 'z-10 scale-105 shadow-xl' : 'z-0 scale-95'
                }`}
                onClick={() => handleSelect(index)}
              >
                {index === 1 && (
                  <div className="absolute top-0 right-0 px-2 py-1 bg-blue-600 text-white text-xs font-bold uppercase rounded-bl-lg">
                    Beliebt
                  </div>
                )}
                <h3 className="text-xl font-semibold text-gray-900">{option.title}</h3>
                <p className="mt-4 text-lg text-gray-600">{option.description}</p>
                {option.price ? (
                  <p className="mt-6 text-2xl font-bold text-gray-900">{option.price}</p>
                ) : (
                  <p className="mt-4 text-2xl font-bold text-gray-900">
                    {billingCycle === 'monthly' ? option.monthlyPrice : option.annualPrice}
                    {option.annualPrice && (
                      <span className="block text-base text-gray-700">{billingCycle === 'monthly' ? 'pro Monat' : 'pro Jahr'}</span>
                    )}
                  </p>
                )}
                <ul className="mt-4 space-y-2">
                <span className="block mt-2 text-base font-bold text-gray-700">
                  {index - 1 !== -1 && pricingOptions[index - 1]?.title + " +"} 
                </span>

                  {option.details.map((detail, idx) => (
                    <li key={idx} className="text-gray-600 flex items-center">
                      <svg
                        className="w-5 h-5 text-blue-600 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      {detail}
                    </li>
                  ))}
                </ul>
                <Link to="/demo">
                  <button className="mt-8 w-full px-4 py-2 text-base font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    {option.title === 'Enterprise' ? 'Kontaktieren Sie uns' : 'Jetzt beginnen'}
                  </button>
                </Link>
              </animated.div>
            ))}
          </div>
        </div>
        <div class="mt-6 text-center">
  <span class="text-sm text-gray-500 italic">
    Alle angegebenen Preise verstehen sich exklusive Steuern. Der Betrag wird zu Beginn des Abrechnungszeitraums fällig. Falls das monatliche Gesprächskontingent überschritten wird, erfolgt die Berechnung des zusätzlichen Betrags rückwirkend für den jeweiligen Monat. Bei Fragen stehen wir Ihnen gerne zur Verfügung.
  </span>
</div>

        <div className="mt-8 text-center">
          <Link to="/compare" className="inline-block text-blue-600 underline text-lg font-semibold">
            Vergleichen Sie alle Pakete
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
