import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';

const CreateStatementDialog = ({ open, onClose, onCreate }) => {
  const [statement, setStatement] = useState('');
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);

  const handleCreate = async () => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('text', statement);

      const response = await axios.post('https://tts.blub.ai/generate_audio', formData);
      const { url } = response.data;
      console.log(url);
      setAudioUrl(url);

      // Nachdem die URL gesetzt wurde, können wir den Benutzer benachrichtigen
      onCreate(url);
    } catch (error) {
      console.error('Fehler beim Generieren der Audiodatei:', error);
      //alert('Fehler beim Generieren der Audiodatei.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Neue Ansage erstellen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bitte geben Sie die Ansage ein, die generiert werden soll.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="statement"
          label="Aussage"
          type="text"
          fullWidth
          value={statement}
          onChange={(e) => setStatement(e.target.value)}
        />
        {loading && <CircularProgress />}
        {audioUrl && (
          <audio controls src={audioUrl} style={{ width: '100%', marginTop: '10px' }}>
            Your browser does not support the audio element.
          </audio>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Abbrechen
        </Button>
        <Button onClick={handleCreate} color="primary" disabled={loading || !statement}>
          Erstellen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateStatementDialog;
