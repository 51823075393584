import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography, Snackbar, Alert, Tooltip, Grid, Paper } from '@mui/material';
import axiosInstance from '../axiosConfig';

const CustomerSettings = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [maxApiKeys, setMaxApiKeys] = useState('');
  const [maxAgents, setMaxAgents] = useState('');
  const [plan, setPlan] = useState('');
  const [chatEnabled, setChatEnabled] = useState(false);
  const [maxChatApiKeys, setMaxChatApiKeys] = useState('');
  const [maxEmployees, setMaxEmployees] = useState(''); // Neue State-Variable für maxEmployees
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Fehler beim Laden der Kunden:', error);
    }
  };

  const handleCustomerChange = async (event) => {
    const customerId = event.target.value;
    setSelectedCustomer(customerId);

    try {
      const response = await axiosInstance.get('/admin/customer/settings', {
        params: {
          customer_id: customerId,
        },
      });

      const settings = response.data[0];  // Das erste Element im Array auswählen
      if (settings) {
        setMaxApiKeys(settings.max_api_keys);
        setMaxAgents(settings.max_agents);
        setPlan(settings.plan);
        setChatEnabled(settings.chat_enabled === 1);  // Konvertiere Zahl in boolean
        setMaxChatApiKeys(settings.max_chat_api_keys);
        setMaxEmployees(settings.max_employees);  // Neue Zuweisung für maxEmployees
      } else {
        // Keine Einstellungen gefunden, alles leeren
        clearFields();
      }
    } catch (error) {
      console.error('Fehler beim Laden der Kundeneinstellungen:', error);
      // Bei einem Fehler alles leeren
      clearFields();
    }
  };

  // Funktion zum Leeren aller Eingabefelder
  const clearFields = () => {
    setMaxApiKeys('');
    setMaxAgents('');
    setPlan('');
    setChatEnabled(false);
    setMaxChatApiKeys('');
    setMaxEmployees('');  // Leere maxEmployees
  };

  const handleSaveSettings = async () => {
    if (!selectedCustomer) {
      setSnackbarMessage('Bitte wählen Sie einen Kunden aus.');
      setOpenSnackbar(true);
      return;
    }

    try {
      await axiosInstance.put('/admin/customer/settings', {
        customer_id: selectedCustomer,  // Kunde, der aktualisiert wird
        max_api_keys: maxApiKeys,
        max_agents: maxAgents,
        plan,
        chat_enabled: chatEnabled,
        max_chat_api_keys: maxChatApiKeys,
        max_employees: maxEmployees,  // Speichere maxEmployees
      });
      setSnackbarMessage('Einstellungen erfolgreich gespeichert.');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Speichern der Einstellungen:', error);
      setSnackbarMessage('Fehler beim Speichern der Einstellungen.');
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box className="p-4 w-full flex flex-col items-center">
      <Typography variant="h4" gutterBottom>
        Kundenverwaltung
      </Typography>
      
      <Paper elevation={3} className="p-6 mb-6 w-full max-w-4xl">
        <FormControl fullWidth className="mb-4">
          <InputLabel>Kunde auswählen</InputLabel>
          <Select value={selectedCustomer} onChange={handleCustomerChange} label="Kunde auswählen">
            {customers.map((customer) => (
              <MenuItem key={customer.id} value={customer.id}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedCustomer && (
          <Box className="mt-4">
            <Typography variant="h6" className="mb-4">
              Kundeneinstellungen
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Tooltip title="Maximale Anzahl an API-Schlüsseln, die dieser Kunde verwenden darf">
                  <TextField
                    fullWidth
                    label="Max API-Schlüssel"
                    value={maxApiKeys}
                    onChange={(e) => setMaxApiKeys(e.target.value)}
                    type="number"
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip title="Maximale Anzahl an Agenten für diesen Kunden">
                  <TextField
                    fullWidth
                    label="Max Agenten"
                    value={maxAgents}
                    onChange={(e) => setMaxAgents(e.target.value)}
                    type="number"
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip title="Aktueller Plan des Kunden (basic, pro, enterprise)">
                  <FormControl fullWidth>
                    <InputLabel>Plan</InputLabel>
                    <Select value={plan} onChange={(e) => setPlan(e.target.value)} label="Plan">
                      <MenuItem value="basic">Basic</MenuItem>
                      <MenuItem value="pro">Pro</MenuItem>
                      <MenuItem value="enterprise">Enterprise</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip title="Aktiviert oder deaktiviert die Chat-Funktion für diesen Kunden">
                  <FormControl fullWidth>
                    <InputLabel>Chat aktiviert</InputLabel>
                    <Select value={chatEnabled} onChange={(e) => setChatEnabled(e.target.value === 'true')} label="Chat aktiviert">
                      <MenuItem value="true">Ja</MenuItem>
                      <MenuItem value="false">Nein</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip title="Maximale Anzahl an API-Schlüsseln für den Chat-Service">
                  <TextField
                    fullWidth
                    label="Max Chat API-Schlüssel"
                    value={maxChatApiKeys}
                    onChange={(e) => setMaxChatApiKeys(e.target.value)}
                    type="number"
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip title="Maximale Anzahl an Mitarbeitern">
                  <TextField
                    fullWidth
                    label="Max Mitarbeiter"
                    value={maxEmployees}  // Eingabefeld für maxEmployees
                    onChange={(e) => setMaxEmployees(e.target.value)}
                    type="number"
                  />
                </Tooltip>
              </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={handleSaveSettings} className="mt-6">
              Einstellungen speichern
            </Button>
          </Box>
        )}
      </Paper>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomerSettings;
