import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import LandingPage from './pages/frontend/LandingPage';
import Dashboard from './pages/backend/Dashboard';
import ModernHeader from './components/ModernHeader';
import TestimonialsPage from './pages/frontend/TestimonialsPage';
import ContactPage from './pages/frontend/ContactPage';
import Login from './pages/frontend/Login';
import Logout from './pages/frontend/Logout';
import ConversationDetails from './pages/backend/ConversationDetails';
import ConversationsPage from './pages/backend/ConversationsPage';
import StatisticsPage from './pages/backend/StatisticsPage';
import SettingsPage from './pages/backend/SettingsPage';
import { AuthProvider, useAuth } from './AuthContext';
import AgentsPage from './pages/backend/Agents';
import Footer from './components/Footer';
import DemoRequestForm from './pages/frontend/DemoRequestForm';
import AdminSettingsPage from './pages/backend/AdminSettingsPage';
import { initGA, logPageView } from './components/analytics';
import SessionExpirationModal from './components/SessionExpirationModal';
import axiosInstance from './axiosConfig';
import { motion } from "framer-motion";
import Comparison from './components/Comparison';
import Datenschutz from './pages/frontend/Datenschutz';
import Impressum from './pages/frontend/Impressum';
import AGB from './pages/frontend/AGB';
import Features from './components/Features';
import ImageFeatures from './components/ImageFeatures';
import ChatsPage from './pages/backend/ChatsPage';
import ChatDetailPage from './components/ChatDetailPage';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

function ProtectedRoute({ element }) {
  const { user, loading } = useAuth();
  const variants = {
    initial: {
      scaleY: 0.5,
      opacity: 0,
    },
    animate: {
      scaleY: 1,
      opacity: 1,
      transition: {
        repeat: Infinity,
        repeatType: "mirror",
        duration: 1,
        ease: "circIn",
      },
    },
  };
  if (loading) {
    return (
      <motion.div
        transition={{
          staggerChildren: 0.25,
        }}
        initial="initial"
        animate="animate"
        className="flex gap-1"
      >
        <motion.div variants={variants} className="h-12 w-2 bg-white" />
        <motion.div variants={variants} className="h-12 w-2 bg-white" />
        <motion.div variants={variants} className="h-12 w-2 bg-white" />
        <motion.div variants={variants} className="h-12 w-2 bg-white" />
        <motion.div variants={variants} className="h-12 w-2 bg-white" />
      </motion.div>
    );
  }

  return user ? element : <Navigate to="/login" />;
}

function App() {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  // Funktion zur Behandlung des Schließens des Modals
  const handleCloseModal = useCallback(() => {
    setIsSessionExpired(false);
    // Optional: Weiterleitung zur Anmeldeseite
    // window.location.href = '/login';
  }, []);

  // Funktion zur Behandlung des Sitzungsablaufs
  const handleSessionExpiration = useCallback(() => {
    setIsSessionExpired(true);
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  }, []);

  useEffect(() => {
    initGA();
  }, []);

  const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
      logPageView(location.pathname + location.search);
    }, [location]);
  };

  const PageTracker = () => {
    usePageTracking();
    return null;
  };

  // Integrieren Sie handleSessionExpiration in axiosInstance
  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    const responseInterceptor = axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;

        // Behandlung von 403-Fehler
        if (error.response.status === 403) {
          handleSessionExpiration();
          return Promise.reject(error);
        }

        // Behandlung von 401-Fehler
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refreshToken = localStorage.getItem('refresh_token');
          try {
            const { data } = await axiosInstance.post('/refresh', { refresh_token: refreshToken });
            localStorage.setItem('token', data.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
            return axiosInstance(originalRequest);
          } catch (refreshError) {
            console.error('Token-Aktualisierung fehlgeschlagen:', refreshError);
            handleSessionExpiration();
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [handleSessionExpiration]);

  return (
    <AuthProvider>
      <Router>
        <GlobalStyles />
        <Wrapper>
          <ModernHeader />
          <Content>
            <PageTracker />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />

              <Route
                path="/dashboard"
                element={<ProtectedRoute element={<Dashboard />} />}
              />
              <Route
                path="/conversation/:conversation_id"
                element={<ProtectedRoute element={<ConversationDetails />} />}
              />
              <Route
                path="/conversations"
                element={<ProtectedRoute element={<ConversationsPage />} />}
              />
              <Route
                path="/statistics"
                element={<ProtectedRoute element={<StatisticsPage />} />}
              />
              <Route
                path="/settings"
                element={<ProtectedRoute element={<SettingsPage />} />}
              />
              <Route
                path="/agents"
                element={<ProtectedRoute element={<AgentsPage />} />}
              />
              <Route
                path="/admin"
                element={<ProtectedRoute element={<AdminSettingsPage />} />}
              />
              <Route
                path="/chats"
                element={<ProtectedRoute element={<ChatsPage />} />}
              />
              <Route
                path="/chat/:chatId"
                element={<ProtectedRoute element={<ChatDetailPage />} />}
              />
              <Route path="/features" element={<ImageFeatures />} />
              <Route path="/testimonials" element={<TestimonialsPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/demo" element={<DemoRequestForm />} />
              <Route path="/compare" element={<Comparison />} />
              <Route path="/datenschutz" element={<Datenschutz />} />
              <Route path="/impressum" element={<Impressum />} />
              <Route path="/agb" element={<AGB />} />
            </Routes>
          </Content>
        </Wrapper>
        <SessionExpirationModal isOpen={isSessionExpired} onClose={handleCloseModal} />
      </Router>
      
    </AuthProvider>
    
  );
}

export default App;
