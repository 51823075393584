import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="overflow-x-hidden bg-gray-50">

      <section className="pt-20 bg-gray-50 sm:pt-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="px-6 text-6xl text-gray-900">Ihr intelligenter Anrufbeantworter</h1>
            <p className="mt-4 text-4xl leading-tight text-gray-900 sm:text-5xl lg:text-2xl">
              Automatisierte Anrufverwaltung mit
              <span className="relative inline-flex sm:inline">
                <span className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 blur-lg filter opacity-30 w-full h-full absolute inset-0"></span>
                <span className="relative z-10"> KI </span>
              </span>
            </p>
            <div className="px-8 sm:flex sm:items-center sm:justify-center sm:space-x-5 mt-10">
              <Link to="/demo" className="inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                Demo anfordern
              </Link>
            </div>
            <p className="mt-6 text-base text-gray-500">14 Tage kostenlose Testversion</p>
          </div>
        </div>

        <div className="pb-16 bg-white mt-20">
          <div className="relative">
            <div className="absolute inset-0 h-1/3 bg-gray-50"></div>
            <div className="relative mx-auto">
              <div className="lg:max-w-5xl lg:mx-auto">
                <img className="transform scale-105 shadow-lg" src="https://img.ashub.de/blub-ai/img-test.png" alt="Hero" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
