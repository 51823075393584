import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography, Snackbar, Alert } from '@mui/material';
import axiosInstance from '../axiosConfig';

const ApiKeyManagement = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [newApiKey, setNewApiKey] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [customers, setCustomers] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    fetchApiKeys();
    fetchCustomers();
  }, []);

  const fetchApiKeys = async () => {
    try {
      const response = await axiosInstance.get('/admin/get-keys');
      setApiKeys(response.data);
    } catch (error) {
      console.error('Error fetching API keys:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleCreateApiKey = async () => {
    if (!selectedCustomer) {
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await axiosInstance.post('/admin/create-key', null, {
        params: {
          'api-customer': selectedCustomer
        }
      });
      setNewApiKey(response.data.api_key);
      fetchApiKeys();
    } catch (error) {
      console.error('Error creating API key:', error);
    }
  };

  const handleDeleteApiKey = async (apiKey) => {
    try {
      await axiosInstance.delete('/admin/delete-key', {
        params: {
          'api-key': apiKey
        }
      });
      fetchApiKeys();
    } catch (error) {
      console.error('Error deleting API key:', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const filteredApiKeys = apiKeys.filter(key => key.customer_id === selectedCustomer);

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        API Key Management
      </Typography>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel>Customer</InputLabel>
        <Select value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)} label="Customer">
          {customers.map((customer) => (
            <MenuItem key={customer.id} value={customer.id}>
              {customer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleCreateApiKey} sx={{ mb: 4 }}>
        Create API Key
      </Button>
      {newApiKey && (
        <Typography variant="body1" sx={{ mb: 4 }}>
          New API Key: {newApiKey}
        </Typography>
      )}
      <Typography variant="h6" gutterBottom>
        Existing API Keys
      </Typography>
      <Box sx={{ width: '100%' }}>
        {filteredApiKeys.length > 0 ? (
          filteredApiKeys.map((key) => (
            <Box key={key.api_key} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="body1">{key.api_key}</Typography>
              <Button variant="contained" color="secondary" onClick={() => handleDeleteApiKey(key.api_key)}>
                Delete
              </Button>
            </Box>
          ))
        ) : (
          <Typography variant="body1">No API keys found for this customer.</Typography>
        )}
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
          Bitte wählen Sie einen Kunden aus, bevor Sie einen API-Schlüssel erstellen.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ApiKeyManagement;
