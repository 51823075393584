import React from 'react';
import Testimonials from '../../components/SucessStories';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
const TestimonialsPage = () => {
  return (
    <>
    <Helmet>
    <title>blub.ai - Kundenstimmen</title>
    <meta name="description" content="Lesen Sie, was unsere zufriedenen Kunden über blub.ai sagen. Erfolgsgeschichten und Erfahrungsberichte aus erster Hand." />
    <meta name="robots" content="index, follow" />
    <link rel="canonical" href="https://www.blub.ai/testimonials" />
</Helmet>

      <Testimonials />
      <Footer />
    </>
  );
};

export default TestimonialsPage;
